import styled from 'styled-components';

import { colors } from '../../styles/colors';

export const BodyWrapper = styled.div`
  position: relative;
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: ${colors.black};
  overflow: hidden scroll;
  font-family: 'Jost', sans-serif;
`;
