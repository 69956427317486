import axios from 'axios';
import { config } from '../config';

const headers = {
  headers: {
    'Accept-Language': 'en',
    'Content-Type': 'application/json',
  },
};

const get = async endpoint => {
  const url = config.BASE_URL + endpoint;

  return axios.get(url, headers);
};

const post = async (endpoint, data) => {
  const url = config.BASE_URL + endpoint;

  return axios.post(url, data, headers);
};

const patch = async (endpoint, data) => {
  const url = config.BASE_URL + endpoint;

  return axios.patch(url, data, headers);
};

export const Api = {
  getQuiz: async () => get('getQuiz'),

  sendAnswers: async data => post('sendAnswers', data),

  updateEmail: async data => patch('updateEmail', data),

  purchase: async data => post('purchase', data),
};
