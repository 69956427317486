import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { H4, Regular } from '..';
import { icons } from '../../utils/icons';
import { mobile, tablet } from '../../styles/breakpoints';
import { colors } from '../../styles/colors';

export const Timer = ({ isVisibleOnTablet }) => {
  const [time, setTime] = useState({
    hours: '00',
    minutes: '15',
    seconds: '00',
  });

  useEffect(() => {
    let time = 900;

    const timer = setInterval(() => {
      let hours = Math.floor(time / 3600);
      let minutes = parseInt(`${time / 60}`, 10);
      let seconds = parseInt(`${time % 60}`, 10);

      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      setTime({ hours, minutes, seconds });

      time--;

      if (time < 0) {
        time = 900;
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Container isVisibleOnTablet={isVisibleOnTablet}>
      <DescriptionContainer>
        <Description>Order now to claim free delivery!</Description>
        <Leaves1 src={icons.checkout_leaves_1} alt="Leaves" />
      </DescriptionContainer>
      <TimerWrapper>
        <TimerItem>
          <H4 color={colors.white}>{time.hours}</H4>
          <TimerLabel>Hours</TimerLabel>
        </TimerItem>
        <H4 color={colors.white}>&nbsp;&nbsp;:&nbsp;&nbsp;</H4>
        <TimerItem>
          <H4 color={colors.white}>{time.minutes}</H4>
          <TimerLabel>Minutes</TimerLabel>
        </TimerItem>
        <H4 color={colors.white}>&nbsp;&nbsp;:&nbsp;&nbsp;</H4>
        <TimerItem>
          <H4 color={colors.white}>{time.seconds}</H4>
          <TimerLabel>Seconds</TimerLabel>
        </TimerItem>
        <Leaves2 src={icons.checkout_leaves_2} alt="Leaves" />
      </TimerWrapper>
    </Container>
  );
};

Timer.propTypes = {
  isVisibleOnTablet: PropTypes.bool,
};

Timer.defaultProps = {
  isVisibleOnTablet: true,
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 22px 0;
  color: ${colors.white};
  background: #06ab80;
  text-align: center;
  border-radius: 6px 6px 0 0;

  @media ${tablet} {
    flex-direction: column;
    border-radius: 0;
    display: ${props => (props.isVisibleOnTablet ? 'flex' : 'none')};
  }
`;
const DescriptionContainer = styled.div`
  position: relative;
`;
const Description = styled(Regular)`
  max-width: 247px;
  width: 100%;
  margin-right: 17px;
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  color: ${colors.white};

  @media ${mobile} {
    text-align: center;
  }
`;
const TimerWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
`;
const TimerItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const TimerLabel = styled(Regular)`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${colors.whiteAlpha(0.8)};
`;
const Leaves1 = styled.img`
  position: absolute;
  bottom: 0;
  left: -85px;

  @media ${mobile} {
    bottom: -65px;
    left: -50px;
  }
`;
const Leaves2 = styled.img`
  position: absolute;
  top: -20px;
  right: -75px;

  @media ${mobile} {
    top: -70px;
    right: -65px;
  }
`;
