import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

export const Seo = ({ title, children }) => (
  <Helmet>
    <title>{title}</title>
    {children}
  </Helmet>
);

Seo.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};
