import React from 'react';

import { Header, Seo } from '../components';

export const NotFound = () => (
  <>
    <Seo title="Not found | B.Moment" />
    <Header title="Not found" />
  </>
);
