import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Api } from '../../../apis/api';
import { Analytics } from '../../../apis/Analytics';
import { smTablet, tablet } from '../../../styles/breakpoints';
import { icons } from '../../../utils/icons';
import { CheckoutSchema } from '../../../utils/validations';
import { fadeIn } from '../../../components';
import { BUNDLES, SUBSCRIPTION_PLANS } from '../CheckoutConstants';
import { ShippingAddress } from './ShippingAddress';
import { PaymentMethod } from './PaymentMethod';

export const PaymentModal = ({
  variant,
  isVisible,
  selectedBundle,
  selectedSubscription,
  setIsVisible,
}) => {
  const { push } = useHistory();
  const userInfo = useSelector(s => s.funnel.userInfo);
  const braintreeInstance = useSelector(
    ({ payments }) => payments.braintreeInstance,
  );
  const [paymentMethod, setPaymentMethod] = useState('credit_card');
  const formik = useFormik({
    initialValues: {
      city: '',
      email: '',
      address: '',
      country: '',
      fullName: '',
      apartment: '',
      postalCode: '',
      agreedToTNC: false,
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: () => {
      braintreeInstance.requestPaymentMethod(requestPaymentMethodErr => {
        if (!requestPaymentMethodErr) {
          const data = {
            id: userInfo.id,
            bundle: selectedBundle,
            subscription: selectedSubscription,
          };

          Api.purchase(data).catch(err => {
            console.log(err);
          });
          Analytics.trackPurchase(data);

          push('/success');
        }
      });
    },
    validationSchema: CheckoutSchema,
  });
  const bundle = BUNDLES.find(({ id }) => id === selectedBundle);
  const plan = SUBSCRIPTION_PLANS[selectedBundle].find(
    ({ id }) => id === selectedSubscription,
  );

  if (!isVisible) {
    return null;
  }

  return (
    <Backdrop onClick={() => setIsVisible(false)}>
      <Form
        onSubmit={formik.handleSubmit}
        onClick={event => event.stopPropagation()}
      >
        <ShippingAddress
          values={formik.values}
          errors={formik.errors}
          handleChange={formik.handleChange}
          setFieldValue={formik.setFieldValue}
        />
        <PaymentMethod
          variant={variant}
          values={formik.values}
          plan={plan}
          bundle={bundle}
          paymentMethod={paymentMethod}
          agreedToTNCError={formik.errors.agreedToTNC}
          setPaymentMethod={setPaymentMethod}
          setFieldValue={formik.setFieldValue}
        />
        <CloseIcon
          src={icons.close}
          alt="Close"
          onClick={() => setIsVisible(false)}
        />
      </Form>
    </Backdrop>
  );
};

PaymentModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  selectedBundle: PropTypes.string.isRequired,
  selectedSubscription: PropTypes.string.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  variant: PropTypes.oneOf([1, 2, 3]).isRequired,
};

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.7);
`;

const CloseIcon = styled.img`
  display: none;
  position: absolute;
  top: 15px;
  right: 15px;

  @media ${tablet} {
    display: block;
  }
`;

const Form = styled.form`
  display: flex;
  justify-content: center;
  position: fixed;
  width: 875px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation: ${fadeIn} 0.2s ease-in forwards;
  animation-delay: 0.1s;

  @media ${smTablet} {
    width: auto;
    flex-direction: column;
    position: relative;
    left: 0;
    top: 0;
    transform: unset;
  }
`;
