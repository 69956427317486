import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors } from '../../styles/colors';
import { mobile } from '../../styles/breakpoints';

export const ProgressBar = ({ step, totalSteps }) => {
  const progress = (step * 100) / totalSteps;

  return (
    <ProgressBorder>
      <Progress width={progress.toFixed(2)} />
    </ProgressBorder>
  );
};

ProgressBar.propTypes = {
  step: PropTypes.number,
  totalSteps: PropTypes.number,
};

const ProgressBorder = styled.div`
  display: block;
  height: 8px;
  margin: auto;
  background: ${colors.background};
  border-radius: 6px;

  @media ${mobile} {
    border-radius: 0;
  }
`;

const Progress = styled.div`
  width: ${props => props.width || '0'}%;
  height: 8px;
  background: ${colors.primary};
  border-radius: 6px;
  transition: width 0.2s;

  @media ${mobile} {
    border-radius: 0;
  }
`;
