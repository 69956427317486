import styled from 'styled-components';
import { mobile } from '../../styles/breakpoints';
import { colors } from '../../styles/colors';

export const ContentWrapper = styled.div`
  padding: 3.5rem 0;
  background: ${colors.background};

  @media ${mobile} {
    padding: 2rem 0;
  }
`;
