const ENV = process.env.NODE_ENV || 'development';
const APP_STAGE = process.env.REACT_APP_STAGE || 'development';
const common = {
  APP_STAGE,
  GTM_TAG: process.env.GTM_TAG || 'GTM-58423QN',
  BRAINTREE_CLIENT_ID:
    process.env.BRAINTREE_CLIENT_ID || 'sandbox_38j6nnrx_w9r397vh3gh6ztwc',
  PAYPAL_CLIENT_ID:
    process.env.PAYPAL_CLIENT_ID ||
    'AXqMRfNPvQyJZOO8Ou3X2LjdHK8pfLdbZWJC2s7obWFHb4VmiyNIEcibQid8MiswZD4VzaWxYY2akCcG',
};

const configs = {
  test: {
    ...common,
    ENV,
    BASE_URL: 'https://private-d9dab-bmoment.apiary-mock.com/api/',
    ADYEN_SCRIPT_CONFIG: {
      crossOrigin: 'anonymous',
      src:
        'https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.7.0/adyen.js',
      integrity:
        'sha384-aRaL1/W2ASAwZpEWmiHJJAhcc5h+pwDywrocWjla8oCSJaMlY4MC2hOPvAaOcOCq',
    },
    ADYEN_CSS_CONFIG: {
      rel: 'stylesheet',
      crossOrigin: 'anonymous',
      src:
        'https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.7.0/adyen.css',
      integrity:
        'sha384-y1lKqffK5z+ENzddmGIfP3bcMRobxkjDt/9lyPAvV9H3JXbJYxCSD6L8TdyRMCGM',
    },
  },
  development: {
    ...common,
    ENV,
    BASE_URL: 'http://localhost:5001/bmoment-prod/us-central1/',
    ADYEN_SCRIPT_CONFIG: {
      crossOrigin: 'anonymous',
      src:
        'https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.7.0/adyen.js',
      integrity:
        'sha384-aRaL1/W2ASAwZpEWmiHJJAhcc5h+pwDywrocWjla8oCSJaMlY4MC2hOPvAaOcOCq',
    },
    ADYEN_CSS_CONFIG: {
      rel: 'stylesheet',
      crossOrigin: 'anonymous',
      src:
        'https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.7.0/adyen.css',
      integrity:
        'sha384-y1lKqffK5z+ENzddmGIfP3bcMRobxkjDt/9lyPAvV9H3JXbJYxCSD6L8TdyRMCGM',
    },
  },
  staging: {
    ...common,
    ENV,
    BASE_URL: 'https://us-central1-bmoment-stage.cloudfunctions.net/',
    ADYEN_SCRIPT_CONFIG: {
      crossOrigin: 'anonymous',
      src:
        'https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.7.0/adyen.js',
      integrity:
        'sha384-aRaL1/W2ASAwZpEWmiHJJAhcc5h+pwDywrocWjla8oCSJaMlY4MC2hOPvAaOcOCq',
    },
    ADYEN_CSS_CONFIG: {
      rel: 'stylesheet',
      crossOrigin: 'anonymous',
      src:
        'https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.7.0/adyen.css',
      integrity:
        'sha384-y1lKqffK5z+ENzddmGIfP3bcMRobxkjDt/9lyPAvV9H3JXbJYxCSD6L8TdyRMCGM',
    },
  },
  production: {
    ...common,
    ENV,
    BASE_URL: 'https://us-central1-bmoment-prod.cloudfunctions.net/',
    ADYEN_SCRIPT_CONFIG: {
      crossOrigin: 'anonymous',
      src:
        'https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.7.0/adyen.js',
      integrity:
        'sha384-aRaL1/W2ASAwZpEWmiHJJAhcc5h+pwDywrocWjla8oCSJaMlY4MC2hOPvAaOcOCq',
    },
    ADYEN_CSS_CONFIG: {
      rel: 'stylesheet',
      crossOrigin: 'anonymous',
      src:
        'https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.7.0/adyen.css',
      integrity:
        'sha384-y1lKqffK5z+ENzddmGIfP3bcMRobxkjDt/9lyPAvV9H3JXbJYxCSD6L8TdyRMCGM',
    },
  },
};

export const config = configs[APP_STAGE];
