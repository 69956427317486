/* eslint-disable */
require('./App.css');
import React from 'react';
import * as Sentry from '@sentry/browser';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { config } from './config';
import {
  BodyWrapper,
  /* Cookies, */ Footer,
  Navigation,
  Vwo,
} from './components';
import {
  Checkout,
  CheckoutV2,
  CheckoutV3,
  Calculating,
  Conditions,
  Contacts,
  DaughtersQuizIntro,
  Email,
  Home,
  HomeV2,
  NotFound,
  Privacy,
  Quiz,
  QuizIntro,
  RefundPolicy,
  Success,
  Summary,
} from './routes';

export const App = () => {
  if (config.ENV === 'production' && config.APP_STAGE === 'production') {
    Sentry.init({
      dsn:
        'https://af8fd8253bf745ee8fd3a9e431b466f3@o153093.ingest.sentry.io/5339292',
    });
  }

  return (
    <BodyWrapper>
      <BrowserRouter>
        <meta itemProp="name" content="B.Moment" />
        <meta itemProp="url" content="https://bmoment.co" />
        <Vwo />
        {/* <Cookies /> */}
        <Navigation />
        <div
          itemProp="mainEntity"
          itemScope
          itemType="http://schema.org/Webpage"
        >
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/v2" exact component={HomeV2} />
            <Route path="/contacts" exact component={Contacts} />
            <Route path="/privacy-policy" exact component={Privacy} />
            <Route path="/refund-policy" exact component={RefundPolicy} />
            <Route path="/general-conditions" exact component={Conditions} />
            <Route path="/quiz-intro" exact component={QuizIntro} />
            <Route path="/quiz/:step" exact component={Quiz} />
            <Route
              path="/daughters-quiz-intro"
              exact
              component={DaughtersQuizIntro}
            />
            <Route path="/daughters-quiz/:step" exact component={Quiz} />
            <Route path="/email" exact component={Email} />
            <Route path="/calculating" exact component={Calculating} />
            <Route path="/summary" exact component={Summary} />
            <Route path="/checkout" exact component={Checkout} />
            <Route path="/checkout2" exact component={CheckoutV2} />
            <Route path="/checkout3" exact component={CheckoutV3} />
            <Route path="/success" exact component={Success} />
            <Route path="*" component={NotFound} />
          </Switch>
        </div>
        <Footer />
      </BrowserRouter>
    </BodyWrapper>
  );
};
