import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { DefaultButton, H2, Regular } from '../../components';
import { icons } from '../../utils/icons';
import { colors } from '../../styles/colors';
import { mobile, smTablet, tablet } from '../../styles/breakpoints';

export const MoisturisingFaceMask = ({ variant }) => {
  const { push } = useHistory();

  return (
    <Container>
      <Wrapper>
        <LeftContainer>
          <Heading>B.Moment moisturising face masks</Heading>
          <Description>
            Experience skincare together with B.Moment facial masks created for
            you and your daughter to enjoy. Share the ritual, with personalized
            face masks based on your skin needs.
          </Description>
          <BoldText>Your face masks come with:</BoldText>
          <Grid>
            <Item icon={icons.home_envelope} label="Easy instructions" />
            <Item icon={icons.home_brush} label="Soft brusher" />
            <Item icon={icons.home_sponge} label="Face sponge" />
            <Item icon={icons.home_ribbon} label="Additional perks" />
          </Grid>
          <ButtonWrapper>
            <DefaultButton
              fullWidth={true}
              type="button"
              id="quizBtn2"
              label={variant === 1 ? 'DESIGN YOUR RITUAL' : 'SEE THE PRODUCT'}
              onClick={() => push(variant === 1 ? '/quiz-intro' : '/checkout2')}
            />
          </ButtonWrapper>
        </LeftContainer>
        <FaceMaskImageContainer>
          <FaceMaskImage
            src={icons.home_moisturising_face_mask}
            alt="Face mask"
          />
          {variant === 2 ? (
            <GiftContainer>
              <GiftImage src={icons.checkout_gift} alt="gift" />
              <GiftText>now the first ritual for free</GiftText>
            </GiftContainer>
          ) : null}
        </FaceMaskImageContainer>
      </Wrapper>
    </Container>
  );
};

MoisturisingFaceMask.propTypes = {
  variant: PropTypes.oneOf([1, 2]),
};

MoisturisingFaceMask.defaultProps = {
  variant: 1,
};

const Item = ({ icon, label }) => (
  <ItemContainer>
    <ItemIconContainer>
      <img src={icon} alt="Label" />
    </ItemIconContainer>
    <ItemLabel>{label}</ItemLabel>
  </ItemContainer>
);

Item.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

const Container = styled.div`
  padding: 0 24px;
  background-color: ${colors.greyAlpha(0.2)};

  @media ${smTablet} {
    padding: 0;
  }
`;
const Wrapper = styled.div`
  display: flex;
  align-items: 'center';
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 0;

  @media ${tablet} {
    flex-direction: column-reverse;
    padding: 0 0 50px;
  }
`;
const LeftContainer = styled.div`
  text-align: left;

  @media ${smTablet} {
    padding: 0 24px;
  }
`;
const Heading = styled(H2)`
  @media ${tablet} {
    font-size: 26px;
  }
`;
const Description = styled(Regular)`
  max-width: 495px;
  width: 100%;
  padding-top: 15px;
  font-size: 18px;
  text-align: left;
  color: ${colors.blackAlpha(0.8)};
`;
const BoldText = styled(Description)`
  padding-bottom: 30px;
  font-weight: 600;
`;
const FaceMaskImageContainer = styled.div`
  position: relative;
`;
const FaceMaskImage = styled.img`
  @media (max-width: 1100px) {
    width: 100%;
    height: 100%;
    margin-left: 25px;
  }

  @media ${tablet} {
    margin-left: 0;
    padding-bottom: 26px;
  }
`;
const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  max-width: 493px;
  width: 100%;
  margin-bottom: 15px;
`;
const GiftContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 14px;
  transform: translateY(-50%);
  border-radius: 7px;
  background-color: ${colors.error};

  @media ${mobile} {
    left: 50%;
    right: unset;
    transform: translate(-50%, -50%);
  }
`;
const GiftImage = styled.img`
  padding-bottom: 2px;
`;
const GiftText = styled(Regular)`
  margin-left: 5px;
  font-weight: 600;
  font-size: 15px;
  line-height: 34px;
  color: ${colors.white};
  text-transform: uppercase;

  @media ${mobile} {
    white-space: nowrap;
  }
`;
const ButtonWrapper = styled.div`
  max-width: 270px;
  width: 100%;

  @media ${tablet} {
    margin: 0 auto;
  }
`;
const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;
const ItemIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 60px;
  height: 60px;
  margin-right: 14px;
  border-radius: 20px;
  background-color: ${colors.white};
`;
const ItemLabel = styled(Regular)`
  width: 183px;
  font-size: 18px;
  font-weight: 500;
  color: ${colors.blackAlpha(0.8)};
`;
