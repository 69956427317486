import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { icons } from '../../../utils/icons';
import { colors } from '../../../styles/colors';
import { Regular } from '../../text/Regular';

export const InfoListQuestion = ({ question }) => {
  const { custom, label, options } = question;

  return (
    <Container>
      <img src={icons[`quiz_${custom.icon}`]} alt={custom.icon} />
      <LabelContainer>
        <Leaves1 src={icons.quiz_leaves_4} alt="Leaves" />
        <Label>{label}</Label>
      </LabelContainer>
      <OptionsWrapper>
        {options.map(({ label }) => (
          <OptionItem key={label}>
            <img src={icons.checkout_checkmark} alt="Checkmark" />
            <OptionText>{label}</OptionText>
          </OptionItem>
        ))}
        <Leaves2 src={icons.quiz_leaves_5} alt="Leaves" />
      </OptionsWrapper>
    </Container>
  );
};

InfoListQuestion.propTypes = {
  question: PropTypes.shape({
    custom: PropTypes.shape({
      icon: PropTypes.string,
    }),
    label: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
      }),
    ),
  }).isRequired,
};

const Container = styled.div`
  padding-top: 14px;
  padding-bottom: 32px;
`;
const LabelContainer = styled.div`
  position: relative;
`;
const Label = styled(Regular)`
  padding: 18px 0;
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  text-align: center;
  color: ${colors.black};
`;
const Leaves1 = styled.img`
  position: absolute;
  top: -45px;
  left: -25px;
`;
const Leaves2 = styled.img`
  position: absolute;
  bottom: -45px;
  right: -25px;
`;
const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;
const OptionItem = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 8px;

  &:last-of-type {
    padding-bottom: 0;
  }
`;
const OptionText = styled(Regular)`
  padding-left: 9px;
  font-size: 18px;
  font-weight: normal;
  line-height: 26px;
  color: ${colors.blackAlpha(0.8)};
`;
