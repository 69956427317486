import { constants } from '../constants';

export const INITIAL_STATE = {
  scriptsLoaded: false,
  braintreeInstance: null,
};

export const paymentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.payments.SET_SCRIPTS_LOADED:
      return { ...state, scriptsLoaded: action.payload };
    case constants.payments.SET_BRAINTREE_INSTANCE:
      return { ...state, braintreeInstance: action.payload };
    default:
      return state;
  }
};
