export function getQuestionOptions({ key, questions }) {
  const question = questions.find(q => q.key === key);

  if (!question) {
    return [];
  }

  return question.options;
}

export function getGeneralConditionLabel({
  skinType,
  skinSensitivity,
  skinTypes,
}) {
  if (skinSensitivity === 'yes') {
    return 'Sensitive Skin';
  } else if (skinSensitivity === 'sometimes') {
    return 'Sometimes Sensitive Skin';
  }

  if (skinType === 'dont_know' || skinType === 'not_sure') {
    return 'Unknown';
  }

  const result = skinTypes.find(({ value }) => value === skinType);

  return result ? result.label : skinType;
}

export function getSkinConcernsLabel({ skinConcern, skinConcerns }) {
  let result = '';
  const values = skinConcern?.split('|');

  if (!values) {
    return result;
  }

  if (values.length <= 1) {
    const value = skinConcerns.find(({ value }) => value === values[0]);

    result = value ? value.label : values[0];
  } else {
    const value1 = skinConcerns.find(({ value }) => value === values[0]);
    const value2 = skinConcerns.find(({ value }) => value === values[1]);

    result = `${value1?.label || values[0]} and ${value2?.label || values[1]}`;

    if (values.length > 2) {
      result = `${result} + other problems`;
    }
  }

  return result;
}

export function getMostSuitableFruitLabel({ expectedEffect }) {
  if (expectedEffect === 'glow' || expectedEffect === 'healthy_glow') {
    return 'Avocado';
  }

  return 'Acai berry';
}
