import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { config } from '../../config';
import { actions } from '../../state/actions';
import { ExternalScripts } from './ExternalScripts';

const DROPIN_CONFIG = {
  authorization: config.BRAINTREE_CLIENT_ID,
  card: {
    cardholderName: {
      required: false,
    },
    overrides: {
      styles: {
        input: {
          color: '#202359',
          'font-size': '16px',
          'font-weight': '400',
        },
        '.number': {
          color: '#202359',
          'font-weight': '400',
        },
      },
    },
  },
};

export const BraintreeDropIn = ({ id, preloaded }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector(({ funnel }) => funnel.userInfo);
  const { scriptsLoaded, braintreeInstance } = useSelector(
    ({ payments }) => payments,
  );
  const buildBraintreeDropIn = () => {
    const createOptions = {
      ...DROPIN_CONFIG,
      container: `#drop-in-${id}`,
    };

    window.braintree.dropin.create(createOptions, (err, instance) =>
      err
        ? console.error(err)
        : dispatch(actions.payments.setBraintreeInstance(instance)),
    );
  };

  useEffect(() => {
    if (scriptsLoaded) {
      buildBraintreeDropIn();
    }
  }, [scriptsLoaded, braintreeInstance, userInfo]);

  return (
    <>
      {preloaded ? null : (
        <ExternalScripts
          onLoadComplete={() => {
            window.SCRIPTS_LOADED = true;
          }}
        />
      )}
      <div id={`drop-in-${id}`} />
    </>
  );
};

BraintreeDropIn.propTypes = {
  id: PropTypes.string.isRequired,
  preloaded: PropTypes.bool,
};

BraintreeDropIn.defaultProps = {
  preloaded: false,
};
