import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Regular } from '..';
import { colors } from '../../styles/colors';

export const CalorieItem = ({ min, max }) => {
  const norm = (min + max) / 2;
  const width = (norm * 50) / 5000;

  return (
    <AlignText>
      <Calories left={width + 3}>
        <Regular>{`${min} - ${max}`}</Regular>
      </Calories>
      <ProgressBorder>
        <Progress left={width} />
      </ProgressBorder>
      <Limits>
        <Flex>
          <Regular>1000</Regular>
        </Flex>
        <Regular>5000</Regular>
      </Limits>
    </AlignText>
  );
};

CalorieItem.propTypes = {
  max: PropTypes.number,
  min: PropTypes.number,
};

const AlignText = styled.div`
  text-align: left;
`;

const Calories = styled.div`
  margin-left: ${props => props.left || '0'}%;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
`;

const ProgressBorder = styled.div`
  height: 11px;
  margin-bottom: 1rem;
  background: ${colors.background};
  border-radius: 6px;
`;

const Progress = styled.div`
  position: relative;
  margin-left: ${props => props.left || '0'}%;
  width: 30%;
  height: 11px;
  background: ${colors.primary};
  border-radius: 6px;
  transition: width 0.2s;
`;

const Limits = styled.div`
  display: flex;
`;

const Flex = styled.div`
  flex: 1;
`;
