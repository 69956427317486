import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { colors } from '../../styles/colors';
import { Regular } from '../text/Regular';

export const Loader = ({ onValueUpdated }) => {
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (percent < 100) {
        const newValue = percent + 1;

        onValueUpdated(newValue);
        setPercent(newValue);
      } else {
        clearInterval(interval);
      }
    }, 50);

    return () => clearInterval(interval);
  }, [percent]);

  return (
    <Container>
      <CircleWrapperRight>
        <CircleRight />
      </CircleWrapperRight>
      <CircleWrapperLeft>
        <CircleLeft />
      </CircleWrapperLeft>
      <Text>{percent}%</Text>
    </Container>
  );
};

Loader.propTypes = {
  onValueUpdated: PropTypes.func,
};

Loader.defaultProps = {
  onValueUpdated: () => null,
};

const circleRight = keyframes`
  0% { transform: rotate(-135deg); }
  50%, 100% { transform: rotate(45deg); }
`;
const circleLeft = keyframes`
  0%, 50% { transform: rotate(-135deg); }
  100% { transform: rotate(45deg); }
`;
const Container = styled.div`
  width: 200px;
  height: 200px;
  margin: 0 auto;
  position: relative;
`;
const CircleWrapper = styled.div`
  width: 100px;
  height: 200px;
  position: absolute;
  top: 0;
  overflow: hidden;
`;
const CircleWrapperRight = styled(CircleWrapper)`
  right: 0;
`;
const CircleWrapperLeft = styled(CircleWrapper)`
  left: 0;
`;
const CircleWhole = styled.div`
  width: 160px;
  height: 160px;
  border: 5px solid transparent;
  border-radius: 50%;
  position: absolute;
  top: 0;
  transform: rotate(-135deg);
  box-sizing: content-box;
`;
const CircleRight = styled(CircleWhole)`
  border-top: 5px solid ${colors.primary};
  border-right: 5px solid ${colors.primary};
  right: 15px;
  animation: ${circleRight} 5s linear forwards;
`;
const CircleLeft = styled(CircleWhole)`
  border-bottom: 5px solid ${colors.primary};
  border-left: 5px solid ${colors.primary};
  left: 15px;
  animation: ${circleLeft} 5s linear forwards;
`;
const Text = styled(Regular)`
  position: absolute;
  top: 27%;
  left: 50%;
  transform: translate(-50%);
  font-size: 42px;
  font-weight: bold;
`;
