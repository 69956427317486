import TagManager from 'react-gtm-module';

import { config } from '../config';

class AnalyticsClass {
  analyticsEnabled = false;

  constructor() {
    this.analyticsEnabled =
      config.ENV === 'production' && config.APP_STAGE === 'production';

    if (this.analyticsEnabled) {
      TagManager.initialize({ gtmId: config.GTM_TAG });
    }
  }

  trackCTAButton(param) {
    if (this.analyticsEnabled) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'Click',
          category: 'Summary page',
          label: `CTA button - ${param}`,
        },
      });
    }
  }

  trackQuizAnswer(category, label = 'undefined') {
    if (this.analyticsEnabled) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'Quiz question answered',
          category: category,
          label: label,
        },
      });
    }
  }

  trackPurchase(payload) {
    if (this.analyticsEnabled) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'Purchase',
          ...payload,
        },
      });
    }
  }
}

export const Analytics = new AnalyticsClass();
