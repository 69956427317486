import React from 'react';
import PropTypes from 'prop-types';

import { OptionItem } from '..';

export const SingleOption = ({ answer, onSelect, question }) => {
  const { options } = question;

  return options.map((o, i) => (
    <OptionItem
      id={`singleOption${i}`}
      key={o.value}
      {...o}
      selected={o.value === answer}
      onClick={() => onSelect({ [question.key]: o.value, label: o.label })}
    />
  ));
};

SingleOption.propTypes = {
  answer: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
};

SingleOption.defaultProps = {
  answer: null,
};
