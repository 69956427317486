import { constants } from '../constants';

export const setScriptsLoaded = payload => ({
  type: constants.payments.SET_SCRIPTS_LOADED,
  payload,
});

export const setBraintreeInstance = payload => ({
  type: constants.payments.SET_BRAINTREE_INSTANCE,
  payload,
});
