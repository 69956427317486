import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../styles/colors';

export const OlWrapper = styled.ol`
  padding: 0;
  color: ${colors.greyDark};
  margin: ${p => p.margin || '0px'};
  list-style-position: inside;
  list-style-type: ${props => props.listStyleType || 'disc'};
`;

OlWrapper.propTypes = {
  margin: PropTypes.string,
};
