import React from 'react';
import styled from 'styled-components';

import {
  Caption,
  Container,
  ContentWrapper,
  Header,
  Regular,
  Seo,
} from '../components';

export const Contacts = () => (
  <>
    <Seo title="Contacts | B.Moment" />
    <Header title="Contacts" />
    <ContentWrapper>
      <Container>
        <Flex>
          <Left>
            <Caption className="bolder">Company</Caption>
            <Regular margin="0 0 1rem 0">DoFasting.com</Regular>
            <Caption className="bolder">Company code</Caption>
            <Regular margin="0 0 1rem 0">303157579</Regular>
            <Caption className="bolder">Address of registration</Caption>
            <Regular margin="0 0 1rem 0">
              Ramybės g. 4-70, Vilnius. Lithuania. European Union
            </Regular>
          </Left>
          <Right>
            <Caption className="bolder">Address</Caption>
            <Regular margin="0 0 1rem 0">
              Antakalnio g. 17, Vilnius. Lithuania. European Union
            </Regular>
            <Caption className="bolder">Email</Caption>
            <Regular margin="0 0 1rem 0">hello@bmoment.co</Regular>
          </Right>
        </Flex>
      </Container>
    </ContentWrapper>
  </>
);

const Flex = styled.div`
  display: flex;
  min-height: calc(100vh - 7rem - 140px - 86px - 85px);
`;

const Left = styled.div`
  flex: 1;
  padding-right: 0.5rem;
`;

const Right = styled.div`
  flex: 1;
  padding-left: 0.5rem;
`;
