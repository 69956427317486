import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors } from '../../styles/colors';
import { mobile } from '../../styles/breakpoints';

export const ModalWrapper = ({ children }) => (
  <Background>
    <ModalView>{children}</ModalView>
  </Background>
);

ModalWrapper.propTypes = {
  children: PropTypes.any,
};

const Background = styled.div`
  padding: 4.5rem 0;
  min-height: 100vh;
  background: ${colors.background};

  @media ${mobile} {
    min-height: 100vh;
    padding: 3rem 0;
  }
`;

const ModalView = styled.div`
  position: relative;
  max-width: 780px;
  padding: 40px 4% 66px;
  margin: 0 auto;
  text-align: center;
  background: ${colors.white};
  border-radius: 40px;

  @media ${mobile} {
    padding: 0 2%;
  }
`;
