import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Carousel from '@brainhubeu/react-carousel';

import {
  CarouselDots,
  DefaultButton,
  H1,
  H2,
  H3,
  Regular,
} from '../../components';
import { icons } from '../../utils/icons';
import { colors } from '../../styles/colors';
import { smDesktop, smTablet, xsmDesktop } from '../../styles/breakpoints';

const SM_DESKTOP_WIDTH = 1200;

export const HomeHeaderV2 = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <Container>
      <Rectangle1 src={icons.home_rectangle_1} alt="Rectangle" />
      <Rectangle2 src={icons.home_rectangle_2} alt="Rectangle" />
      <Wrapper>
        <Carousel
          autoPlay={8000}
          value={activeSlide}
          minDraggableOffset={5}
          keepDirectionWhenDragging={true}
          addArrowClickHandler={true}
          arrowLeft={
            window.innerWidth >= SM_DESKTOP_WIDTH ? (
              <ArrowCircle className="left">
                <img src={icons.home_arrow_left} alt="Arrow left" />
              </ArrowCircle>
            ) : null
          }
          arrowRight={
            window.innerWidth >= SM_DESKTOP_WIDTH ? (
              <ArrowCircle className="right">
                <img src={icons.home_arrow_right} alt="Arrow right" />
              </ArrowCircle>
            ) : null
          }
          onChange={nextSlide => {
            setActiveSlide(slide => {
              if (slide === 2) {
                return 0;
              }

              return nextSlide;
            });
          }}
        >
          <CarouselItem>
            <LeftAlignedContainer maxWidth="500px">
              <HeadingWrapper>
                <Heading>
                  Unforgettable beauty rituals for mother and daughter
                </Heading>
                <Leaves1 src={icons.home_leaves_1} alt="Leaves" />
                <Leaves2 src={icons.home_leaves_2} alt="Leaves" />
              </HeadingWrapper>
              <BuyButton to="/checkout2">
                <DefaultButton
                  fullWidth={true}
                  label="SEE THE PRODUCT"
                  type="button"
                />
              </BuyButton>
            </LeftAlignedContainer>
            <ImageWrapper>
              <MomAndDaughter
                src={icons.home_mom_daughter_1}
                alt="Mom and daughter"
              />
              <FaceMask src={icons.home_face_mask} alt="Face mask" />
            </ImageWrapper>
          </CarouselItem>
          <CarouselItem>
            <LeftAlignedContainer maxWidth="582px">
              <HeadingWrapper>
                <Heading2>
                  Buy our bestselling 3-month ritual subscription for mother and
                  daughter and <span className="red">save 20%</span>
                </Heading2>
                <Leaves1 src={icons.home_leaves_1} alt="Leaves" />
                <Leaves2 src={icons.home_leaves_2} alt="Leaves" />
              </HeadingWrapper>
              <BuyButton to="/checkout2">
                <DefaultButton
                  fullWidth={true}
                  label="SEE THE PRODUCT"
                  type="button"
                />
              </BuyButton>
            </LeftAlignedContainer>
            <ImageWrapper>
              <Image src={icons.home_moisturising_face_mask} alt="Face mask" />
            </ImageWrapper>
          </CarouselItem>
          <CarouselItem>
            <LeftAlignedContainer maxWidth="483px">
              <HeadingSubText>Give away month!</HeadingSubText>
              <HeadingWrapper>
                <Heading3>
                  Only this month you can get 1 beauty ritual for you and your
                  daughter for free!
                </Heading3>
                <Leaves1 src={icons.home_leaves_1} alt="Leaves" />
                <Leaves2 src={icons.home_leaves_2} alt="Leaves" />
              </HeadingWrapper>
              <HeadingDescription>
                Order now and enjoy the fulfilment of strengthening the bond
                with your daughter
              </HeadingDescription>
              <BuyButton to="/checkout2">
                <DefaultButton
                  fullWidth={true}
                  label="SEE THE PRODUCT"
                  type="button"
                />
              </BuyButton>
            </LeftAlignedContainer>
            <ImageWrapper>
              <Image
                src={icons.home_mom_daughter_face_mask}
                alt="Mom and daughter"
              />
              <FaceMask src={icons.home_face_mask} alt="Face mask" />
            </ImageWrapper>
          </CarouselItem>
        </Carousel>
        <CarouselDots
          dotsCount={3}
          activeSlide={activeSlide}
          setActiveSlide={setActiveSlide}
        />
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  padding: 0 24px;

  @media ${smTablet} {
    padding: 0;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1370px;
  width: 100%;
  margin: 0 auto 80px;

  @media ${smDesktop} {
    margin: 0 auto 50px;
    padding: 0 24px;
  }
`;
const ArrowCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: ${colors.gradient};
  cursor: pointer;

  &.left {
    margin-right: 35px;
  }

  &.right {
    margin-left: 35px;
  }
`;
const CarouselItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  @media ${smDesktop} {
    flex-direction: column-reverse;
  }
`;
const LeftAlignedContainer = styled.div`
  max-width: ${props => props.maxWidth || '500px'};
  text-align: left;

  @media ${smDesktop} {
    max-width: unset;
    text-align: center;
  }
`;
const Image = styled.img`
  position: relative;
  max-width: 600px;
  width: 100%;
`;
const HeadingWrapper = styled.div`
  position: relative;
`;
const Heading = styled(H1)`
  font-size: 42px;
  font-weight: 600;
  text-align: left;

  @media ${smTablet} {
    font-size: 30px;
  }
`;
const Heading2 = styled(H2)`
  max-width: 582px;
  width: 100%;
  font-size: 46px;
  font-weight: 600;
  text-align: left;

  @media ${smTablet} {
    font-size: 26px;
  }

  .red {
    color: ${colors.error};
  }
`;
const Heading3 = styled(H3)`
  max-width: 483px;
  width: 100%;
  margin: 3px 0 7px;
  font-size: 33px;
  font-weight: 600;
  text-align: left;

  @media ${smTablet} {
    font-size: 22px;
  }
`;
const HeadingSubText = styled(Regular)`
  font-size: 20px;
  line-height: 36px;
  text-align: left;
  color: ${colors.error};
`;
const HeadingDescription = styled(Regular)`
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  color: ${colors.black};
`;
const BuyButton = styled(Link)`
  display: inline-block;
  max-width: 270px;
  width: 100%;
  margin-top: 25px;
`;
const Leaves1 = styled.img`
  position: absolute;
  top: -100px;
  left: 0;

  @media ${smDesktop} {
    display: none;
  }
`;
const Leaves2 = styled.img`
  position: absolute;
  top: -65px;
  right: 45px;

  @media ${smDesktop} {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  position: relative;
  width: 50%;

  @media ${smTablet} {
    width: auto;
    margin-left: 0;
  }
`;
const FaceMask = styled.img`
  display: block;
  position: absolute;
  left: -105px;
  bottom: -65px;

  @media ${smTablet} {
    display: none;
  }

  @media ${xsmDesktop} {
    left: -225px;
    bottom: -125px;
  }

  @media ${smDesktop} {
    display: none;
  }
`;
const MomAndDaughter = styled.img`
  width: 100%;
`;
const Rectangle1 = styled.img`
  position: absolute;
  bottom: -320px;
  left: 0;
  z-index: -1;

  @media ${smTablet} {
    display: none;
  }
`;
const Rectangle2 = styled.img`
  position: absolute;
  top: -44px;
  right: 0;
  z-index: -1;

  @media ${smTablet} {
    top: 120px;
    right: -50%;
  }
`;
