import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors } from '../../styles/colors';

export const BmiItem = ({ bmi }) => (
  <Wrapper>
    <Status className={bmi < 18.5 && 'selected'} margin="0 0 1rem 0">
      Obese
      {bmi < 18.5 && (
        <Value>
          {bmi}
          <Body> BMI</Body>
        </Value>
      )}
    </Status>
    <Hr />
    <Status
      className={bmi > 18.5 && bmi < 24.9 && 'selected'}
      margin="1rem 0 1rem 0"
    >
      Normal
      {bmi > 18.5 && bmi < 24.9 && (
        <Value>
          {bmi}
          <Body> BMI</Body>
        </Value>
      )}
    </Status>
    <Hr />
    <Status className={bmi > 24.9 && 'selected'} margin="1rem 0 0 0">
      Underweight
      {bmi > 24.9 && (
        <Value>
          {bmi}
          <Body> BMI</Body>
        </Value>
      )}
    </Status>
  </Wrapper>
);

BmiItem.propTypes = {
  bmi: PropTypes.number,
};

const Wrapper = styled.div`
  padding: 1.5rem 2rem;
  margin-bottom: 1rem;
  background: ${colors.white};
  border-radius: 6px;
`;

const Status = styled.div`
  margin: ${props => props.margin || '0px'};
  font-size: 18px;
  font-weight: 400;
  color: ${colors.greyDark};

  &.selected {
    font-weight: 700;
    color: ${colors.black};
  }
`;

const Hr = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  background: ${colors.background};
`;

const Value = styled.div`
  float: right;
  font-size: 1.5rem;
  color: ${colors.primary};
`;

const Body = styled.span`
  font-size: 1rem;
  font-weight: 400;
`;
