import React, { useEffect, useState } from 'react';
import { keys } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { DefaultButton, OptionItem } from '..';
import { colors } from '../../styles/colors';
import { mobile } from '../../styles/breakpoints';

export const GridOption = ({ answer, question, onSelect }) => {
  const { custom, options } = question;
  const deselector = options.find(o => o.custom?.deselectAll)?.value;
  const getDefaultSelectedValue = () => {
    const answerSplitted = typeof answer === 'string' ? answer.split('|') : [];

    return answerSplitted.reduce((accu, val) => {
      accu[val] = true;

      return accu;
    }, {});
  };
  const [selected, setSelected] = useState(getDefaultSelectedValue());
  const disabled = keys(selected).length === 0;
  const handleOptionClick = option => () => {
    let newSelected = { ...selected };
    if (selected[option.value]) {
      delete newSelected[option.value];
    } else if (option.custom?.deselectAll) {
      newSelected = { [option.value]: true };
    } else {
      newSelected = { ...selected, [option.value]: true };
      delete newSelected[deselector];
    }
    setSelected(newSelected);
  };

  const onSubmit = () => {
    if (!disabled) {
      const answer = keys(selected).join('|');
      onSelect({ [question.key]: answer, label: 'undefined' });
    }
  };

  useEffect(() => {
    setSelected(getDefaultSelectedValue());
  }, [question]);

  return (
    <>
      <OptionsWrapper className={custom?.vertical ? 'column' : null}>
        {options.map((o, i) => (
          <OptionItem
            key={o.value}
            id={`checkBox${i}`}
            {...o}
            fullWidth={!!custom?.vertical}
            selected={selected[o.value]}
            onClick={handleOptionClick(o)}
          />
        ))}
      </OptionsWrapper>
      <ButtonWrapper>
        <DefaultButton
          id="submitBtn"
          label="Continue"
          fullWidth={true}
          onClick={onSubmit}
          disabled={disabled}
        />
      </ButtonWrapper>
    </>
  );
};

GridOption.propTypes = {
  answer: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
};

GridOption.defaultProps = {
  answer: null,
};

const OptionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 700px;
  margin-bottom: 32px;

  &.column {
    flex-direction: column;
  }
`;
const ButtonWrapper = styled.div`
  max-width: 375px;
  width: 100%;
  margin: 0 auto;

  @media ${mobile} {
    position: fixed;
    max-width: initial;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px 24px;
    border-top: 1px solid #ebebeb;
    background-color: ${colors.white};
  }
`;
