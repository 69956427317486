import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import styled, { keyframes } from 'styled-components';

import { colors } from '../../styles/colors';
import { xsmTablet } from '../../styles/breakpoints';
import { DefaultButton } from '..';
import { icons } from '../../utils/icons';

export const Menu = ({ variant, isOpen, closeMenu }) => {
  const { push } = useHistory();
  const homeUrl = variant === 2 ? '/v2' : '/';

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <Overlay onClick={closeMenu} />
      <MenuContainer>
        <MenuList onClick={closeMenu}>
          <MenuListHashItem smooth to={`${homeUrl}#magical-moments`}>
            Magical moments
          </MenuListHashItem>
          <MenuListHashItem smooth to={`${homeUrl}#face-masks`}>
            Face masks
          </MenuListHashItem>
          <MenuListHashItem smooth to={`${homeUrl}#ingredients`}>
            Ingredients
          </MenuListHashItem>
          <MenuListHashItem smooth to={`${homeUrl}#our-promise`}>
            Our promise
          </MenuListHashItem>
          <DefaultButton
            id="quizBtn4"
            type="button"
            height="auto"
            padding="14px 40px"
            label={variant === 1 ? 'DESIGN YOUR RITUAL' : 'SEE THE PRODUCT'}
            onClick={() => push(variant === 2 ? 'checkout2' : '/quiz-intro')}
          />
          <Leaves1 src={icons.nav_leaves_1} alt="Leaves" />
          <Leaves2 src={icons.nav_leaves_2} alt="Leaves" />
          <Leaves3 src={icons.nav_leaves_3} alt="Leaves" />
          <Leaves4 src={icons.nav_leaves_4} alt="Leaves" />
        </MenuList>
      </MenuContainer>
    </>
  );
};

Menu.propTypes = {
  closeMenu: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf([1, 2]).isRequired,
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;
const slideIn = keyframes`
  from {
    right: -40%;
  }

  to {
    right: 0;
  }
`;
const slideInMobile = keyframes`
  from {
    right: -70%;
  }

  to {
    right: 0;
  }
`;
const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  animation: ${fadeIn} 0.2s ease-in forwards;
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 2;
`;
const MenuContainer = styled.div`
  position: absolute;
  width: 40%;
  top: 0;
  right: -40%;
  bottom: 0;
  padding-top: 10rem;
  animation: ${slideIn} 0.3s ease-in forwards;
  background: ${colors.white};
  z-index: 2;

  @media ${xsmTablet} {
    width: 100%;
    right: -70%;
    animation: ${slideInMobile} 0.3s ease-in forwards;
  }
`;
const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 58px;
`;
const MenuListHashItem = styled(HashLink)`
  display: block;
  margin-bottom: 35px;
  color: ${colors.black};
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
`;
const Leaves1 = styled.img`
  position: absolute;
  top: -15px;
  left: 38px;
`;
const Leaves2 = styled.img`
  position: absolute;
  top: -40px;
  right: 25px;
`;
const Leaves3 = styled.img`
  position: absolute;
  top: 100px;
  left: 10px;
`;
const Leaves4 = styled.img`
  position: absolute;
  bottom: -100px;
  right: 15px;
`;
