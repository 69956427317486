import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors } from '../../styles/colors';

export const Spinner = ({ disabled, backgroundColor, spinnerColor }) => (
  <Container
    spinnerColor={spinnerColor}
    backgroundColor={backgroundColor}
    className={disabled ? 'disable' : null}
  />
);

Spinner.propTypes = {
  backgroundColor: PropTypes.string,
  disabled: PropTypes.bool,
  spinnerColor: PropTypes.string,
};

Spinner.defaultProps = {
  backgroundColor: null,
  disabled: false,
  spinnerColor: null,
};

const Container = styled.div`
  position: relative;
  transform: translateZ(0);
  font-size: 3px;
  margin: 0 auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: linear-gradient(
    to right,
    ${props => props.spinnerColor || colors.white} 10%,
    rgba(255, 255, 255, 0) 42%
  );
  animation: load 1.4s infinite linear;

  &:before {
    width: 50%;
    height: 50%;
    background: ${props => props.spinnerColor || colors.white};
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }

  &:after {
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: ${props => props.backgroundColor || colors.gradient};
  }

  &.disable:after {
    background: ${props => props.disabledColor || colors.foggyGreen};
  }

  @-webkit-keyframes load {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
