import React from 'react';

import styled from 'styled-components';
import { H1, H2, Regular } from '..';
import { mobile } from '../../styles/breakpoints';
import { icons } from '../../utils/icons';

export const PlanFeatures = () => (
  <>
    <H1 className="centered" margin="0 0 2.5rem 0">
      What we prepared for you
    </H1>
    <ItemMargin>
      <Flex>
        <ImgBox>
          <Img bg={icons['instruction_1']} />
        </ImgBox>
        <TextBlock>
          <H2 margin="0 0 1rem 0">
            100% Personalized Meal Plan
            <br />
            +grocery list for each week
          </H2>
          <Regular>
            This plan is a number one solution to boost your metabolic engine up
            to 67%. All meals were created by our professional nutritionists.
            You&apos;ll have your own meal plan, with unique set of recipes and
            their nutritional value, based on your own profile. For your
            convenience, we will provide individual grocery list for each week.
          </Regular>
        </TextBlock>
      </Flex>
    </ItemMargin>
  </>
);

const ItemMargin = styled.div`
  margin-bottom: 3rem;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${props => props.direction || 'row'};

  @media ${mobile} {
    display: block;
    margin-bottom: 2.5rem;
  }
`;

const TextBlock = styled.div`
  flex: 0.6;
`;

const ImgBox = styled.div`
  position: relative;
  flex: 0.4;
  margin-right: 2rem;

  @media ${mobile} {
    width: 80%;
    margin: 0 auto 1rem auto;
  }
`;

const Img = styled.div`
  padding-bottom: 100%;
  background: url(${props => props.bg});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;
