import React from 'react';
import styled from 'styled-components';

import { colors } from '../styles/colors';
import { mobile } from '../styles/breakpoints';
import { Caption, OlWrapper, Regular, Seo } from '../components';

export const Privacy = () => (
  <>
    <Seo title="Terms and policies | B.Moment" />
    <Container>
      <Wrapper>
        <Heading>Privacy Policy</Heading>
        <RegularText margin="0 0 0.5rem 0">
          In this privacy policy, we, Kilo Grupė UAB, company code 303157579,
          registered at Ramybės str. 4-70, Vilnius, Lithuania, which is an
          official owner of the Bmoment (“Company”, “we”, “us” or “our”),
          explain how we handle your personal data when you visit our website,
          contact us through our official social media pages or email, and/or
          use our services.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          In this notice you will find the answers to the following questions:
        </RegularText>
        <OlWrapper margin="0 0 30px 0">
          <Li>how we use your data;</Li>
          <Li>when we provide your data to others;</Li>
          <Li>how long we store your data;</Li>
          <Li>what is our marketing policy;</Li>
          <Li>what rights related to personal data you possess;</Li>
          <Li>how we use cookies;</Li>
          <Li>other issues that you should take into account.</Li>
          <Li>
            In case of any inquiries or if you would like to exercise any of
            your rights provided in this notice, you may submit such inquiries
            and requests by email: hello@bmoment.co.
          </Li>
        </OlWrapper>
        <RegularText margin="0 0 0.5rem 0">
          All the definitions used in this privacy policy have the same meaning
          as prescribed in Company’s General Conditions unless expressly
          provided otherwise in this privacy policy. This privacy policy forms
          an integral part of Company’s General Conditions.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          In the event this privacy policy is translated into other languages
          and if there are differences between the English version and such
          translation, the English version shall prevail, unless otherwise
          provided.
        </RegularText>
        <Caption className="bolder" margin="1.5rem 0">
          HOW WE USE YOUR PERSONAL DATA?
        </Caption>
        <RegularText margin="0 0 0.5rem 0">
          1.1. This Section provides the following information:
        </RegularText>
        <OlWrapper margin="0 0 30px 0">
          <Li>categories of personal data, that we process;</Li>
          <Li>
            in case of the personal data that we did not obtain directly from
            you, the source and specific categories of that data;
          </Li>
          <Li>the purposes for which we may process your personal data; and</Li>
          <Li>the legal bases of the processing.</Li>
        </OlWrapper>
        <RegularText margin="0 0 0.5rem 0">
          1.2. We process information relating to provision of services by us to
          you (“service data”). The service data may include your contact
          details (such as your email address). The legal basis for this
          processing is the performance of a contract between you and us and/or
          taking steps, at your request, to enter into such a contract and our
          legitimate interests, namely the proper administration of our website
          and business.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          1.3. We may process information that you provide to us for the purpose
          of subscribing to our email messages and newsletters (“messaging
          data”). The messaging data is processed to send you the relevant
          messages and newsletters. The legal basis for this processing is your
          consent.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          1.4. We may process information relating to any communication that you
          send to us (“correspondence data”). The correspondence data may
          include the communication content and metadata associated with the
          communication. The correspondence data is processed for the purposes
          of communicating with you and record-keeping. The legal basis for this
          processing is our legitimate interests, namely the proper
          administration of our website and business, ensuring uniform and high
          quality consultation practice and for investigating disputes between
          you and our employees.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          1.5. We may process information on your use of our website and/or apps
          as well as on your device (“device data”) when you are browsing our
          website. Device data may include IP address, geographical location,
          browser type and version, operating system, device type, screen
          resolution and (in case you agree to share such) your location data as
          well as information on the use of our website and apps (i.e. referral
          source, length of visit, page views and website navigation paths, as
          well as information about the timing, frequency and pattern of your
          service use). We obtain such data through the use of cookies and
          similar technologies. Device data is processed to enhance the website
          as well as to set default options. We also use such data to have a
          better understanding of how you use our website and services as well
          as for securing the website. The legal basis for this processing is
          our legitimate interest, namely the proper management of our website
          and business.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          1.6. We may process any of your personal data identified in this
          notice where necessary for the establishment, exercise or defence of
          legal claims, whether in court proceedings or in an administrative or
          out-of-court procedure. The legal basis for this processing is our
          legitimate interests, namely the protection and assertion of our legal
          rights, your legal rights and the legal rights of others.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          1.7. We may process any of your personal data identified in this
          notice where necessary for the purposes of obtaining or maintaining
          insurance coverage, managing risks, or obtaining professional advice.
          The legal basis for this processing is our legitimate interests,
          namely the proper protection of our business against risks.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          1.8. In addition to the specific purposes for which we may process
          your personal data set out in this Section, we may also process any of
          your personal data where such processing is necessary for compliance
          with a legal obligation to which we are subject, or in order to
          protect your vital interests or the vital interests of another natural
          person.
        </RegularText>
        <Caption className="bolder" margin="1.5rem 0">
          WHEN WE PROVIDE YOUR DATA TO OTHERS?
        </Caption>
        <RegularText margin="0 0 0.5rem 0">
          2.1. We may disclose your personal data to any member of our group of
          companies (including our subsidiaries, our ultimate holding company
          and all its subsidiaries) insofar as reasonably necessary for the
          purposes set out in this notice. Such may include internal
          administration purposes as well as provision/sharing of IT services or
          data centres in the group.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          2.2. We may disclose your personal data to our insurers and/or
          professional advisers insofar as reasonably necessary for the purposes
          of obtaining or maintaining insurance coverage, managing risks,
          obtaining professional advice, or the establishment, exercise or
          defence of legal claims, whether in court proceedings or in an
          administrative or out-of-court procedure.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          2.3. We may disclose your personal data to our anti-fraud, risks and
          compliance providers insofar as reasonably necessary for the purposes
          of protecting your personal data and fulfilling our legal obligations.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          2.4. We may disclose your personal data to other service providers
          insofar as it is reasonably necessary to provide specific services
          (including, providers of servers and maintenance thereof, email
          service providers, service providers used for data analysis, customer
          satisfaction surveys or market research). We take all the necessary
          measures to ensure that such subcontractors would implement proper
          organisational and technical measures to ensure security and privacy
          of your personal data.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          2.5. In addition to the specific disclosures of personal data set out
          in this Section, we may disclose your personal data where such
          disclosure is necessary for compliance with a legal obligation to
          which we are subject, or in order to protect your vital interests or
          the vital interests of another natural person.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          2.6. Persons, indicated in this Section may be established outside the
          Republic of Lithuania, European Union and European Economic Area. In
          case we will transfer your personal data to such persons, we will take
          all the necessary and in the legal acts indicated measures to ensure
          that your privacy will remain properly secured, including where
          appropriate, signing standard contractual clauses for transfer of
          data. To find out more information regarding appropriate safeguards
          you may contact us via email: hello@bmoment.co
        </RegularText>
        <Caption className="bolder" margin="1.5rem 0">
          HOW LONG WE STORE YOUR DATA?
        </Caption>
        <RegularText margin="0 0 0.5rem 0">
          3.1. Your personal data that we process for any purpose or purposes
          shall not be kept for longer than is necessary for that purpose or
          those purposes. In any case it shall be kept for no longer than:
        </RegularText>
        <OlWrapper margin="0 0 30px 0">
          <Li>
            messaging data will be retained for no longer than 2 (two) years
            following the provision of consent or, in case, the messaging data
            is being sent to the present clients in order to maintain and
            improve customer relations, for no longer than 2 (two) years
            following the end of provision of the respective services, unless
            you respectively withdraw your consent earlier or object to such
            processing;
          </Li>
          <Li>
            correspondence data will be retained for no longer than 6 (six)
            months following the end of such communication.
          </Li>
        </OlWrapper>
        <RegularText margin="0 0 0.5rem 0">
          3.2. In some cases, it is not possible for us to specify in advance
          the periods for which your personal data will be retained. I. e.
          device data will be retained for as much as will be necessary for the
          relevant processing purposes.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          3.3. Notwithstanding the other provisions of this Section, we may
          retain your personal data where such retention is necessary for
          compliance with a legal obligation to which we are subject, or in
          order to protect your vital interests or the vital interests of
          another natural person.
        </RegularText>
        <Caption className="bolder" margin="1.5rem 0">
          MARKETING MESSAGES
        </Caption>
        <RegularText margin="0 0 0.5rem 0">
          4.1. In case you consent, we will send you marketing messages via
          email to inform you on what we are up to. Also, if we already have
          provided services to you and you do not object, we will inform you
          about our other products that might interest you including other
          information related to such.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          4.2. You may opt-out of receiving marketing messages at any time via
          email: hello@bmoment.co
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          4.3. Please be informed that as our business activities consists of a
          network of closely related services, it may take a few days until all
          the systems are updated, thus you may continue to receive marketing
          messages while we are still processing your request.
        </RegularText>
        <Caption className="bolder" margin="1.5rem 0">
          YOUR RIGHTS
        </Caption>
        <RegularText margin="0 0 0.5rem 0">
          5.1. In this Section, we have summarised the rights that you have
          under data protection laws. Some of the rights are complex thus we
          only provide the main aspects of such rights. Accordingly, you should
          read the relevant laws (first and foremost the General Data Protection
          Regulation (EU) 2016/679) and guidance from the regulatory authorities
          for a full explanation of these rights.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          5.2. Your principal rights under data protection law are the
          following:
        </RegularText>
        <OlWrapper margin="0 0 30px 0">
          <Li>the right to access data;</Li>
          <Li>the right to rectification;</Li>
          <Li>the right to erasure of your personal data;</Li>
          <Li>the right to restrict processing of your personal data;</Li>
          <Li>the right to object to processing of your personal data;</Li>
          <Li>the right to data portability;</Li>
          <Li>the right to complain to a supervisory authority; and</Li>
          <Li>the right to withdraw consent.</Li>
        </OlWrapper>
        <RegularText margin="0 0 0.5rem 0">
          5.3. The right to access data. You have the right to confirmation as
          to whether or not we process your personal data and, where we do,
          access to the personal data, together with certain additional
          information. That additional information includes details of the
          purposes of the processing, the categories of personal data concerned
          and the recipients of the personal data. Providing the rights and
          freedoms of others are not affected, we will supply to you a copy of
          your personal data. The first copy will be provided free of charge,
          but additional copies may be subject to a reasonable fee.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          5.4. The right to rectification. You have the right to have any
          inaccurate personal data about you rectified and, taking into account
          the purposes of the processing, to have any incomplete personal data
          about you completed.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          5.5. In some circumstances you have the right to the erasure of your
          personal data. Those circumstances include when: (i) the personal data
          are no longer necessary in relation to the purposes for which they
          were collected or otherwise processed; (ii) you withdraw consent to
          consent-based processing and there is no other legal basis to process
          data; (iii) you object to the processing under certain rules of
          applicable data protection laws; (iv) the processing is for direct
          marketing purposes; or (v) the personal data have been unlawfully
          processed. However, there are exclusions of the right to erasure. Such
          exclusions include when processing is necessary: (i) for exercising
          the right of freedom of expression and information; (ii) for
          compliance with our legal obligation; or (iii) for the establishment,
          exercise or defence of legal claims.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          5.6. In some circumstances you have the right to restrict the
          processing of your personal data. Those circumstances are when: (i)
          you contest the accuracy of the personal data; (ii) processing is
          unlawful but you oppose erasure; (iii) we no longer need the personal
          data for the purposes of our processing, but you require personal data
          for the establishment, exercise or defence of legal claims; and (iv)
          you have objected to processing, pending the verification of that
          objection. Where processing has been restricted on this basis, we may
          continue to store your personal data, however we will only further
          process such data in any other way: (i) with your consent; (ii) for
          the establishment, exercise or defence of legal claims; (iii) for the
          protection of the rights of another person; or (iv) for reasons of
          important public interest.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          5.7. You have the right to object to our processing of your personal
          data on grounds relating to your particular situation, but only to the
          extent that the legal basis for the processing is that the processing
          is necessary for: the performance of a task carried out in the public
          interest or the purposes of the legitimate interests pursued by us or
          by a third party. If you make such an objection, we will cease to
          process the personal information unless we can demonstrate compelling
          legitimate grounds for the processing which override your interests,
          rights and freedoms, or the processing is for the establishment,
          exercise or defence of legal claims.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          5.8. You have the right to object to our processing of your personal
          data for direct marketing purposes (including profiling for direct
          marketing purposes). If you make such an objection, we will cease to
          process your personal data for this purpose.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          5.9. You have the right to object to our processing of your personal
          data for scientific or historical research purposes or statistical
          purposes on grounds relating to your particular situation, unless the
          processing is necessary for the performance of a task carried out for
          reasons of public interest.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          5.10. The right to data portability. To the extent that the legal
          basis for our processing of your personal data is:
        </RegularText>
        <OlWrapper margin="0 0 30px 0">
          <Li>consent; or</Li>
          <Li>
            performance of a contract or steps to be taken at your request prior
            to entering into a contract, necessary to enter into such, you have
            the right to receive your personal data from us in a structured,
            commonly used and machine-readable format. However, this right does
            not apply where it would adversely affect the rights and freedoms of
            others.
          </Li>
        </OlWrapper>
        <RegularText margin="0 0 0.5rem 0">
          5.11. If you consider that our processing of your personal information
          infringes data protection laws, you have a legal right to lodge a
          complaint with a supervisory authority responsible for data
          protection. You may do so in the EU member state of your habitual
          residence, your place of work or the place of the alleged
          infringement. Our data processing is supervised by State Data
          Protection Inspectorate of the Republic of Lithuania, registered
          office at L. Sapiegos St. 17, LT-10312 Vilnius, www.ada.lt.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          5.12. To the extent that the legal basis for our processing of your
          personal information is consent, you have the right to withdraw that
          consent at any time. Withdrawal will not affect the lawfulness of
          processing before the withdrawal.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          5.13. In addition to specific measure provided in this Section or the
          website you may also exercise any of the rights indicated herein by
          contacting us by email: hello@bmoment.co
        </RegularText>
        <Caption className="bolder" margin="1.5rem 0">
          ABOUT COOKIES
        </Caption>
        <RegularText margin="0 0 0.5rem 0">
          6.1. Cookies are small textual files containing identifier that is
          sent by a web server to your web browser and is stored by the browser.
          The identifier is then sent back to the server each time the browser
          requests a page from the server.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          6.2. Cookies do not typically contain any information that personally
          identifies a user, but personal information that we store about you
          may be linked to the information stored in and obtained from cookies.
        </RegularText>
        <Caption className="bolder" margin="1.5rem 0">
          COOKIES THAT WE USE
        </Caption>
        <RegularText margin="0 0 0.5rem 0">
          In the website we use cookies of three main types, for the following
          purposes:
        </RegularText>
        <OlWrapper margin="0 0 30px 0">
          <Li>
            Required cookies – used to ensure proper performance of the website,
            security of customers and their data, provision of high-quality
            services;
          </Li>
          <Li>
            Functional cookies – used to enhance the website user experience,
            analyse the use of the system and in accordance to such improve the
            provision of services;
          </Li>
          <Li>
            Advertising cookies – used to observer user online behaviour and
            optimize marketing campaigns according to such information.
          </Li>
        </OlWrapper>
        <Caption className="bolder" margin="1.5rem 0">
          COOKIES USED BY OUR SERVICE PROVIDERS
        </Caption>
        <RegularText margin="0 0 0.5rem 0">
          8.1. Our service providers use cookies and those cookies may be stored
          on your computer when you visit our website.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">8.2. We use:</RegularText>
        <OlWrapper margin="0 0 30px 0">
          <Li>
            Google Analytics cookies to observe our website traffic. Cookies
            used for this purpose help us detect website errors as well as
            measure website bandwidth.{' '}
          </Li>
          <Li>
            Youtube cookies to display in our website content uploaded in
            Youtube. Cookies used for this purpose help us maintain integrity,
            create informative and dynamic website.{' '}
          </Li>
          <Li>
            Twitter cookies to display in our website content posted in Twitter.
            Cookies used for this purpose help us maintain integrity, create
            informative and dynamic website.{' '}
          </Li>
          <Li>
            Google Maps cookies to, if the user permits, determine users
            location. Cookies used for this purpose help us adapt website
            settings in accordance to user’s location and improve user
            experience in our website.{' '}
          </Li>
          <Li>
            Doubleclick cookies to control the display of ads to our users.
            Cookies used for this purpose help us distinguish users that already
            use our services and reduce or stop displaying our ads to such
            users.{' '}
          </Li>
          <Li>
            Facebook cookies to manage the display of ads to our users. Cookies
            used for this purpose help us distinguish users that already use our
            services and reduce or stop displaying our ads to such users.{' '}
          </Li>
          <Li>
            Google Tag Manager cookies to control advertising cookies. Cookies
            used for this purpose help us properly distribute ads to users.{' '}
          </Li>
          <Li>
            Hotjar cookies to observe how users use our website. Cookies used
            for this purpose help us observe the performance of the website and
            analyse how we can improve our website.{' '}
          </Li>
        </OlWrapper>
        <Caption className="bolder" margin="1.5rem 0">
          HOW CAN YOU MANAGE COOKIES?
        </Caption>
        <RegularText margin="0 0 0.5rem 0">
          9.1. Most browsers allow you to refuse to accept cookies and to delete
          cookies. The methods for doing so vary from browser to browser, and
          from version to version. You can however obtain up-to-date information
          about blocking and deleting cookies via information provided in the
          relevant browser website, for example Chrome; Firefox; Internet
          Explorer; Safari.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          9.2. Blocking all cookies will have a negative impact upon the
          usability of many websites.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          9.3. If you block cookies, you will not be able to use all the
          features on our website.
        </RegularText>
        <Caption className="bolder" margin="1.5rem 0">
          THIRD PARTY WEBSITES
        </Caption>
        <RegularText margin="0 0 0.5rem 0">
          In the website you may find links to and from third party sites,
          information sources and related party websites. Please take note that
          such third party website that you will visit by clicking on links have
          their own privacy policies and we take no responsibility regarding
          such privacy policies. We recommend familiarising with privacy
          policies of such websites before providing any personal data to such.
        </RegularText>
        <Caption className="bolder" margin="1.5rem 0">
          CHILDREN PERSONAL DATA
        </Caption>
        <RegularText margin="0 0 0.5rem 0">
          11.1. Our website and services are targeted at persons over the age of
          18.
        </RegularText>
        <RegularText margin="0 0 0.5rem 0">
          11.2. If we have reason to believe that we hold personal data of a
          person under that age in our databases without having consent from the
          parent rights holder, we will delete that personal data.
        </RegularText>
        <Caption className="bolder" margin="1.5rem 0">
          UPDATING YOUR DATA
        </Caption>
        <RegularText margin="0 0 0.5rem 0">
          Please let us know if the personal information that we hold about you
          needs to be corrected or updated.
        </RegularText>
        <Caption className="bolder" margin="1.5rem 0">
          CHANGES TO THE NOTICE
        </Caption>
        <RegularText margin="0 0 0.5rem 0">
          Any changes to this notice will be published in the website and, in
          case of material changes, we may inform you about such via email or by
          other means which we will deem the most fitting in a particular case.
        </RegularText>
      </Wrapper>
    </Container>
  </>
);

const Container = styled.div`
  padding-top: 53px;
  padding-bottom: 55px;
  background-color: ${colors.lightRed};

  @media ${mobile} {
    padding: 29px 24px;
  }
`;
const Wrapper = styled.div`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
`;
const Heading = styled(Regular)`
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 13px;
  color: ${colors.black};
`;
const RegularText = styled(Regular)`
  font-size: 15px;
  line-height: 23px;
  color: ${colors.blackAlpha(0.8)};
`;
const Li = styled.li`
  font-size: 15px;
  line-height: 23px;
  color: ${colors.blackAlpha(0.8)};
`;
