import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { fetchQuiz } from '../../utils/api';
import { actions } from '../../state/actions';
import { MagicalMoments, OurPromise, Seo, Superfruits } from '../../components';
import { HomeHeader } from './HomeHeader';
import { FacialRituals } from './FacialRituals';
import { MoisturisingFaceMask } from './MoisturisingFaceMask';
import { StartYourRitual } from './StartYourRitual';

export const Home = () => {
  const dispatch = useDispatch();
  const handleFetchQuiz = useCallback(fetchQuiz, []);

  useEffect(() => {
    dispatch(actions.app.setVariant(1));
    handleFetchQuiz({
      onSetQuestions: questions =>
        dispatch(actions.funnel.setQuestions(questions)),
    });
  }, [handleFetchQuiz]);

  return (
    <>
      <Seo title="Bmoment - Facial rituals for mother & daughter" />
      <HomeWrapper>
        <HomeHeader />
        <MagicalMoments />
        <FacialRituals />
        <MoisturisingFaceMask />
        <Superfruits />
        <OurPromise />
        <StartYourRitual />
      </HomeWrapper>
    </>
  );
};

const HomeWrapper = styled.div`
  position: relative;
  display: block;
  text-align: center;
  margin-top: 70px;
`;
