import { Api } from '../apis/api';

export const fetchQuiz = async ({
  setLoading = () => null,
  onSetQuestions,
}) => {
  try {
    setLoading(true);

    const res = await Api.getQuiz();
    const quiz = res.data;

    setLoading(false);
    onSetQuestions(quiz);
  } catch (e) {
    console.log(e);
    setLoading(false);
  }
};
