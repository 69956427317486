import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { icons } from '../../../utils/icons';
import { colors } from '../../../styles/colors';
import { xsmDesktop } from '../../../styles/breakpoints';
import { Regular } from '../../../components';
import { ReactComponent as CheckmarkIcon } from '../../../assets/images/checkout/checkmark.svg';

export const SubscriptionItem = ({
  id,
  itemId,
  title,
  discount,
  isGift,
  billedMonths,
  monthlyPrice,
  ritualsCount,
  isMostPopular,
  totalPrice,
  totalPriceDiscounted,
  isSelected,
  onSelect,
}) => (
  <Container id={id} onClick={onSelect}>
    <Circle className={isSelected ? 'selected' : null}>
      <CheckmarkIcon />
    </Circle>
    <div>
      <Title className={isSelected ? 'selected' : null}>{title}</Title>
      {itemId === '1_month' ? (
        <RitualsCountText>
          <Bold>1 ritual for free</Bold>, in total 4 beauty rituals
        </RitualsCountText>
      ) : (
        <RitualsCountText>{ritualsCount} beauty rituals, 1/w</RitualsCountText>
      )}
      <PricePerRitualContainer>
        <PricePerRitual>${monthlyPrice}</PricePerRitual>
        <PricePerRitualLabel>&nbsp;/ mo</PricePerRitualLabel>
      </PricePerRitualContainer>
      <MonthlyPriceContainer>
        {totalPrice && billedMonths !== 1 ? (
          <OldPrice>${totalPrice}</OldPrice>
        ) : null}
        <NewPrice>
          {totalPriceDiscounted
            ? `\u00A0\u00A0$${totalPriceDiscounted}\u00A0`
            : null}
          billed{' '}
          {billedMonths === 1 ? 'every month' : `every ${billedMonths} months`}
        </NewPrice>
      </MonthlyPriceContainer>
    </div>
    {isMostPopular ? (
      <MostPopularContainer>
        <MostPopularText>Most Popular</MostPopularText>
      </MostPopularContainer>
    ) : null}
    {discount ? (
      <DiscountContainer>
        <DiscountText>Save {discount}%</DiscountText>
      </DiscountContainer>
    ) : null}
    {isGift ? (
      <GiftContainer>
        <GiftIcon src={icons.checkout_gift} alt="Gift" />
        <GiftText>Gift</GiftText>
      </GiftContainer>
    ) : null}
  </Container>
);

SubscriptionItem.propTypes = {
  billedMonths: PropTypes.number.isRequired,
  discount: PropTypes.number,
  id: PropTypes.string.isRequired,
  isGift: PropTypes.bool,
  isMostPopular: PropTypes.bool,
  isSelected: PropTypes.bool.isRequired,
  itemId: PropTypes.string.isRequired,
  monthlyPrice: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  ritualsCount: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  totalPrice: PropTypes.number,
  totalPriceDiscounted: PropTypes.number,
};

SubscriptionItem.defaultProps = {
  discount: null,
  isGift: false,
  isMostPopular: false,
  totalPrice: null,
  totalPriceDiscounted: null,
};

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 327px;
  padding: 32px 17px 35px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  background-color: ${colors.white};

  &.selected {
    border: 2px solid ${colors.greenLighter};
  }

  path {
    fill: ${colors.white};
  }

  @media ${xsmDesktop} {
    margin-bottom: 37px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;
const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  margin-right: 11px;
  border: 1px solid #e0e0e0;
  background-color: ${colors.white};

  &.selected {
    border: 0;
    background-color: ${colors.greenLighter};
  }
`;
const Title = styled(Regular)`
  font-size: 20px;
  font-weight: 600;
  color: ${colors.black};

  &.selected {
    color: ${colors.greenLighter};
  }
`;
const RitualsCountText = styled(Regular)`
  display: inline-block;
  font-size: 15px;
  line-height: 26px;
  color: ${colors.blackAlpha(0.8)};
`;
const Bold = styled.span`
  font-weight: bold;
`;
const PricePerRitualContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 16px;
`;
const PricePerRitual = styled(Regular)`
  font-size: 28px;
  font-weight: 600;
  color: ${colors.black};
`;
const PricePerRitualLabel = styled(Regular)`
  padding-top: 5px;
  font-size: 15px;
  color: ${colors.blackAlpha(0.8)};
`;
const MonthlyPriceContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 5px;
`;
const OldPrice = styled(Regular)`
  font-size: 15px;
  font-weight: 600;
  color: ${colors.error};
  text-decoration-line: line-through;
`;
const NewPrice = styled(Regular)`
  font-size: 15px;
  color: ${colors.blackAlpha(0.8)};
`;
const DiscountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 114px;
  height: 36px;
  position: absolute;
  top: 0;
  right: -1px;
  transform: translateY(-50%);
  border-radius: 7px;
  border-bottom-right-radius: 0;
  background-color: #f9dbdd;
`;
const DiscountText = styled(Regular)`
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${colors.error};
`;
const MostPopularContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 36px;
  position: absolute;
  top: 0;
  left: -1px;
  transform: translateY(-50%);
  border-radius: 7px;
  border-bottom-left-radius: 0;
  background-color: ${colors.greenLighter};
`;
const MostPopularText = styled(Regular)`
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${colors.white};
`;
const GiftContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 114px;
  height: 36px;
  position: absolute;
  top: 0;
  right: -1px;
  transform: translateY(-50%);
  border-radius: 7px;
  border-bottom-right-radius: 0;
  background-color: ${colors.error};
`;
const GiftIcon = styled.img`
  margin-right: 6px;
`;
const GiftText = styled(Regular)`
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${colors.white};
`;
