import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { icons } from '../../utils/icons';
import { smTablet, tablet } from '../../styles/breakpoints';
import { colors } from '../../styles/colors';
import { Regular } from '../../components';

const CheckoutDescriptionItem = ({ label }) => (
  <CheckoutDescriptionItemContainer>
    <CheckmarkWrapper>
      <img src={icons.checkout_checkmark} alt="Checkmark" />
    </CheckmarkWrapper>
    <Text>{label}</Text>
  </CheckoutDescriptionItemContainer>
);

CheckoutDescriptionItem.propTypes = {
  label: PropTypes.string.isRequired,
};

export const CheckoutDescription = ({ isVisibleOnTablet }) => {
  const variant = useSelector(({ app }) => app.variant);
  const labels =
    variant === 2
      ? [
          'Make your skin refreshed and glowing, and keep your mind relaxed',
          'Enjoy the fulfilment of strengthening the bond with your daughter',
          'Create unforgettable moments and memories that will last forever',
        ]
      : [
          'Refreshed & glowing skin',
          'Stronger bond with your daughter',
          'Special moments that will last forever',
        ];

  return (
    <Container isVisibleOnTablet={isVisibleOnTablet}>
      <Wrapper>
        {labels.map(label => (
          <CheckoutDescriptionItem key={label} label={label} />
        ))}
      </Wrapper>
    </Container>
  );
};

CheckoutDescription.propTypes = {
  isVisibleOnTablet: PropTypes.bool,
};

CheckoutDescription.defaultProps = {
  isVisibleOnTablet: true,
};

const Container = styled.div`
  padding: 62px 24px 34px;
  background-color: ${colors.lightRoseGold};

  @media ${smTablet} {
    padding: 22px 24px;
  }

  @media ${tablet} {
    display: ${props => (props.isVisibleOnTablet ? 'block' : 'none')};
  }
`;
const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
`;
const CheckoutDescriptionItemContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 28px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;
const CheckmarkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 14px;
  border-radius: 10px;
  background-color: ${colors.white};
`;
const Text = styled(Regular)`
  max-width: 284px;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  color: ${colors.blackAlpha(0.8)};

  @media ${smTablet} {
    font-weight: normal;
  }
`;
