import React from 'react';
import PropTypes from 'prop-types';
import keys from 'lodash/fp/keys';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { mobile } from '../../styles/breakpoints';
import { DaughtersDataSchema } from '../../utils/validations';
import { DefaultButton, fadeIn, Input } from '..';

function getInitialFormValues({ daughtersCount, daughtersData = {} }) {
  const result = {};

  for (let i = 0; i < daughtersCount; i += 1) {
    // result[`daughter${i + 1}_age`] =
    //   daughtersData[`daughter${i + 1}_age`] || '';
    result[`daughter${i + 1}_name`] =
      daughtersData[`daughter${i + 1}_name`] || '';
  }

  return result;
}

export const DaughtersData = ({ onSelect }) => {
  const daughtersCount = useSelector(({ funnel }) =>
    parseInt(funnel.userInfo.daughters, 10),
  );
  const daughtersData = useSelector(
    ({ funnel }) => funnel.userInfo.daughtersData,
  );
  const formik = useFormik({
    initialValues: {
      ...getInitialFormValues({ daughtersCount, daughtersData }),
    },
    onSubmit: onSelect,
    validateOnMount: true,
    validationSchema: DaughtersDataSchema(daughtersCount),
  });

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        {Array.from(Array(daughtersCount)).map((_, index) => (
          <Row key={index}>
            <NameInput
              id={`daughterNameInput${index + 1}`}
              name={`daughter${index + 1}_name`}
              type="text"
              placeholder={`Name${daughtersCount > 1 ? ` ${index + 1}` : ''}`}
              value={formik.values[`daughter${index + 1}_name`]}
              onChange={formik.handleChange}
            />
            {/* <AgeInput
              min={1}
              max={120}
              type="number"
              id={`daughterAgeInput${index + 1}`}
              name={`daughter${index + 1}_age`}
              placeholder={`Years${daughtersCount > 1 ? ` ${index + 1}` : ''}`}
              value={formik.values[`daughter${index + 1}_age`]}
              onChange={formik.handleChange}
            /> */}
          </Row>
        ))}
        <ButtonWrapper>
          <DefaultButton
            label="Next"
            type="submit"
            id="submitBtn"
            disabled={!!keys(formik.errors).length}
          />
        </ButtonWrapper>
      </Form>
    </>
  );
};

DaughtersData.propTypes = {
  onSelect: PropTypes.func,
};

const Form = styled.form`
  opacity: 0;
  animation: ${fadeIn} 0.2s ease-in forwards;
  animation-delay: 0.1s;

  @media ${mobile} {
    max-width: none;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;
const NameInput = styled(Input)`
  width: 100%;
`;
// const AgeInput = styled(Input)`
//   width: 34.8%;
// `;
const ButtonWrapper = styled.div`
  max-width: 327px;
  width: 100%;
  margin: 48px auto 0;
`;
