import React from 'react';
import styled from 'styled-components';

import { H1 } from '../../components';
import { icons } from '../../utils/icons';
import { smTablet, xsmDesktop } from '../../styles/breakpoints';

export const HomeHeader = () => (
  <Container>
    <Wrapper>
      <Rectangle1 src={icons.home_rectangle_1} alt="Rectangle" />
      <Rectangle2 src={icons.home_rectangle_2} alt="Rectangle" />
      <HeadingWrapper>
        <Heading>Unforgettable beauty rituals for mother and daughter</Heading>
        <Leaves1 src={icons.home_leaves_1} alt="Leaves" />
        <Leaves2 src={icons.home_leaves_2} alt="Leaves" />
        <Leaves3 src={icons.home_leaves_3} alt="Leaves" />
      </HeadingWrapper>
      <FaceMaskWrapper>
        <MomAndDaughter
          src={icons.home_mom_daughter_1}
          alt="Mom and daughter"
        />
        <FaceMask src={icons.home_face_mask} alt="Face mask" />
      </FaceMaskWrapper>
    </Wrapper>
  </Container>
);

const Container = styled.div`
  position: relative;
  padding: 0 24px;

  @media ${smTablet} {
    padding: 0;
  }
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto 80px;

  @media ${smTablet} {
    flex-direction: column;
    margin: 0 auto 50px;
  }
`;
const HeadingWrapper = styled.div`
  position: relative;
  width: 42%;

  @media ${smTablet} {
    width: 90%;
  }
`;
const Heading = styled(H1)`
  font-size: 46px;
  font-weight: 600;
  text-align: left;

  @media ${smTablet} {
    font-size: 32px;
  }
`;
const Leaves1 = styled.img`
  position: absolute;
  top: -40px;
  left: -50px;
`;
const Leaves2 = styled.img`
  position: absolute;
  top: -55px;
  right: 10px;
`;
const Leaves3 = styled.img`
  position: absolute;
  bottom: -70px;
  left: 35%;
`;
const FaceMaskWrapper = styled.div`
  position: relative;
  width: 50%;
  margin-left: 8%;

  @media ${smTablet} {
    width: auto;
    margin-left: 0;
    padding-top: 40px;
  }
`;
const FaceMask = styled.img`
  position: absolute;
  left: -250px;
  bottom: -50px;

  @media ${smTablet} {
    display: none;
  }

  @media ${xsmDesktop} {
    left: -80px;
    bottom: -125px;
  }
`;
const MomAndDaughter = styled.img`
  width: 100%;

  @media ${smTablet} {
    width: 90%;
  }
`;
const Rectangle1 = styled.img`
  position: absolute;
  bottom: -320px;
  left: 0;
  z-index: -1;

  @media ${smTablet} {
    display: none;
  }
`;
const Rectangle2 = styled.img`
  position: absolute;
  top: -44px;
  right: 0;
  z-index: -1;

  @media ${smTablet} {
    top: 120px;
    right: -50%;
  }
`;
