import { combineReducers } from 'redux';

import { constants } from './constants';
import { appReducer } from './app/AppReducer';
import { funnelReducer } from './funnel/FunnelReducer';
import { paymentsReducer } from './payments/PaymentsReducer';

const combinedReducer = combineReducers({
  app: appReducer,
  funnel: funnelReducer,
  payments: paymentsReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === constants.funnel.CLEAR_STATE) {
    return combinedReducer(undefined, action);
  }

  return combinedReducer(state, action);
};
