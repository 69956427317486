import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors } from '../../styles/colors';
import { mobile } from '../../styles/breakpoints';
import { DefaultButton, H4 } from '../../components';

export const EnjoyTheEffects = () => (
  <Container>
    <Wrapper>
      <Heading>
        Why not share and enjoy these rituals together, with B.Moment
      </Heading>
      <Link to="/email">
        <DefaultButton
          id="seeProductBtn2"
          label="SEE THE PRODUCT"
          type="button"
        />
      </Link>
    </Wrapper>
  </Container>
);

const Container = styled.div`
  padding: 60px 0;
  background-color: ${colors.lightRoseGold};
`;
const Wrapper = styled.div`
  max-width: 423px;
  width: 100%;
  margin: 0 auto;
  text-align: center;

  @media ${mobile} {
    padding: 0 24px;
  }
`;
const Heading = styled(H4)`
  padding-bottom: 22px;
  text-align: center;
`;
