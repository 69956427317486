import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import keys from 'lodash/fp/keys';

import { Api } from '../apis/api';
import { icons } from '../utils/icons';
import { colors } from '../styles/colors';
import { mobile } from '../styles/breakpoints';
import { actions } from '../state/actions';
import { EmailSchema } from '../utils/validations';
import { DefaultButton, fadeIn, Input, Regular, Seo } from '../components';

export const Email = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: { email: '' },
    validateOnMount: true,
    validationSchema: EmailSchema,
    onSubmit: async () => {
      try {
        setLoading(true);

        const response = await Api.updateEmail({
          id: userInfo.id,
          email: formik.values.email,
        });

        dispatch(actions.funnel.updateUserInfo(response.data));
        push('/checkout3');
      } catch (error) {
        setLoading(false);
      }
    },
  });
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userInfo, questions, daughtersEditionQuestions } = useSelector(
    ({ funnel }) => funnel,
  );

  useEffect(() => {
    if (
      !questions?.length ||
      !daughtersEditionQuestions?.length ||
      keys(userInfo).length < questions?.length
    ) {
      push('/');
    } else {
      setLoaded(true);
    }
  }, []);

  if (!loaded) {
    return null;
  }

  return (
    <>
      <Seo tile="Bmoment - Facial rituals for mother & daughter" />
      <Background>
        <Container>
          <Wrapper>
            <LogoWrapper>
              <Logo src={icons.logo} alt="Logo" onClick={() => push('/')} />
            </LogoWrapper>
            <TextContainer>
              <Text>
                Enter your email and get free easy-to-follow beauty ritual guide
              </Text>
              <Leaves1 src={icons.email_leaves_1} alt="Leaves" />
              <Leaves2 src={icons.email_leaves_2} alt="Leaves" />
            </TextContainer>
            <Form onSubmit={formik.handleSubmit}>
              <FormInput
                name="email"
                type="email"
                id="emailInput"
                placeholder="Enter your email"
                label="Your email:"
                mobileBackgroundColor={colors.white}
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              <DefaultButton
                loading={loading}
                disabled={!!keys(formik.errors).length || loading}
                label="Continue"
                type="submit"
              />
            </Form>
          </Wrapper>
        </Container>
      </Background>
    </>
  );
};

const Background = styled.div`
  padding-top: 4.5rem;
  min-height: 100vh;
  background: ${colors.background};

  @media ${mobile} {
    min-height: 100vh;
    padding-top: 0;
  }
`;
const Container = styled.div`
  position: relative;
  max-width: 860px;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
  text-align: center;
  background: ${colors.white};
  border-radius: 40px;

  @media ${mobile} {
    padding: 0;
    background-color: ${colors.background};
  }
`;
const Wrapper = styled.div`
  max-width: 545px;
  width: 100%;
  margin: 0 auto;
  min-height: 600px;
  padding-top: 40px;
  padding-bottom: 66px;

  @media ${mobile} {
    padding: 0;
  }
`;
const LogoWrapper = styled.div`
  @media ${mobile} {
    padding-top: 20px;
    padding-bottom: 13px;
    background-color: ${colors.white};
  }
`;
const Logo = styled.img`
  display: block;
  margin: 0 auto;
  cursor: pointer;
`;
const TextContainer = styled.div`
  position: relative;

  @media ${mobile} {
    padding: 0 24px;
  }
`;
const Text = styled(Regular)`
  display: inline-block;
  padding: 40px 0 60px;
  font-size: 22px;
  font-weight: 600;

  @media ${mobile} {
    padding: 34px 0 40px;
  }
`;
const Form = styled.form`
  opacity: 0;
  animation: ${fadeIn} 0.2s ease-in forwards;
  animation-delay: 0.1s;

  @media ${mobile} {
    max-width: none;
    padding: 0 24px;
  }
`;
const FormInput = styled(Input)`
  margin-bottom: 42px;
`;
const Leaves1 = styled.img`
  position: absolute;
  bottom: 30px;
  left: -45px;

  @media ${mobile} {
    display: none;
  }
`;
const Leaves2 = styled.img`
  position: absolute;
  top: -40px;
  right: -85px;

  @media ${mobile} {
    top: unset;
    bottom: -65px;
    right: 0;
  }
`;
