import { constants } from '../constants';

export const updateUserInfo = payload => ({
  type: constants.funnel.UPDATE_USER_INFO,
  payload,
});

export const updateUserDaughtersInfo = payload => ({
  type: constants.funnel.UPDATE_USER_DAUGHTERS_INFO,
  payload,
});

export const setQuestions = payload => ({
  type: constants.funnel.SET_QUESTIONS,
  payload,
});

export const clearState = () => ({
  type: constants.funnel.CLEAR_STATE,
});
