import React, { useState } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { icons } from '../../../utils/icons';
import { colors } from '../../../styles/colors';
import {
  smDesktop,
  smTablet,
  tablet,
  xsmDesktop,
} from '../../../styles/breakpoints';
import {
  DefaultButton,
  H3,
  Regular,
  RitualBundleItem,
  RitualBundleItemV3,
} from '../../../components';
import { BUNDLES, SUBSCRIPTION_PLANS } from '../CheckoutConstants';
import { BundleItem } from './BundleItem';
import { SubscriptionItem } from './SubscriptionItem';

const regularPlan = SUBSCRIPTION_PLANS.regular.find(
  ({ id }) => id === '1_month',
);
const maxiPlan = SUBSCRIPTION_PLANS.maxi.find(({ id }) => id === '1_month');
const regularBundle = BUNDLES.find(({ id }) => id === 'regular');
const maxiBundle = BUNDLES.find(({ id }) => id === 'maxi');

export const BeautyRitualPlan = ({
  variant,
  isVisibleOnTablet,
  selectedBundle,
  selectedSubscription,
  setIsProductsDescriptionModalVisible,
  setSelectedBundle,
  setSelectedSubscription,
  setVisibleBundle,
  onBuyClick,
}) => {
  const { location } = useHistory();
  const searchParsed = qs.parse(location.search);
  const [selectedBundleId, setSelectedBundleId] = useState(
    searchParsed?.bundleId || 'avocado_oil_regular_kit',
  );
  const { expected_effect, daughtersData } = useSelector(
    s => s.funnel.userInfo,
  );
  const momsFruit = expected_effect === 'glow' ? 'avocado' : 'acai_berry';
  const daughtersFruit =
    daughtersData?.daughter1_expected_effects === 'vitamin_mineral_boost'
      ? 'acai_berry'
      : 'avocado';

  return (
    <Container isVisibleOnTablet={isVisibleOnTablet}>
      {variant === 1 ? <Heading>Choose your beauty ritual plan</Heading> : null}
      <BundlesContainer>
        {variant === 3 ? null : <StepText>Step 1</StepText>}
        <StepTitle>
          {variant === 1
            ? 'Your ritual bundle:'
            : 'Choose one of the bestselling ritual bundles:'}
        </StepTitle>
        {variant === 1 ? (
          <BundleItems>
            {BUNDLES.map(({ id, title }, i) => (
              <BundleItem
                key={id}
                title={title}
                id={`bundleItem${i}`}
                momsFruit={momsFruit}
                daughtersFruit={daughtersFruit}
                image={icons[`checkout_${momsFruit}_${id}_plan`]}
                isSelected={selectedBundle === id}
                onSelect={() => setSelectedBundle(id)}
                onIncludedItemsClick={
                  setVisibleBundle ? () => setVisibleBundle(id) : null
                }
              />
            ))}
          </BundleItems>
        ) : null}
        {variant === 2 ? (
          <BundleItemsV2>
            <RitualBundleItemWrapper>
              <RitualBundleItem
                includedItems={regularBundle.includedItemsAvocadoKit}
                price={`$${regularPlan?.monthlyPrice || '-'}`}
                id="avocado_oil_regular_kit"
                title="Regular Beauty Kit with Avocado Oil"
                icon={icons.checkout_avocado_regular_plan}
                isSelected={selectedBundleId === 'avocado_oil_regular_kit'}
                buttonLabel={
                  selectedBundleId === 'avocado_oil_regular_kit'
                    ? 'CHOSEN'
                    : 'CHOOSE'
                }
                buttonBackgroundColor={
                  colors[
                    selectedBundleId === 'avocado_oil_regular_kit'
                      ? 'gradient'
                      : 'green'
                  ]
                }
                onBuyClick={id => {
                  setSelectedBundleId(id);
                  setSelectedBundle('regular');
                }}
              />
            </RitualBundleItemWrapper>
            <RitualBundleItemWrapper>
              <RitualBundleItem
                includedItems={maxiBundle.includedItemsAvocadoKit}
                price={`$${maxiPlan?.monthlyPrice || '-'}`}
                id="avocado_oil_maxi"
                title="Maxi Beauty Kit with Avocado Oil"
                icon={icons.checkout_avocado_maxi_plan}
                isSelected={selectedBundleId === 'avocado_oil_maxi'}
                buttonLabel={
                  selectedBundleId === 'avocado_oil_maxi' ? 'CHOSEN' : 'CHOOSE'
                }
                buttonBackgroundColor={
                  colors[
                    selectedBundleId === 'avocado_oil_maxi'
                      ? 'gradient'
                      : 'green'
                  ]
                }
                onBuyClick={id => {
                  setSelectedBundleId(id);
                  setSelectedBundle('maxi');
                }}
              />
            </RitualBundleItemWrapper>
            <RitualBundleItemWrapper>
              <RitualBundleItem
                includedItems={regularBundle.includedItemsAcaiKit}
                price={`$${regularPlan?.monthlyPrice || '-'}`}
                id="acai_extract_regular_kit"
                title="Regular Beauty Kit with Acai Berry Extract"
                icon={icons.checkout_acai_berry_regular_plan}
                isSelected={selectedBundleId === 'acai_extract_regular_kit'}
                buttonLabel={
                  selectedBundleId === 'acai_extract_regular_kit'
                    ? 'CHOSEN'
                    : 'CHOOSE'
                }
                buttonBackgroundColor={
                  colors[
                    selectedBundleId === 'acai_extract_regular_kit'
                      ? 'gradient'
                      : 'green'
                  ]
                }
                onBuyClick={id => {
                  setSelectedBundleId(id);
                  setSelectedBundle('regular');
                }}
              />
            </RitualBundleItemWrapper>
            <RitualBundleItemWrapper marginBottom={10}>
              <RitualBundleItem
                includedItems={maxiBundle.includedItemsAcaiKit}
                price={`$${maxiPlan?.monthlyPrice || '-'}`}
                id="acai_extract_maxi"
                title="Maxi Beauty Kit with Acai Berry Extract"
                icon={icons.checkout_acai_berry_maxi_plan}
                isSelected={selectedBundleId === 'acai_extract_maxi'}
                buttonLabel={
                  selectedBundleId === 'acai_extract_maxi' ? 'CHOSEN' : 'CHOOSE'
                }
                buttonBackgroundColor={
                  colors[
                    selectedBundleId === 'acai_extract_maxi'
                      ? 'gradient'
                      : 'green'
                  ]
                }
                onBuyClick={id => {
                  setSelectedBundleId(id);
                  setSelectedBundle('maxi');
                }}
              />
            </RitualBundleItemWrapper>
          </BundleItemsV2>
        ) : null}
        {variant === 3 ? (
          <BundleItems>
            {BUNDLES.map(({ id, title, reviewsCount }) => (
              <RitualBundleItemV3
                id={id}
                key={id}
                title={`${title} beauty kit with ${
                  momsFruit === 'avocado' ? 'avocado oil' : 'acai berry extract'
                }`}
                momsFruit={momsFruit}
                daughtersFruit={daughtersFruit}
                reviewsCount={reviewsCount[momsFruit] || reviewsCount.avocado}
                oneTimePlan={SUBSCRIPTION_PLANS[id].find(
                  ({ id }) => id === '1_month',
                )}
                monthlySubscriptionPlan={SUBSCRIPTION_PLANS[id].find(
                  ({ id }) => id === '6_months',
                )}
                icon={icons[`checkout_${momsFruit}_${id}_plan`]}
                onBuyClick={({ id, plan }) => {
                  setSelectedBundle(id);
                  setSelectedSubscription(plan);
                  onBuyClick({
                    bundle: plan,
                    subscription: id,
                  });
                }}
                onIncludedItemsClick={
                  setVisibleBundle ? () => setVisibleBundle(id) : null
                }
              />
            ))}
          </BundleItems>
        ) : null}
        <LinkText
          className="linkText"
          onClick={() => setIsProductsDescriptionModalVisible(true)}
        >
          Click here to see full products&apos; description
        </LinkText>
      </BundlesContainer>
      {variant === 3 ? null : (
        <SubscriptionsContainer>
          <StepText>Step 2</StepText>
          <StepTitle>Subscription length:</StepTitle>
          <SubscriptionsItems>
            {SUBSCRIPTION_PLANS[selectedBundle].map(
              (
                {
                  id,
                  title,
                  isGift,
                  discount,
                  billedMonths,
                  ritualsCount,
                  monthlyPrice,
                  totalPrice,
                  totalPriceDiscounted,
                  isMostPopular,
                },
                i,
              ) => (
                <SubscriptionItem
                  key={id}
                  id={`subscriptionItem${i}`}
                  itemId={id}
                  title={title}
                  isGift={isGift}
                  discount={discount}
                  monthlyPrice={monthlyPrice}
                  ritualsCount={ritualsCount}
                  billedMonths={billedMonths}
                  isMostPopular={isMostPopular}
                  totalPrice={totalPrice}
                  totalPriceDiscounted={totalPriceDiscounted}
                  isSelected={selectedSubscription === id}
                  onSelect={() => setSelectedSubscription(id)}
                />
              ),
            )}
          </SubscriptionsItems>
          <Footer>
            <DefaultButton
              id="paymentBtn"
              label="BUY NOW"
              type="button"
              onClick={() =>
                onBuyClick({
                  bundle: selectedBundle,
                  subscription: selectedSubscription,
                })
              }
            />
            <PaymentsImage src={icons.checkout_payments} alt="Payments" />
          </Footer>
        </SubscriptionsContainer>
      )}
    </Container>
  );
};

BeautyRitualPlan.propTypes = {
  isVisibleOnTablet: PropTypes.bool,
  onBuyClick: PropTypes.func.isRequired,
  selectedBundle: PropTypes.oneOf(['regular', 'maxi']).isRequired,
  selectedSubscription: PropTypes.oneOf(['1_month', '3_months', '6_months'])
    .isRequired,
  setIsProductsDescriptionModalVisible: PropTypes.func.isRequired,
  setSelectedBundle: PropTypes.func.isRequired,
  setSelectedSubscription: PropTypes.func.isRequired,
  setVisibleBundle: PropTypes.func,
  variant: PropTypes.oneOf([1, 2, 3]),
};

BeautyRitualPlan.defaultProps = {
  isVisibleOnTablet: true,
  setVisibleBundle: null,
  variant: 1,
};

const Container = styled.div`
  padding-top: 50px;

  @media ${tablet} {
    display: ${props => (props.isVisibleOnTablet ? 'block' : 'none')};
  }
`;
const Heading = styled(H3)`
  max-width: 1200px;
  width: 100%;
  padding-bottom: 51px;
  margin: 0 auto 55px;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;

  @media ${xsmDesktop} {
    padding: 0 24px 42px;
    margin: 0 auto 78px;
  }
`;
const StepText = styled(Regular)`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  color: ${colors.greenLighter};
`;
const StepTitle = styled(H3)`
  margin-bottom: 32px;
  text-align: center;
  color: ${colors.black};
`;
const BundleItems = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 710px;
  width: 100%;
  margin: 0 auto;

  @media ${smTablet} {
    align-items: center;
    flex-direction: column;
  }
`;
const BundleItemsV2 = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;

  @media ${smDesktop} {
    align-items: center;
    flex-direction: column;
  }
`;
const BundlesContainer = styled.div`
  padding-bottom: 70px;
  background-color: ${colors.white};

  @media ${smDesktop} {
    padding: 0 24px 20px;
  }
`;
const RitualBundleItemWrapper = styled.div`
  flex: 1 1 0;
  width: 0;
  margin-right: 31px;
  margin-bottom: ${props => props.marginBottom || '40px'};

  &:last-of-type {
    margin-right: 0;
  }

  @media ${smDesktop} {
    flex: initial;
    width: initial;
    margin-right: 0;
  }
`;
const SubscriptionsContainer = styled.div`
  padding-top: 55px;
  padding-bottom: 68px;
  background-color: ${colors.almostWhite};

  @media ${xsmDesktop} {
    padding: 50px 24px 36px;
  }
`;
const SubscriptionsItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1060px;
  width: 100%;
  padding-top: 33px;
  padding-bottom: 40px;
  margin: 0 auto;

  @media ${xsmDesktop} {
    align-items: center;
    flex-direction: column;
  }
`;
const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 327px;
  width: 100%;
  margin: 0 auto;
`;
const PaymentsImage = styled.img`
  margin-top: 33px;
`;
const LinkText = styled(Regular)`
  padding-top: 30px;
  font-size: 16px;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  color: ${colors.blackAlpha(0.8)};

  @media ${smTablet} {
    padding-top: 15px;
  }
`;
