import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { icons } from '../utils/icons';
import { colors } from '../styles/colors';
import { mobile } from '../styles/breakpoints';
import { Regular, Seo } from '../components';

export const Success = () => {
  const { push } = useHistory();
  const variant = useSelector(({ app }) => app.variant);
  const homeUrl = variant === 2 ? '/v2' : '/';

  return (
    <>
      <Seo tile="Bmoment - Facial rituals for mother & daughter" />
      <Background>
        <Container>
          <Wrapper>
            <Logo src={icons.logo} alt="Logo" onClick={() => push(homeUrl)} />
            <IconContainer>
              <Icon src={icons.success_sad_face} alt="Sad face" />
              <Leaves1 src={icons.success_leaves_1} alt="Leaves" />
              <Leaves2 src={icons.success_leaves_2} alt="Leaves" />
            </IconContainer>
            <Title>Oops!</Title>
            <Description>
              We&apos;re sorry! The server has encountered an internal error and
              was unable to finish your purchase.
            </Description>
            <Description>
              Your card has not been charged. For more information, please
              contact our support:&nbsp;
              <MailLink href="mailto:hello@bmoment.co">
                hello@bmoment.co
              </MailLink>
            </Description>
            <HorizontalLine />
            <FooterText>Please try again later</FooterText>
            <Leaves3 src={icons.success_leaves_3} alt="Leaves" />
          </Wrapper>
        </Container>
      </Background>
    </>
  );
};

const Background = styled.div`
  padding-top: 4.5rem;
  min-height: 100vh;
  background: ${colors.background};

  @media ${mobile} {
    min-height: 100vh;
    padding: 25px 0;
    background-color: ${colors.white};
  }
`;
const Container = styled.div`
  position: relative;
  max-width: 860px;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
  text-align: center;
  background: ${colors.white};
  border-radius: 40px;

  @media ${mobile} {
    padding: 0;
  }
`;
const Wrapper = styled.div`
  max-width: 545px;
  width: 100%;
  margin: 0 auto;
  min-height: 535px;
  padding-top: 40px;
  padding-bottom: 66px;

  @media ${mobile} {
    padding: 0 24px;
  }
`;
const Logo = styled.img`
  display: block;
  margin: 0 auto;
  cursor: pointer;
`;
const IconContainer = styled.div`
  position: relative;
  display: inline-block;
`;
const Icon = styled.img`
  width: 80px;
  padding-top: 54px;
`;
const Title = styled(Regular)`
  font-size: 28px;
  font-weight: 600;
  padding-top: 20px;
  padding-bottom: 10px;
`;
const Description = styled(Regular)`
  padding-bottom: 15px;
  font-size: 18px;
  font-weight: normal;
  color: ${colors.blackAlpha(0.8)};
`;
const MailLink = styled.a`
  color: ${colors.blackAlpha(0.8)};
`;
const HorizontalLine = styled.div`
  width: 100px;
  height: 1px;
  margin: 19px auto 26px;
  background-color: #e0e0e0;
`;
const FooterText = styled(Regular)`
  max-width: 276px;
  width: 100%;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 600;
  color: ${colors.blackAlpha(0.8)};
`;
const Leaves1 = styled.img`
  position: absolute;
  bottom: 20px;
  left: -100px;
`;
const Leaves2 = styled.img`
  position: absolute;
  top: 5px;
  right: -95px;
`;
const Leaves3 = styled.img`
  position: absolute;
  right: 27%;
  bottom: 0;

  @media ${mobile} {
    right: 15px;
    bottom: -50px;
  }
`;
