import React, { useState } from 'react';
import styled from 'styled-components';
import Carousel from '@brainhubeu/react-carousel';

import { CarouselDots, H2, RitualBundleItem } from '../../components';
import { icons } from '../../utils/icons';
import { smDesktop } from '../../styles/breakpoints';
import { colors } from '../../styles/colors';
import { SUBSCRIPTION_PLANS } from '../Checkout/CheckoutConstants';

const regularPlan = SUBSCRIPTION_PLANS.regular.find(
  ({ id }) => id === '1_month',
);
const maxiPlan = SUBSCRIPTION_PLANS.maxi.find(({ id }) => id === '1_month');

export const BestSellingRituals = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const carouselVisible = window.innerWidth < 1200;
  const ItemsContainerComponent = carouselVisible ? Carousel : ItemsContainer;

  return (
    <Container id="best-selling-rituals">
      <Wrapper>
        <Heading>Choose best selling rituals</Heading>
        <ItemsContainerComponent
          offset={20}
          itemWidth={277}
          centered={true}
          autoPlay={8000}
          minDraggableOffset={5}
          keepDirectionWhenDragging={true}
          slidesPerPage={2}
          value={activeSlide}
          onChange={nextSlide => {
            setActiveSlide(slide => {
              if (slide === 3) {
                return 0;
              }

              return nextSlide;
            });
          }}
        >
          <RitualBundleItem
            buttonLabel="SEE THE PRODUCT"
            price={`$${regularPlan?.monthlyPrice || '-'}`}
            id="avocado_oil_regular_kit"
            title="Regular Beauty Kit with Avocado Oil"
            icon={icons.checkout_avocado_regular_plan}
          />
          <RitualBundleItem
            buttonLabel="SEE THE PRODUCT"
            price={`$${maxiPlan?.monthlyPrice || '-'}`}
            id="avocado_oil_maxi"
            title="Maxi Beauty Kit with Avocado Oil"
            icon={icons.checkout_avocado_maxi_plan}
          />
          <RitualBundleItem
            buttonLabel="SEE THE PRODUCT"
            price={`$${regularPlan?.monthlyPrice || '-'}`}
            id="acai_extract_regular_kit"
            title="Regular Beauty Kit with Acai Berry Extract"
            icon={icons.checkout_acai_berry_regular_plan}
          />
          <RitualBundleItem
            buttonLabel="SEE THE PRODUCT"
            price={`$${maxiPlan?.monthlyPrice || '-'}`}
            id="acai_extract_maxi"
            title="Maxi Beauty Kit with Acai Berry Extract"
            icon={icons.checkout_acai_berry_maxi_plan}
          />
        </ItemsContainerComponent>
        {carouselVisible ? (
          <CarouselDots
            dotsCount={4}
            activeSlide={activeSlide}
            setActiveSlide={setActiveSlide}
          />
        ) : null}
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${colors.white};
`;
const Wrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 78px 0;

  @media ${smDesktop} {
    padding: 43px 0 40px;
  }
`;
const Heading = styled(H2)`
  text-align: center;
  padding: 0 18px 48px;

  @media ${smDesktop} {
    text-align: left;
  }
`;
const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
