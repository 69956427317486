import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { DefaultButton, NavWrapper } from '..';
import { icons } from '../../utils/icons';
import { colors } from '../../styles/colors';
import { mobile } from '../../styles/breakpoints';

export const SummaryHeader = () => {
  const { push } = useHistory();

  return (
    <Container maxWidth={1200}>
      <NavWrapper>
        <Wrapper>
          <Image src={icons.logo} alt="Logo" onClick={() => push('/')} />
          <LinkButton to="/email">
            <DefaultButton
              id="seeProductBtn1"
              label="SEE THE PRODUCT"
              type="button"
            />
          </LinkButton>
        </Wrapper>
      </NavWrapper>
    </Container>
  );
};

const Container = styled.div`
  padding: 1.5rem 0;
  background: ${colors.white};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Image = styled.img`
  cursor: pointer;

  @media ${mobile} {
    margin: 0 auto;
  }
`;
const LinkButton = styled(Link)`
  @media ${mobile} {
    display: none;
  }
`;
