import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import keys from 'lodash/fp/keys';

import { MagicalMoments, Seo, Timer } from '../../components';
import { CheckoutDescription } from './CheckoutDescription';
import { BeautyRitualPlan } from './BeautyRitualPlan';
import { Reviews } from './Reviews';
import { Faq } from './Faq';
import { BundleModal } from './BundleModal';
import { ProductsDescriptionModal } from './ProductsDescriptionModal';
import { PaymentModal } from './PaymentModal';

export const Checkout = () => {
  const variant = 1;
  const homeUrl = '/';
  const { push } = useHistory();
  const [loaded, setLoaded] = useState(false);
  const [visibleBundle, setVisibleBundle] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [
    isProductsDescriptionModalVisible,
    setIsProductsDescriptionModalVisible,
  ] = useState(false);
  const [selectedBundle, setSelectedBundle] = useState('regular');
  const [selectedSubscription, setSelectedSubscription] = useState('6_months');
  const { userInfo, questions, daughtersEditionQuestions } = useSelector(
    ({ funnel }) => funnel,
  );

  useEffect(() => {
    if (
      !questions?.length ||
      !daughtersEditionQuestions?.length ||
      keys(userInfo).length < questions?.length
    ) {
      push(homeUrl);
    } else {
      setLoaded(true);
    }
  }, []);

  if (!loaded) {
    return null;
  }

  return (
    <>
      <Seo tile="Bmoment - Facial rituals for mother & daughter" />
      <Timer
        isVisibleOnTablet={
          !isModalVisible && !isProductsDescriptionModalVisible
        }
      />
      <CheckoutDescription
        isVisibleOnTablet={
          !isModalVisible && !isProductsDescriptionModalVisible
        }
      />
      <BeautyRitualPlan
        isVisibleOnTablet={
          !isModalVisible && !isProductsDescriptionModalVisible
        }
        selectedBundle={selectedBundle}
        selectedSubscription={selectedSubscription}
        setIsProductsDescriptionModalVisible={
          setIsProductsDescriptionModalVisible
        }
        setVisibleBundle={setVisibleBundle}
        setSelectedBundle={setSelectedBundle}
        setSelectedSubscription={setSelectedSubscription}
        onBuyClick={() => setIsModalVisible(true)}
      />
      <Reviews
        isVisibleOnTablet={
          !isModalVisible && !isProductsDescriptionModalVisible
        }
      />
      <MagicalMoments
        isVisibleOnTablet={
          !isModalVisible && !isProductsDescriptionModalVisible
        }
      />
      <Faq
        isVisibleOnTablet={
          !isModalVisible && !isProductsDescriptionModalVisible
        }
      />
      <BeautyRitualPlan
        isVisibleOnTablet={
          !isModalVisible && !isProductsDescriptionModalVisible
        }
        selectedBundle={selectedBundle}
        selectedSubscription={selectedSubscription}
        setIsProductsDescriptionModalVisible={
          setIsProductsDescriptionModalVisible
        }
        setVisibleBundle={setVisibleBundle}
        setSelectedBundle={setSelectedBundle}
        setSelectedSubscription={setSelectedSubscription}
        onBuyClick={() => setIsModalVisible(true)}
      />
      <PaymentModal
        variant={variant}
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        selectedBundle={selectedBundle}
        selectedSubscription={selectedSubscription}
      />
      <ProductsDescriptionModal
        isVisible={isProductsDescriptionModalVisible}
        onClose={() => setIsProductsDescriptionModalVisible(false)}
      />
      <BundleModal
        visibleBundle={visibleBundle}
        onClose={() => setVisibleBundle(null)}
      />
    </>
  );
};
