import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { icons } from '../../../utils/icons';
import { colors } from '../../../styles/colors';
import { tablet } from '../../../styles/breakpoints';
import {
  BraintreeDropIn,
  DefaultButton,
  ExternalScripts,
  PayPalButton,
  Regular,
} from '../../../components';

export const PaymentMethod = ({
  plan,
  bundle,
  variant,
  values,
  paymentMethod,
  agreedToTNCError,
  setFieldValue,
  setPaymentMethod,
}) => {
  const [scriptsLoaded, setScriptsLoaded] = useState(false);
  const price =
    variant === 3
      ? plan.monthlyPrice
      : plan.totalPriceDiscounted || plan.totalPrice || 0;
  let planTitle = plan.title;

  if (variant === 3) {
    if (plan.id === '6_months') {
      planTitle = 'MONTHLY SUBSCRIPTION';
    } else {
      planTitle = 'ONE TIME';
    }
  }

  return (
    <>
      <ExternalScripts onLoadComplete={() => setScriptsLoaded(true)} />
      <Container>
        <Wrapper>
          <Heading>Submit a secure payment</Heading>
          <PaymentMethodsContainer>
            <PaymentMethodItem
              className={paymentMethod === 'credit_card' ? 'selected' : null}
              onClick={() => setPaymentMethod('credit_card')}
            >
              <img src={icons.checkout_card} alt="Credit card" />
              <CreditCardText>Credit Card</CreditCardText>
            </PaymentMethodItem>
            {/* <PaymentMethodItem
              className={paymentMethod === 'paypal' ? 'selected' : null}
              onClick={() => setPaymentMethod('paypal')}
            >
              <img src={icons.checkout_paypal} alt="Paypal" />
            </PaymentMethodItem> */}
          </PaymentMethodsContainer>
          <InfoContainer>
            <InfoText>
              {bundle.title} ({planTitle}):
            </InfoText>
            <InfoText>
              ${plan[variant === 3 ? 'monthlyPrice' : 'totalPrice'].toFixed(2)}
            </InfoText>
          </InfoContainer>
          {plan.discount && plan.totalPriceDiscounted && variant !== 3 ? (
            <InfoContainer>
              <InfoText>Discount (-{plan.discount || 0}%):</InfoText>
              <InfoText className="red">
                ${plan.totalPriceDiscounted.toFixed(2)}
              </InfoText>
            </InfoContainer>
          ) : null}
          <TotalContainer>
            <TotalText>Total</TotalText>
            <TotalText>${price.toFixed(2)}</TotalText>
          </TotalContainer>
          {paymentMethod === 'credit_card' && scriptsLoaded ? (
            <BraintreeDropIn id="1" preloaded={true} />
          ) : null}
          <CheckboxContainer>
            <input
              id="agreedToTNC"
              type="checkbox"
              checked={values.agreedToTNC}
              onChange={event =>
                setFieldValue('agreedToTNC', event.target.checked)
              }
            />
            <TNCText color={agreedToTNCError ? colors.error : null}>
              I agree to the&nbsp;
              <TNCLink
                color={agreedToTNCError ? colors.error : null}
                target="_blank"
                to="/general-conditions"
              >
                T&Cs
              </TNCLink>
              &nbsp;and&nbsp;
              <TNCLink
                color={agreedToTNCError ? colors.error : null}
                target="_blank"
                to="/privacy-policy"
              >
                Privacy policy
              </TNCLink>
            </TNCText>
          </CheckboxContainer>
          {paymentMethod === 'credit_card' ? (
            <DefaultButton label="SUBMIT SECURE PAYMENT" type="submit" />
          ) : null}
          {paymentMethod === 'paypal' && scriptsLoaded ? (
            <PayPalButton id="1" disabled={!values.agreedToTNC} />
          ) : null}
        </Wrapper>
      </Container>
    </>
  );
};

PaymentMethod.propTypes = {
  agreedToTNCError: PropTypes.string,
  bundle: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
  paymentMethod: PropTypes.oneOf(['credit_card', 'paypal']).isRequired,
  plan: PropTypes.shape({
    discount: PropTypes.number,
    id: PropTypes.string,
    monthlyPrice: PropTypes.number,
    title: PropTypes.string,
    totalPrice: PropTypes.number,
    totalPriceDiscounted: PropTypes.number,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setPaymentMethod: PropTypes.func.isRequired,
  values: PropTypes.shape({
    agreedToTNC: PropTypes.bool,
    cardNumber: PropTypes.string,
    cvv: PropTypes.string,
    expires: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  variant: PropTypes.oneOf([1, 2, 3]).isRequired,
};

PaymentMethod.defaultProps = {
  agreedToTNCError: null,
};

const Container = styled.div`
  max-width: 430px;
  width: 100%;
  padding: 55px 0;
  background-color: ${colors.white};

  @media ${tablet} {
    max-width: unset;
    padding-top: 36px;
    padding-bottom: 34px;
  }
`;
const Wrapper = styled.div`
  max-width: 327px;
  width: 100%;
  margin: 0 auto;
`;
const Heading = styled(Regular)`
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  color: ${colors.black};
`;
const PaymentMethodsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 21px;
  margin-bottom: 23px;
`;
const PaymentMethodItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 156px;
  height: 54px;
  border-radius: 10px;
  background-color: ${colors.almostWhite};
  cursor: pointer;

  &.selected {
    border: 2px solid #06ab80;
  }
`;
const CreditCardText = styled(Regular)`
  font-size: 16px;
  font-weight: 600;
  color: #06ab80;
  margin-left: 5px;
`;
const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
`;
const InfoText = styled(Regular)`
  font-size: 16px;
  color: ${colors.blackAlpha(0.8)};

  &.red {
    color: rgba(199, 23, 23, 0.8);
  }
`;
const TotalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  padding-top: 18px;
  border-top: 1px solid #e0e0e0;
`;
const TotalText = styled(Regular)`
  font-size: 20px;
  font-weight: 600;
  color: ${colors.black};
`;
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
`;
const TNCText = styled(Regular)`
  padding-left: 5px;
  font-size: 15px;
  color: ${props => props.color || colors.blackAlpha(0.8)};
`;
const TNCLink = styled(Link)`
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
  color: ${props => props.color || '#06ab80'};
`;
