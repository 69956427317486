import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { icons } from '../utils/icons';
import { colors } from '../styles/colors';
import { mobile } from '../styles/breakpoints';
import { H4, Regular, Seo } from '../components';

export const DaughtersQuizIntro = () => {
  const { push } = useHistory();
  const [loaded, setLoaded] = useState(false);
  const { questions, daughtersEditionQuestions } = useSelector(
    ({ funnel }) => funnel,
  );

  useEffect(() => {
    if (!questions?.length || !daughtersEditionQuestions?.length) {
      push('/');
    } else {
      setLoaded(true);
    }
  }, []);

  if (!loaded) {
    return null;
  }

  return (
    <>
      <Seo tile="Bmoment - Facial rituals for mother & daughter" />
      <Container>
        <Wrapper>
          <Logo src={icons.logo} alt="Logo" onClick={() => push('/')} />
          <ImageWrapper>
            <DaughterEditionImg
              src={icons.daughter_edition}
              alt="Daughter edition"
            />
            <DaughtersEditionWrapper>
              <DaughtersEditionText>
                Daughter&apos;s edition
              </DaughtersEditionText>
            </DaughtersEditionWrapper>
          </ImageWrapper>
          <HeadingWrapper>
            <Leaves1 src={icons.daughter_quiz_intro_leaves1} alt="Leaves" />
            <Heading>
              That’s great! Now let’s look at what’s best for your daughter
            </Heading>
          </HeadingWrapper>
          <Leaves2 src={icons.daughter_quiz_intro_leaves2} alt="Leaves" />
          <LinkButton id="continueQuizBtn" to="/daughters-quiz/0">
            Let&apos;s go
          </LinkButton>
        </Wrapper>
      </Container>
    </>
  );
};

const Container = styled.div`
  padding: 56px 0;
  min-height: 100vh;
  background-color: ${colors.background};

  @media ${mobile} {
    padding: 20px 0;
    background-color: ${colors.white};
  }
`;
const Wrapper = styled.div`
  position: relative;
  max-width: 700px;
  padding: 40px 4% 66px;
  margin: 0 auto;
  text-align: center;
  background: ${colors.white};
  border-radius: 40px;

  @media ${mobile} {
    padding: 0;
  }
`;
const ImageWrapper = styled.div`
  overflow: hidden;
`;
const DaughterEditionImg = styled.img`
  width: 100%;
  margin: 40px auto 30px;

  @media ${mobile} {
    width: 150%;
    margin-left: 50%;
    transform: translateX(-50%);
  }
`;
const DaughtersEditionWrapper = styled.div`
  max-width: 169px;
  width: 100%;
  padding: 7px 0;
  margin: -35px auto 0;
  border-radius: 50px;
  transform: translateY(-50%);
  background-color: #fbe3e4;

  @media ${mobile} {
    margin: 7px auto 13px;
    transform: unset;
  }
`;
const DaughtersEditionText = styled(Regular)`
  font-size: 15px;
  font-weight: bold;
  color: ${colors.error};
`;
const LinkButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 327px;
  height: 54px;
  margin: 35px auto 0;
  position: relative;
  z-index: 1;
  color: ${colors.white};
  border-radius: 30px;
  font-size: 17px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  background: ${colors.gradient};

  @media ${mobile} {
    margin: 43px auto 0;
  }
`;
const Logo = styled.img`
  display: block;
  margin: 0 auto;
  cursor: pointer;
`;
const HeadingWrapper = styled.div`
  position: relative;
  display: inline-block;
`;
const Heading = styled(H4)`
  display: inline-block;
  max-width: 383px;
  width: 100%;
  margin: 0 auto;
  text-align: center;

  @media ${mobile} {
    max-width: 277px;
  }
`;
const Leaves1 = styled.img`
  position: absolute;
  left: -40px;
  bottom: 45px;

  @media ${mobile} {
    bottom: 80px;
  }
`;
const Leaves2 = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;

  @media ${mobile} {
    width: 83px;
    bottom: 35px;
    right: 15px;
  }
`;
