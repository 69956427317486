import logo from '../assets/images/nav/logo.svg';
import nav_back from '../assets/images/nav/nav_back.svg';
import close from '../assets/images/nav/close.svg';
import menu from '../assets/images/nav/menu.svg';
import back from '../assets/images/nav/back.svg';
import nav_leaves_1 from '../assets/images/nav/leaves_1.svg';
import nav_leaves_2 from '../assets/images/nav/leaves_2.svg';
import nav_leaves_3 from '../assets/images/nav/leaves_3.svg';
import nav_leaves_4 from '../assets/images/nav/leaves_4.svg';
import summary_avocado from '../assets/images/summary/avocado.svg';
import summary_acai_berry from '../assets/images/summary/acai_berry.svg';
import summary_family from '../assets/images/summary/family.svg';
import summary_massage from '../assets/images/summary/massage.svg';
import summary_nerd_face from '../assets/images/summary/nerd_face.svg';
import summary_sad_face from '../assets/images/summary/sad_face.svg';
import summary_leaves_1 from '../assets/images/summary/leaves_1.svg';
import summary_leaves_2 from '../assets/images/summary/leaves_2.svg';
import summary_leaves_3 from '../assets/images/summary/leaves_3.svg';
import summary_leaves_4 from '../assets/images/summary/leaves_4.svg';
import home_angel from '../assets/images/home/angel.svg';
import home_branch from '../assets/images/home/branch.svg';
import home_brush from '../assets/images/home/brush.svg';
import home_envelope from '../assets/images/home/envelope.svg';
import home_face_mask from '../assets/images/home/face_mask.png';
import home_leaves_1 from '../assets/images/home/leaves_1.svg';
import home_leaves_2 from '../assets/images/home/leaves_2.svg';
import home_leaves_3 from '../assets/images/home/leaves_3.svg';
import home_leaves_4 from '../assets/images/home/leaves_4.svg';
import home_leaves_5 from '../assets/images/home/leaves_5.svg';
import home_leaves_6 from '../assets/images/home/leaves_6.svg';
import home_leaves_7 from '../assets/images/home/leaves_7.svg';
import home_leaves_8 from '../assets/images/home/leaves_8.svg';
import home_leaves_9 from '../assets/images/home/leaves_9.svg';
import home_massage from '../assets/images/home/massage.svg';
import home_moisturising_face_mask from '../assets/images/home/moisturising_face_mask.png';
import home_mom_daughter_1 from '../assets/images/home/mom_daughter_1.png';
import home_mom_daughter_2 from '../assets/images/home/mom_daughter_2.png';
import home_people_1 from '../assets/images/home/people_1.png';
import home_people_2 from '../assets/images/home/people_2.png';
import home_people_3 from '../assets/images/home/people_3.png';
import home_people_4 from '../assets/images/home/people_4.png';
import home_rectangle_1 from '../assets/images/home/rectangle_1.svg';
import home_rectangle_2 from '../assets/images/home/rectangle_2.svg';
import home_ribbon from '../assets/images/home/ribbon.svg';
import home_sponge from '../assets/images/home/sponge.svg';
import home_superfruits from '../assets/images/home/superfruits.png';
import home_unicorn from '../assets/images/home/unicorn.svg';
import home_mom_daughter_face_mask from '../assets/images/home/mom_daughter_face_mask.png';
import home_arrow_left from '../assets/images/home/arrow_left.svg';
import home_arrow_right from '../assets/images/home/arrow_right.svg';
import home_first_ritual from '../assets/images/home/first_ritual.png';
import option_arrow from '../assets/images/quiz/option_arrow.svg';
import quiz_leaves_1 from '../assets/images/quiz/leaves_1.svg';
import quiz_leaves_2 from '../assets/images/quiz/leaves_2.svg';
import quiz_leaves_3 from '../assets/images/quiz/leaves_3.svg';
import quiz_leaves_4 from '../assets/images/quiz/leaves_4.svg';
import quiz_leaves_5 from '../assets/images/quiz/leaves_5.svg';
import quiz_girl_face from '../assets/images/quiz/girl_face.svg';
import quiz_mom_daughter_faces from '../assets/images/quiz/mom_daughter_faces.svg';
import quiz_two_women_faces from '../assets/images/quiz/two_women_faces.svg';
import quiz_unicorn from '../assets/images/quiz/unicorn.svg';
import quiz_stars from '../assets/images/quiz/stars.svg';
import daughter_edition from '../assets/images/daughter_quiz_intro/daughter_edition.png';
import daughter_quiz_intro_leaves1 from '../assets/images/daughter_quiz_intro/leaves_1.svg';
import daughter_quiz_intro_leaves2 from '../assets/images/daughter_quiz_intro/leaves_2.svg';
import quiz_intro_leaves_1 from '../assets/images/quiz_intro/leaves_1.svg';
import quiz_intro_leaves_2 from '../assets/images/quiz_intro/leaves_2.svg';
import quiz_intro_mom_daughter from '../assets/images/quiz_intro/mom_daughter.png';
import calculating_leaf from '../assets/images/calculating/leaf.svg';
import calculating_leaves_1 from '../assets/images/calculating/leaves_1.svg';
import calculating_leaves_2 from '../assets/images/calculating/leaves_2.svg';
import calculating_leaves_3 from '../assets/images/calculating/leaves_3.svg';
import email_leaves_1 from '../assets/images/email/leaves_1.svg';
import email_leaves_2 from '../assets/images/email/leaves_2.svg';
import success_sad_face from '../assets/images/success/sad_face.png';
import success_happy_face from '../assets/images/success/happy_face.svg';
import success_leaves_1 from '../assets/images/success/leaves_1.svg';
import success_leaves_2 from '../assets/images/success/leaves_2.svg';
import success_leaves_3 from '../assets/images/success/leaves_3.svg';
import checkout_leaves_1 from '../assets/images/checkout/leaves_1.svg';
import checkout_leaves_2 from '../assets/images/checkout/leaves_2.svg';
import checkout_leaves_3 from '../assets/images/checkout/leaves_3.svg';
import checkout_leaves_4 from '../assets/images/checkout/leaves_4.svg';
import checkout_checkmark from '../assets/images/checkout/checkmark.svg';
import checkout_avocado_maxi_plan from '../assets/images/checkout/avocado_maxi_plan.jpg';
import checkout_avocado_regular_plan from '../assets/images/checkout/avocado_regular_plan.jpg';
import checkout_acai_berry_maxi_plan from '../assets/images/checkout/acai_berry_maxi_plan.jpg';
import checkout_acai_berry_regular_plan from '../assets/images/checkout/acai_berry_regular_plan.jpg';
import checkout_chevron_up from '../assets/images/checkout/chevron_up.svg';
import checkout_chevron_down from '../assets/images/checkout/chevron_down.svg';
import checkout_chevron_right from '../assets/images/checkout/chevron_right.svg';
import checkout_gift from '../assets/images/checkout/gift.svg';
import checkout_payments from '../assets/images/checkout/payments.png';
import checkout_stars from '../assets/images/checkout/stars.svg';
import checkout_user_1 from '../assets/images/checkout/user_1.png';
import checkout_user_2 from '../assets/images/checkout/user_3.png';
import checkout_user_3 from '../assets/images/checkout/user_2.png';
import checkout_plus from '../assets/images/checkout/plus.svg';
import checkout_minus from '../assets/images/checkout/minus.svg';
import checkout_card from '../assets/images/checkout/card.svg';
import checkout_paypal from '../assets/images/checkout/paypal.svg';
import checkout_paypal_checkout from '../assets/images/checkout/paypal_checkout.svg';
import checkout_lock from '../assets/images/checkout/lock.svg';
import checkout_replay from '../assets/images/checkout/replay.svg';
import checkout_avocado_big_mask from '../assets/images/checkout/avocado_big_mask.png';
import checkout_avocado_brushes from '../assets/images/checkout/avocado_brushes.png';
import checkout_avocado_cream from '../assets/images/checkout/avocado_cream.png';
import checkout_avocado_mic_water from '../assets/images/checkout/avocado_mic_water.png';
import checkout_avocado_scrub from '../assets/images/checkout/avocado_scrub.png';
import checkout_avocado_serum from '../assets/images/checkout/avocado_serum.png';
import checkout_avocado_small_mask from '../assets/images/checkout/avocado_small_mask.png';
import checkout_avocado_sponge from '../assets/images/checkout/avocado_sponge.png';
import checkout_avocado_tonic from '../assets/images/checkout/avocado_tonic.png';
import checkout_acai_berry_big_mask from '../assets/images/checkout/acai_berry_big_mask.png';
import checkout_acai_berry_brushes from '../assets/images/checkout/acai_berry_brushes.png';
import checkout_acai_berry_cream from '../assets/images/checkout/acai_berry_cream.png';
import checkout_acai_berry_mic_water from '../assets/images/checkout/acai_berry_mic_water.png';
import checkout_acai_berry_scrub from '../assets/images/checkout/acai_berry_scrub.png';
import checkout_acai_berry_serum from '../assets/images/checkout/acai_berry_serum.png';
import checkout_acai_berry_small_mask from '../assets/images/checkout/acai_berry_small_mask.png';
import checkout_acai_berry_sponge from '../assets/images/checkout/acai_berry_sponge.png';
import checkout_acai_berry_tonic from '../assets/images/checkout/acai_berry_tonic.png';

import footer_envelope from '../assets/images/footer/envelope.svg';

export const icons = {
  logo,
  nav_back,
  close,
  menu,
  nav_leaves_1,
  nav_leaves_2,
  nav_leaves_3,
  nav_leaves_4,
  home_angel,
  home_branch,
  home_brush,
  home_envelope,
  home_face_mask,
  home_leaves_1,
  home_leaves_2,
  home_leaves_3,
  home_leaves_4,
  home_leaves_5,
  home_leaves_6,
  home_leaves_7,
  home_leaves_8,
  home_leaves_9,
  home_massage,
  home_moisturising_face_mask,
  home_mom_daughter_1,
  home_mom_daughter_2,
  home_people_1,
  home_people_2,
  home_people_3,
  home_people_4,
  home_rectangle_1,
  home_rectangle_2,
  home_ribbon,
  home_sponge,
  home_superfruits,
  home_unicorn,
  home_mom_daughter_face_mask,
  home_arrow_left,
  home_arrow_right,
  home_first_ritual,
  back,
  option_arrow,
  quiz_leaves_1,
  quiz_leaves_2,
  quiz_leaves_3,
  quiz_leaves_4,
  quiz_leaves_5,
  quiz_girl_face,
  quiz_mom_daughter_faces,
  quiz_two_women_faces,
  quiz_unicorn,
  quiz_stars,
  quiz_intro_leaves_1,
  quiz_intro_leaves_2,
  quiz_intro_mom_daughter,
  daughter_edition,
  daughter_quiz_intro_leaves1,
  daughter_quiz_intro_leaves2,
  calculating_leaf,
  calculating_leaves_1,
  calculating_leaves_2,
  calculating_leaves_3,
  email_leaves_1,
  email_leaves_2,
  success_sad_face,
  success_happy_face,
  success_leaves_1,
  success_leaves_2,
  success_leaves_3,
  summary_avocado,
  summary_acai_berry,
  summary_family,
  summary_massage,
  summary_nerd_face,
  summary_sad_face,
  summary_leaves_1,
  summary_leaves_2,
  summary_leaves_3,
  summary_leaves_4,
  checkout_checkmark,
  checkout_leaves_1,
  checkout_leaves_2,
  checkout_leaves_3,
  checkout_leaves_4,
  checkout_avocado_maxi_plan,
  checkout_avocado_regular_plan,
  checkout_acai_berry_maxi_plan,
  checkout_acai_berry_regular_plan,
  checkout_chevron_up,
  checkout_chevron_down,
  checkout_chevron_right,
  checkout_gift,
  checkout_payments,
  checkout_stars,
  checkout_user_1,
  checkout_user_2,
  checkout_user_3,
  checkout_plus,
  checkout_minus,
  checkout_card,
  checkout_paypal,
  checkout_paypal_checkout,
  checkout_lock,
  checkout_replay,
  checkout_avocado_big_mask,
  checkout_avocado_brushes,
  checkout_avocado_cream,
  checkout_avocado_mic_water,
  checkout_avocado_scrub,
  checkout_avocado_serum,
  checkout_avocado_small_mask,
  checkout_avocado_sponge,
  checkout_avocado_tonic,
  checkout_acai_berry_big_mask,
  checkout_acai_berry_brushes,
  checkout_acai_berry_cream,
  checkout_acai_berry_mic_water,
  checkout_acai_berry_scrub,
  checkout_acai_berry_serum,
  checkout_acai_berry_small_mask,
  checkout_acai_berry_sponge,
  checkout_acai_berry_tonic,
  footer_envelope,
};
