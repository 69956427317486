import styled, { keyframes } from 'styled-components';

import { mobile } from '../../styles/breakpoints';
import { colors } from '../../styles/colors';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const H3 = styled.h3`
  padding: ${props => props.padding || '0px'};
  margin: ${props => props.margin || '0px'};
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  color: ${props => props.color || colors.black};
  text-align: left;

  &.fadeIn {
    opacity: 0;
    animation: ${fadeIn} 0.2s ease-in forwards;
  }

  @media ${mobile} {
    font-size: 32px;
  }
`;
