import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Regular } from '../text/Regular';
import { colors } from '../../styles/colors';
import { mobile } from '../../styles/breakpoints';

export const Input = ({
  id,
  placeholder,
  type,
  error,
  name,
  label,
  autoComplete,
  borderColor,
  backgroundColor,
  mobileBackgroundColor,
  className,
  min,
  max,
  value,
  required,
  onChange,
  pattern,
  maxLength,
}) => (
  <>
    {label ? <Label textAlign="left">{label}</Label> : null}
    <InputComp
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      mobileBackgroundColor={mobileBackgroundColor}
      className={`${error ? 'error' : ''} ${className || ''}`}
    >
      <Flex>
        <Field
          id={id}
          value={value}
          autoComplete={autoComplete}
          backgroundColor={backgroundColor}
          mobileBackgroundColor={mobileBackgroundColor}
          onChange={onChange}
          onInvalid={
            type === 'email'
              ? e => e.target.setCustomValidity('Enter an email address')
              : () => {}
          }
          onInput={
            type === 'email' ? e => e.target.setCustomValidity('') : () => {}
          }
          onFocus={() => {
            if (/Android/.test(navigator?.appVersion)) {
              if (document.activeElement.tagName === 'INPUT') {
                if (document.activeElement?.scrollIntoView) {
                  document.activeElement.scrollIntoView();
                }
              }
            }
          }}
          placeholder={placeholder}
          type={type}
          name={name}
          required={required}
          min={min}
          max={max}
          maxLength={maxLength}
          pattern={
            type === 'email'
              ? '[a-zA-Z0-9.+-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}'
              : pattern
          }
        />
      </Flex>
    </InputComp>
  </>
);

Input.propTypes = {
  autoComplete: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  mobileBackgroundColor: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Input.defaultProps = {
  autoComplete: 'off',
  backgroundColor: null,
  borderColor: null,
  error: null,
  min: 0,
  mobileBackgroundColor: null,
  placeholder: '',
  required: true,
  type: 'text',
};

const InputComp = styled.div`
  padding: 14px 18px;
  margin: 0 auto;
  font-size: 18px;
  border-radius: 6px;
  border: 1px solid ${props => props.borderColor || 'transparent'};
  background-color: ${props => props.backgroundColor || colors.background};

  &.error {
    border-color: ${colors.error};
  }

  &.inline__left {
    flex: 1;
    margin-right: 0.5rem;
  }

  &.inline__right {
    flex: 1;
    margin-left: 0.5rem;
  }

  @media ${mobile} {
    background-color: ${props =>
      props.mobileBackgroundColor || colors.background};
  }
`;
const Flex = styled.div`
  display: flex;
`;
const Field = styled.input`
  flex: 1;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: inherit;
  color: ${colors.black};
  border: none;
  outline: none;
  background-color: ${props => props.backgroundColor || colors.background};

  @media ${mobile} {
    background-color: ${props =>
      props.mobileBackgroundColor || colors.background};
  }
`;
const Label = styled(Regular)`
  padding-bottom: 16px;
  text-align: left;
  font-size: 22px;
  font-weight: 600;
  color: ${colors.black};

  @media ${mobile} {
    font-size: 18px;
  }
`;
