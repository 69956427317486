import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Regular } from '..';
import { colors } from '../../styles/colors';
import { mobile, smMobile, smTablet } from '../../styles/breakpoints';
import { monthNames } from '../../utils/datetime';
import { icons } from '../../utils/icons';

export const GraphicItem = ({ isMetric, weight, desiredWeight, firstWeek }) => {
  const difference = weight - desiredWeight;
  const date = new Date();
  date.setDate(date.getDate() + Math.ceil(difference / firstWeek) * 7);

  return (
    <Wrapper>
      <picture>
        <source media="(max-width: 480px)" srcSet={icons.graphMobile} />
        <Img src={icons.graph} alt="Flowers" />
      </picture>
      <StartPoint>{weight}</StartPoint>
      <EndPoint>{desiredWeight}</EndPoint>
      <Estimation>
        <H2 color={colors.primary}>{`-${difference} ${
          isMetric ? 'kg' : 'lb'
        }`}</H2>
        <Regular textAlign="center">{`By ${
          monthNames[date.getMonth()]
        } ${new Date().getFullYear()}`}</Regular>
      </Estimation>
    </Wrapper>
  );
};

GraphicItem.propTypes = {
  desiredWeight: PropTypes.number,
  firstWeek: PropTypes.number,
  isMetric: PropTypes.bool,
  weight: PropTypes.number,
};

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 0.5rem;
`;

const Img = styled.img`
  width: 100%;
`;

const StartPoint = styled.div`
  position: absolute;
  width: 54px;
  height: 54px;
  top: 21%;
  left: 16.1%;
  font-size: 18px;
  font-weight: 700;
  color: ${colors.black};
  line-height: 54px;
  text-align: center;
  background: ${colors.white};
  border: 3px solid ${colors.primary};
  border-radius: 250px;

  @media ${smTablet} {
    top: 19%;
  }

  @media ${mobile} {
    left: 11%;
  }
`;

const EndPoint = styled.div`
  position: absolute;
  width: 54px;
  height: 54px;
  top: 62%;
  left: 70%;
  font-size: 18px;
  font-weight: 700;
  color: ${colors.black};
  line-height: 54px;
  text-align: center;
  background: ${colors.white};
  border: 3px solid ${colors.primary};
  border-radius: 250px;

  @media ${smTablet} {
    top: 60%;
  }

  @media ${mobile} {
    left: 75%;
    top: 62%;
  }

  @media ${smMobile} {
    top: 60%;
  }
`;

const Estimation = styled.div`
  position: absolute;
  top: 1rem;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
`;

const H2 = styled.h2`
  margin: 0;
  font-size: 40px;
  font-weight: 700;
  color: ${colors.primary};
`;
