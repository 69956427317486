import React from 'react';
import PropTypes from 'prop-types';
import keys from 'lodash/fp/keys';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { colors } from '../../styles/colors';
import { mobile } from '../../styles/breakpoints';
import { UserDataSchema } from '../../utils/validations';
import { DefaultButton, fadeIn, Input } from '..';

export const UserData = ({ onSelect }) => {
  const { name, age } = useSelector(({ funnel }) => funnel.userInfo);
  const formik = useFormik({
    initialValues: {
      name: name || '',
      age: age || '',
    },
    onSubmit: onSelect,
    validateOnMount: true,
    validationSchema: UserDataSchema,
  });

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <FormInput
          name="name"
          type="text"
          id="inputName"
          placeholder="Name"
          label="Your name:"
          value={formik.values.name}
          onChange={formik.handleChange}
        />
        <FormInput
          min={18}
          max={120}
          name="age"
          type="number"
          id="inputAge"
          label="Your age:"
          placeholder="Years"
          value={formik.values.age}
          onChange={formik.handleChange}
        />
        <ButtonWrapper>
          <DefaultButton
            label="Next"
            type="submit"
            id="submitBtn"
            disabled={!!keys(formik.errors).length}
          />
        </ButtonWrapper>
      </Form>
    </>
  );
};

UserData.propTypes = {
  onSelect: PropTypes.func,
};

const Form = styled.form`
  opacity: 0;
  animation: ${fadeIn} 0.2s ease-in forwards;
  animation-delay: 0.1s;

  @media ${mobile} {
    max-width: none;
  }
`;
const FormInput = styled(Input)`
  margin-bottom: 42px;
`;
const ButtonWrapper = styled.div`
  @media ${mobile} {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px 24px;
    border-top: 1px solid #ebebeb;
    background-color: ${colors.white};
  }
`;
