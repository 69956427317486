const pathsWhereToShowBackArrow = {
  quiz: true,
};

export const shouldShowBackArrow = ({ pathname }) => {
  const path = pathname.replace('/', '');

  return pathsWhereToShowBackArrow[path];
};

export const isRoute = route => ({ pathname }) => {
  const regExp = new RegExp(route);
  return regExp.test(pathname);
};
