import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { icons } from '../../utils/icons';
import { fadeIn, Regular } from '../../components';
import { colors } from '../../styles/colors';
import { smTablet, tablet } from '../../styles/breakpoints';

export const ProductsDescriptionModal = ({ isVisible, onClose }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Backdrop onClick={onClose}>
      <Wrapper>
        <CloseIcon src={icons.close} alt="Close" onClick={onClose} />
        <Content>
          <Headline>Regular kit</Headline>
          <Subheadline>Nourishing Face Mask with Avocado Oil</Subheadline>
          <Description>
            <span className="bold">Description:</span> This unique formula has
            been created to nourish the skin and give it a healthy glow. Natural
            avocado oil is known for its nourishing and anti-inflammatory
            properties, and helps the skin to become smoother and stronger. It
            contains Vitamins D, A, and E that protect from damaging everyday
            environmental factors such as UV rays and pollution.
          </Description>
          <Description>
            Our products contain NO genetically modified ingredients, parabens,
            or phthalates.
          </Description>
          <Description>
            <span className="bold">How to use:</span> Apply the mask with the
            brush provided. Leave on the skin for 10 minutes. Remove with the
            sponge and pat the face dry. For best results, cleanse and exfoliate
            the skin before applying a facial mask.
          </Description>
          <SmallText>
            <span className="bold">Ingredients:</span> Aqua (Water), Aloe
            Barbadensis Leaf Juice (Organic Aloe), Glycerin (Kosher, Vegetable),
            Persea Gratissima (Avocado) Oil, Cassia Angustifolia Seed Extract
            (botanical hyaluronic acid), Rosa Canina Fruit Oil (Organic Rosehip
            Seed Oil), Simmondsia Chinensis Seed Oil (Organic Jojoba), Panthenol
            (Vitamin B5), Sodium Pca, Sodium Lactate, Arginine, Aspartic Acid,
            Glycine, Alanine, Serine, Valine, Isoleucine, Proline, Threonine,
            Histidine, Phenylalanine, Centella Asiatica Extract (Organic Gotu
            Kola), Equisetum Arvense Extract (Horsetail), Geranium Maculatum
            Extract (Wild Geranium), Taraxacum Officinale Extract (Dandelion),
            Carrageenan, Acrylates Copolymer, Hydroxyethyl Ethylcellulose,
            Potassium Sorbate, Sodium Benzoate, Ethylhexylglycerin.
          </SmallText>

          <Subheadline>Hydrating Face Mask with Acai Berry Extract</Subheadline>
          <Description>
            <span className="bold">Description:</span> This face mask contains
            organic acai berry oil, an incredibly effective moisturizer that
            helps boost the skin’s natural hydration. It is rich in Omega 6 and
            Omega 9, and Vitamins B2, B3, C, and E, which regenerate and soothe
            the skin. Feel the nourishing power of acai berry!
          </Description>
          <Description>
            Our products contain NO genetically modified ingredients, parabens,
            or phthalates.
          </Description>
          <Description>
            <span className="bold">How to use:</span> Apply the mask with the
            brush provided. Leave on the skin for 10 minutes. Remove with the
            sponge and pat the face dry. For best results, cleanse and exfoliate
            the skin before applying a facial mask.
          </Description>
          <SmallText>
            <span className="bold">Ingredients:</span> Aqua (Water), Aloe
            Barbadensis Leaf Juice (Organic Aloe), Glycerin (Kosher, Vegetable),
            Euterpe Oleracea (Acai) fruit extract, sodium benzoate, potassium
            sorbate, Cassia Angustifolia Seed Extract (botanical hyaluronic
            acid), ]Simmondsia Chinensis Seed Oil (Organic Jojoba), Sodium Pca,
            Sodium Lactate, Arginine, Aspartic Acid, Glycine, Alanine, Serine,
            Valine, Isoleucine, Proline, Threonine, Histidine, Phenylalanine,
            Centella Asiatica Extract (Organic Gotu Kola), Equisetum Arvense
            Extract (Horsetail), Geranium Maculatum Extract (Wild Geranium),
            Taraxacum Officinale Extract (Dandelion), Carrageenan, Acrylates
            Copolymer, Hydroxyethyl Ethylcellulose, Potassium Sorbate, Sodium
            Benzoate, Ethylhexylglycerin.
          </SmallText>

          <Subheadline>Brush</Subheadline>
          <Description>
            <span className="bold">Description:</span> Skin-soft brush made from
            natural materials. Specially created to apply our face masks. Rinse
            with water after using.
          </Description>

          <Subheadline>Face Sponge</Subheadline>
          <Description>
            <span className="bold">Description:</span> Designed to gently remove
            face masks from the skin. Rinse with water after using.
          </Description>

          <Headline>Maxi kit</Headline>
          <Subheadline>Micellar water</Subheadline>
          <Description>
            <span className="bold">Description:</span> Our micellar water
            cleanser is an ultra-gentle way to remove excess oil and dirt
            without the need to rinse. Hydrating Micellar Water with ceramides
            and niacinamide helps calm the skin without disrupting its
            protective barrier. This new formula is also suitable for removing
            mascara or other makeup.
          </Description>
          <Description>
            Developed with the help of top dermatologists, our Hydrating
            Micellar Water is a 2-in-1 product that effectively cleanses and
            hydrates without disrupting the skin’s protective barrier. Featuring
            three essential ceramides and soothing niacinamide in an
            easy-to-use, no-rinse formula, this gentle micellar water is also
            non-comedogenic and paraben-free.
          </Description>
          <Description>
            <span className="bold">How to use:</span> Gently wipe the pad over
            the skin until completely free of makeup and impurities. Shake well
            before using. Can be used in the morning and in the evening.
          </Description>
          <SmallText>
            <span className="bold">Ingredients:</span> Aqua/Water/Eau, Glycerin,
            Hexylene Glycol, BHT, Ceramide Np, Ceramide Ap, Ceramide Eop,
            Carbomer, Niacinamide, Sodium Lauroyl Lactylate, Cholesterol,
            Phenoxyethanol, Poloxamer 184, Disodium Cocoamphodiacetate, Disodium
            Edta, Phytosphingosine, Xanthan Gum, Polyaminopropyl Biguanide,
            Ethylhexylglycerin
          </SmallText>

          <Subheadline>Face scrub</Subheadline>
          <Description>
            <span className="bold">Description:</span> This face scrub gently
            exfoliates and purifies the skin. With ultra-fine particles, it
            helps to carefully remove excess dead cells while respecting
            sensitive skin. Experience more supple, nourished skin on both face
            and lips. See effective results immediately as skin looks more
            refined, and feels softer and smoother. Dirt, oil, and impurities
            are effectively removed while dull skin is gently polished away.
          </Description>
          <Description>
            <span className="bold">How to use:</span> Using dry fingers, smooth
            a small amount on clean, dry skin, taking care to avoid the eye
            area. Next, massage over the face with wet fingers, and then rinse
            with warm water.
          </Description>
          <SmallText>
            <span className="bold">Ingredients:</span> 782785/56, Isopropyl
            Palmitate, Sucrose, Synthetic Wax, Glyceryl Stearate,
            Trihydroxystearin, Glycine Soja Oil/Soybean Oil, Peg-7 Glyceryl
            Cocoate, Perlite, Prunus Armeniaca Kernel Oil/Apricot Kernel Oil,
            Cera Alba/Beeswax, Cera Microcristallina/Microcrystalline Wax,
            Rosmarinus Officinalis (rosemary) Extract, Theobroma Grandiflorum
            Seed Powder, Saccharide Hydrolysate, Glycerin, Helianthus Annuus
            Seed Oil/Sunflower Seed Oil, Silica, Cocos Nucifera Oil/Coconut Oil,
            Theobroma Cacao Seed Butter/Cocoa Seed Butter, Caprylyl Glycol,
            Tocopherol, Pentaerythrityl Tetra-di-t-Butyl Hydroxyhydrocinnamate,
            Phenoxyethanol, ci 77491, ci 77492, ci 77499/Iron Oxides, Caramel,
            Linalool, Coumarin, Limonene, Parfum/Fragrance. F.i.l. # b211897/1
          </SmallText>

          <Subheadline>Face Tonic</Subheadline>
          <Description>
            <span className="bold">Description:</span> Premium water-based
            facial toner developed to cleanse, tone, moisturize, and balance the
            pH level of the skin. Formulated with Witch Hazel Aloe Vera formula
            and pore cleansing rosewater, our tonic is both certified organic
            and non-distilled. Our Tonic comes with no added parabens,
            phthalates, sulfates, or artificial fragrances or dyes, and is 100%
            cruelty-free.
          </Description>
          <Description>
            <span className="bold">How to use:</span> Apply to the skin using a
            cotton ball or pad, avoiding the eye area. Wipe in sweeping, outward
            motions until the cotton comes up clean. Follow with a moisturizer.
          </Description>
          <SmallText>
            <span className="bold">Ingredients:</span> Purified Water, Certified
            Organic Witch Hazel Ext Blend (Hamamelis Virginiana Extract (Witch
            Hazel*), Aloe Barbadensis Leaf Juice (Filet Of Aloe Vera*)),
            Glycerin, Phenoxyethanol, Rosa Centifolia (Rose) Flower Water,
            Fragrance (Natural Rose), Citric Acid, Citrus Grandis (Grapefruit)
            Seed Extract
          </SmallText>

          <Subheadline>Facial serum</Subheadline>
          <Description>
            <span className="bold">Description:</span> This intensive hydrating
            fluid revitalizes, hydrates, smoothes, and minimizes the feeling of
            tightness. It contains a concentrated blend of Hyaluronic Acid,
            Panthenol, Glycolipids, and Algae Extract to restore your moisture
            balance. NO artificial fragrance or colors are used in our serum.
          </Description>
          <Description>
            <span className="bold">How to use:</span> Squeeze a small amount
            onto your fingertips, then massage gently over the cleansed skin on
            the face and neck.
          </Description>
          <SmallText>
            <span className="bold">Ingredients:</span> Water, Melissa
            Officinalis Leaf Extract, Sodium Hyaluronate, Hydrolyzed
            Glycosaminoglycans, Glycerin, Glyceryl Polymethacrylate, Panthenol,
            Lavandula Angustifolia (Lavender) Oil, Glycolipids, Hydrolyzed
            Algin, Chlorella Vulgaris Extract, Sea Water, PPG-5-Ceteth-20,
            Phenoxyethanol, Methylparaben.
          </SmallText>

          <Subheadline>Moisturizing cream</Subheadline>
          <Description>
            <span className="bold">Description:</span> Our cream acts both by
            effectively hydrating the skin and restoring its protective barrier.
            Developed with the help of top dermatologists, it is ideal for faces
            with dry or very dry skin. Our award-winning, best-selling water gel
            formula is quickly absorbed and instantly delivers intense
            hydration. Also suitable for sensitive skin.
          </Description>
          <Description>
            <span className="bold">How to use:</span> Apply evenly to the face
            and neck after cleansing.
          </Description>
          <SmallText>
            <span className="bold">Ingredients:</span> Water, Dimethicone,
            Glycerin, Dimethicone/vinyl Dimethicone Crosspolymer,
            Phenoxyethanol, Polyacrylamide, Cetearyl Olivate, Sorbitan Olivate,
            Dimethiconol, C13-14 Isoparaffin, Dimethicone Crosspolymer,
            Chlorphenesin, Carbomer, Laureth-7, Sodium Hyaluronate,
            Ethylhexylglycerin, Fragrance, C12-14 Pareth -12, Sodium Hydroxide,
            Blue 1
          </SmallText>

          <Subheadline>Brush</Subheadline>
          <Description>
            <span className="bold">Description:</span> Skin-soft brush made from
            natural materials. Specially created to apply our face masks. Rinse
            with water after using.
          </Description>

          <Subheadline>Face Sponge</Subheadline>
          <Description>
            <span className="bold">Description:</span> Designed to gently remove
            face masks from the skin. Rinse with water after using.
          </Description>
        </Content>
      </Wrapper>
    </Backdrop>
  );
};

ProductsDescriptionModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.7);
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation: ${fadeIn} 0.2s ease-in forwards;
  animation-delay: 0.1s;

  @media ${smTablet} {
    width: auto;
    flex-direction: column;
    position: relative;
    left: 0;
    top: 0;
    transform: unset;
  }
`;
const CloseIcon = styled.img`
  display: none;
  position: absolute;
  top: 15px;
  right: 15px;

  @media ${tablet} {
    display: block;
  }
`;
const Content = styled.div`
  width: 100%;
  height: 90vh;
  overflow: scroll;
  padding: 0 20px 30px;
  background-color: ${colors.almostWhite};

  @media ${tablet} {
    padding-top: 43px;
    padding-bottom: 34px;
  }

  @media ${smTablet} {
    height: 100vh;
  }
`;
const Headline = styled(Regular)`
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: ${colors.black};
`;
const Subheadline = styled(Regular)`
  padding-bottom: 15px;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  color: ${colors.greenLighter};
`;
const Description = styled(Regular)`
  padding-bottom: 10px;
  font-size: 18px;
  text-align: left;
  color: ${colors.black};

  .bold {
    font-weight: bold;
  }
`;
const SmallText = styled(Regular)`
  padding-bottom: 30px;
  font-size: 15px;
  text-align: left;
  color: ${colors.black};

  .bold {
    font-weight: bold;
  }
`;
