import React from 'react';
import PropTypes from 'prop-types';

import { UserData } from './UserData';
import { DaughtersData } from './DaughtersData';
import { GridOption } from './GridOption';
import { SingleOption } from './SingleOption';
import { InfoQuestion } from './InfoQuestion';

const questions = {
  single: SingleOption,
  multiple: GridOption,
  user_data: UserData,
  daughters_data: DaughtersData,
  info: InfoQuestion,
};

export const AskQuestion = ({ answer, question, onSelect }) => {
  const Question = questions[question.type] || null;

  if (!Question) {
    return null; // Return error alert
  }

  return <Question answer={answer} onSelect={onSelect} question={question} />;
};

AskQuestion.propTypes = {
  answer: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
};

AskQuestion.defaultProps = {
  answer: null,
};
