import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { H2, Regular } from '../../components';
import { icons } from '../../utils/icons';
import { colors } from '../../styles/colors';
import { mobile } from '../../styles/breakpoints';

export const FacialRituals = ({ backgroundColor }) => (
  <Container id="face-masks" backgroundColor={backgroundColor}>
    <Wrapper>
      <HeadingWrapper>
        <Heading>Facial rituals for mother & daughter</Heading>
        <Leaves1 src={icons.home_leaves_4} alt="Leaves" />
        <Leaves2 src={icons.home_leaves_5} alt="Leaves" />
      </HeadingWrapper>
      <Description>with instructions and pleasant surprises</Description>
    </Wrapper>
  </Container>
);

FacialRituals.propTypes = {
  backgroundColor: PropTypes.string,
};

FacialRituals.defaultProps = {
  backgroundColor: colors.white,
};

const Container = styled.div`
  padding-top: 95px;
  padding-bottom: 73px;
  background-color: ${props => props.backgroundColor};

  @media ${mobile} {
    padding-top: 44px;
    padding-bottom: 42px;
  }
`;
const Wrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;

  @media ${mobile} {
    padding: 0 24px;
  }
`;
const HeadingWrapper = styled.div`
  position: relative;
  display: inline-block;
`;
const Heading = styled(H2)`
  display: inline-block;
`;
const Description = styled(Regular)`
  font-size: 22px;
  font-weight: 500;
  color: ${colors.blackAlpha(0.8)};

  @media ${mobile} {
    text-align: left;
  }
`;
const Leaves1 = styled.img`
  position: absolute;
  bottom: -60px;
  left: -85px;

  @media ${mobile} {
    display: none;
  }
`;
const Leaves2 = styled.img`
  position: absolute;
  top: -35px;
  right: -85px;

  @media ${mobile} {
    display: none;
  }
`;
