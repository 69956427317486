import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { icons } from '../../../utils/icons';
import { colors } from '../../../styles/colors';
import { Regular } from '../../text/Regular';

export const InfoRatedQuestion = ({ question }) => {
  const { custom, label } = question;

  return (
    <Container>
      <img src={icons[`quiz_${custom.icon}`]} alt={custom.icon} />
      <StarsIcon src={icons.quiz_stars} alt="Stars" />
      <Label>{label}</Label>
    </Container>
  );
};

InfoRatedQuestion.propTypes = {
  question: PropTypes.shape({
    custom: PropTypes.shape({
      icon: PropTypes.string,
    }),
    label: PropTypes.string,
  }).isRequired,
};

const Container = styled.div`
  padding-top: 14px;
  padding-bottom: 32px;
`;
const StarsIcon = styled.img`
  display: block;
  padding-top: 19px;
  padding-bottom: 14px;
  margin: 0 auto;
`;
const Label = styled(Regular)`
  font-size: 18px;
  font-weight: normal;
  line-height: 26px;
  text-align: center;
  color: ${colors.blackAlpha(0.8)};
`;
