import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors } from '../../styles/colors';
import { mobile } from '../../styles/breakpoints';
import { Spinner } from '../layout/Spinner';

export const DefaultButton = ({
  id,
  onClick,
  loading,
  height,
  fullWidth,
  padding,
  children,
  label,
  disabled,
  type,
  backgroundColor,
}) => {
  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  return (
    <Container
      id={id}
      type={type}
      height={height}
      padding={padding}
      disabled={disabled}
      onClick={handleClick}
      backgroundColor={backgroundColor}
      className={`${disabled && 'disable'} ${
        (fullWidth || type === 'submit') && 'long'
      }`}
    >
      {loading ? (
        <LoaderContainer>
          <Spinner disabled={disabled} backgroundColor={backgroundColor} />
        </LoaderContainer>
      ) : (
        children || label
      )}
    </Container>
  );
};

DefaultButton.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  height: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  padding: PropTypes.string,
  type: PropTypes.string,
};

DefaultButton.defaultProps = {
  backgroundColor: null,
  children: null,
  disabled: false,
  fullWidth: false,
  height: '48px',
  loading: false,
  padding: '0 40px',
  type: 'button',
};

const Container = styled.button`
  position: relative;
  height: ${props => props.height || '48px'};
  padding: ${props => props.padding || '0 40px'};
  font-size: 17px;
  font-weight: 600;
  color: ${props => props.color || colors.white};
  background: ${props => props.backgroundColor || colors.gradient};
  border: none;
  border-radius: 30px;
  transition: opacity 0.2s;
  outline: none;
  cursor: pointer;

  &.disable {
    cursor: not-allowed;
    background: ${props => props.disabledColor || colors.foggyGreen};
  }

  &.long {
    width: 100%;
  }

  @media ${mobile} {
    width: 100%;
    font-size: 16px;
  }
`;
const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
