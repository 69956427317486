import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { icons } from '../../utils/icons';
import { smDesktop } from '../../styles/breakpoints';
import { ReactComponent as ArrowLeft } from '../../assets/images/home/arrow_left.svg';
import { ReactComponent as ArrowRight } from '../../assets/images/home/arrow_right.svg';

export const CarouselDots = ({ activeSlide, setActiveSlide, dotsCount }) => {
  const dots = useRef(Array.from({ length: dotsCount }, (v, i) => i));

  return (
    <Container>
      <LeftDotsArrow
        src={icons.home_arrow_left}
        onClick={() =>
          setActiveSlide(activeSlide => {
            if (activeSlide > 0) {
              return activeSlide - 1;
            }

            return activeSlide;
          })
        }
      />
      {dots.current.map(dot => (
        <Dot key={dot} className={activeSlide === dot ? 'active' : null} />
      ))}
      <RightDotsArrow
        src={icons.home_arrow_right}
        onClick={() =>
          setActiveSlide(activeSlide => {
            if (activeSlide < dotsCount - 1) {
              return activeSlide + 1;
            }

            return activeSlide;
          })
        }
      />
    </Container>
  );
};

CarouselDots.propTypes = {
  activeSlide: PropTypes.number.isRequired,
  dotsCount: PropTypes.number.isRequired,
  setActiveSlide: PropTypes.func.isRequired,
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media ${smDesktop} {
    margin-top: 17px;
  }
`;
const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: #d0d0d0;
  margin-right: 12px;

  &:last-of-type {
    margin-right: 0;
  }

  &.active {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background: #1ab07c;
  }
`;
const LeftDotsArrow = styled(ArrowLeft)`
  display: none;
  margin-right: 14px;
  cursor: pointer;

  path {
    fill: #1ab07c;
  }

  @media ${smDesktop} {
    display: block;
  }
`;
const RightDotsArrow = styled(ArrowRight)`
  display: none;
  margin-left: 14px;
  cursor: pointer;

  path {
    fill: #1ab07c;
  }

  @media ${smDesktop} {
    display: block;
  }
`;
