import React, { useState } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';

import { MagicalMoments, Seo, Timer } from '../../components';
import { CheckoutDescription } from './CheckoutDescription';
import { BeautyRitualPlan } from './BeautyRitualPlan';
import { Reviews } from './Reviews';
import { Faq } from './Faq';
import { ProductsDescriptionModal } from './ProductsDescriptionModal';
import { PaymentModal } from './PaymentModal';

export const CheckoutV2 = ({ location }) => {
  let variant = 2;
  const searchParsed = qs.parse(location.search);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [
    isProductsDescriptionModalVisible,
    setIsProductsDescriptionModalVisible,
  ] = useState(false);
  const [selectedBundle, setSelectedBundle] = useState(
    searchParsed?.bundleId?.includes('regular') ? 'regular' : 'maxi',
  );
  const [selectedSubscription, setSelectedSubscription] = useState('6_months');

  return (
    <>
      <Seo tile="Bmoment - Facial rituals for mother & daughter" />
      <Timer
        isVisibleOnTablet={
          !isModalVisible && !isProductsDescriptionModalVisible
        }
      />
      <CheckoutDescription
        isVisibleOnTablet={
          !isModalVisible && !isProductsDescriptionModalVisible
        }
      />
      <BeautyRitualPlan
        variant={2}
        isVisibleOnTablet={
          !isModalVisible && !isProductsDescriptionModalVisible
        }
        selectedBundle={selectedBundle}
        selectedSubscription={selectedSubscription}
        setIsProductsDescriptionModalVisible={
          setIsProductsDescriptionModalVisible
        }
        setSelectedBundle={setSelectedBundle}
        setSelectedSubscription={setSelectedSubscription}
        onBuyClick={() => setIsModalVisible(true)}
      />
      <Reviews
        isVisibleOnTablet={
          !isModalVisible && !isProductsDescriptionModalVisible
        }
      />
      <MagicalMoments
        isVisibleOnTablet={
          !isModalVisible && !isProductsDescriptionModalVisible
        }
      />
      <Faq
        isVisibleOnTablet={
          !isModalVisible && !isProductsDescriptionModalVisible
        }
      />
      <BeautyRitualPlan
        variant={2}
        isVisibleOnTablet={
          !isModalVisible && !isProductsDescriptionModalVisible
        }
        selectedBundle={selectedBundle}
        selectedSubscription={selectedSubscription}
        setIsProductsDescriptionModalVisible={
          setIsProductsDescriptionModalVisible
        }
        setSelectedBundle={setSelectedBundle}
        setSelectedSubscription={setSelectedSubscription}
        onBuyClick={() => setIsModalVisible(true)}
      />
      <PaymentModal
        variant={variant}
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        selectedBundle={selectedBundle}
        selectedSubscription={selectedSubscription}
      />
      <ProductsDescriptionModal
        isVisible={isProductsDescriptionModalVisible}
        onClose={() => setIsProductsDescriptionModalVisible(false)}
      />
    </>
  );
};

CheckoutV2.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
};
