import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { config } from '../../config';
import { colors } from '../../styles/colors';

export const PayPalButton = memo(({ id, disabled }) => {
  const { push } = useHistory();
  const { scriptsLoaded, braintreeInstance } = useSelector(
    ({ payments }) => payments,
  );
  const buildPayPalButton = async () => {
    const createOptions = {
      container: `#paypal-${id}`,
      authorization: config.BRAINTREE_CLIENT_ID,
    };
    try {
      const client = await window.braintree.client.create(createOptions);
      const paypalCheckoutInstance = await window.braintree.paypalCheckout.create(
        { client },
      );
      window.paypal.Button.render(
        {
          env: 'sandbox',
          style: {
            color: 'gold',
            size: 'responsive',
            shape: 'rect',
            label: 'paypal',
            tagline: 'false',
          },
          onCancel: () => push('/error'),
          onError: () => {
            console.log('TODO: purchase implementation');
            push('/error');
          },
          payment: () =>
            paypalCheckoutInstance.createPayment({
              flow: 'vault',
              billingAgreementDescription:
                'PerfectBodyDNA personalized weight loss book.',
            }),
          onAuthorize: data =>
            paypalCheckoutInstance.tokenizePayment(data).then(payload => {
              console.log(payload);
            }),
        },
        `#paypal-${id}`,
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (scriptsLoaded && braintreeInstance) {
      buildPayPalButton();
    }
  }, [scriptsLoaded, braintreeInstance]);

  return (
    <Container>
      <div id={`paypal-${id}`} />
      {disabled ? <Overlay id="overlay" /> : null}
    </Container>
  );
});

PayPalButton.displayName = 'PayPalButton';

PayPalButton.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
};

PayPalButton.defaultProps = {
  disabled: false,
};

const Container = styled.div`
  position: relative;
`;
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: ${colors.whiteAlpha(0.5)};
`;
