import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { fetchQuiz } from '../utils/api';
import { Analytics } from '../apis/Analytics';
import { icons } from '../utils/icons';
import { colors } from '../styles/colors';
import { actions } from '../state/actions';
import { mobile, smTablet } from '../styles/breakpoints';
import MOM_DAUGHTER from '../assets/images/quiz_intro/mom_daughter.png';
import { OptionItem, Regular, Seo, Spinner } from '../components';

const daughtersCountOptions = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4 or more', value: '4_more' },
];

export const QuizIntro = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const handleFetchQuiz = useCallback(fetchQuiz, []);
  const { questions, daughtersEditionQuestions } = useSelector(
    ({ funnel }) => funnel,
  );
  const daughtersCount = useSelector(
    ({ funnel }) => funnel.userInfo.daughters_count,
  );
  const shouldFetchQuiz =
    !questions?.length || !daughtersEditionQuestions?.length;
  const [loading, setLoading] = useState(shouldFetchQuiz);
  const updateUser = value => dispatch(actions.funnel.updateUserInfo(value));
  const onSelect = value => {
    Analytics.trackQuizAnswer('daughters_count', value['label']);

    delete value['label'];

    updateUser(value);
    push('/quiz/0');
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (shouldFetchQuiz) {
      handleFetchQuiz({
        setLoading,
        onSetQuestions: questions =>
          dispatch(actions.funnel.setQuestions(questions)),
      });
    }
  }, []);

  if (loading) {
    return (
      <LoaderContainer>
        <Spinner backgroundColor="#f8f8fa" spinnerColor={colors.green} />
      </LoaderContainer>
    );
  }

  return (
    <>
      <Seo tile="Bmoment - Facial rituals for mother & daughter" />
      <Container>
        <Wrapper>
          <ImageContainer>
            <ImageWrapper>
              <MomDaughterImg src={MOM_DAUGHTER} alt="Mom daughter" />
            </ImageWrapper>
            <Leaves1 src={icons.quiz_intro_leaves_1} alt="Leaves" />
            <Logo src={icons.logo} alt="Logo" />
          </ImageContainer>
          <ContentWrapper>
            <Content>
              <Title>
                Unforgettable beauty rituals for mother and daughter
              </Title>
              <Description>
                Take a quiz to see what ritual we can build for you.
              </Description>
              <Leaves2 src={icons.quiz_intro_leaves_2} alt="Leaves" />
            </Content>
          </ContentWrapper>
          <QuestionWrapper>
            <QuestionLabel>
              Start by selecting how many daughters you have:
            </QuestionLabel>
            <QuestionsWrapper>
              {daughtersCountOptions.map((o, i) => (
                <OptionItem
                  id={`singleOption${i}`}
                  key={o.value}
                  {...o}
                  custom={{ showHorizontalArrow: true, halfWidthTablet: true }}
                  selected={o.value === daughtersCount}
                  onClick={() =>
                    onSelect({ daughters_count: o.value, label: o.label })
                  }
                />
              ))}
            </QuestionsWrapper>
          </QuestionWrapper>
        </Wrapper>
      </Container>
    </>
  );
};

const Container = styled.div`
  min-height: 100vh;
  padding-top: 56px;
  background-color: #f8f8fa;

  @media ${smTablet} {
    padding-top: 0;
  }
`;
const Wrapper = styled.div`
  position: relative;
  max-width: 860px;
  margin: 0 auto;
  text-align: center;
  background: ${colors.white};
  border-radius: 40px;

  @media ${mobile} {
    padding: 0;
  }
`;
const MomDaughterImg = styled.img`
  width: 100%;

  @media ${mobile} {
    width: 150%;
    margin-left: 50%;
    transform: translateX(-50%);
  }
`;
const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: ${colors.lightRed};
`;
const Content = styled.div`
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  padding: 41px 0 34px;

  @media ${smTablet} {
    padding: 15px 24px 19px;
  }
`;
const Logo = styled.img`
  display: block;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 79px;

  @media ${smTablet} {
    width: 140px;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
`;
const ImageContainer = styled.div`
  position: relative;
  background-color: ${colors.lightRed};
`;
const ImageWrapper = styled.div`
  overflow: hidden;
`;
const Leaves1 = styled.img`
  position: absolute;
  left: 22px;
  bottom: -25px;
  z-index: 1;

  @media ${smTablet} {
    left: 6px;
    bottom: -15px;
    width: 92px;
  }
`;
const Leaves2 = styled.img`
  width: 71px;
  position: absolute;
  bottom: 13px;
  right: 42px;

  @media ${smTablet} {
    width: 50px;
    right: 0;
    bottom: 0;
  }
`;
const Title = styled(Regular)`
  font-size: 28px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  color: ${colors.purple};
`;
const Description = styled(Regular)`
  padding: 6px 0 0;
  font-size: 18px;
  font-weight: normal;
  line-height: 26px;
  text-align: left;
  color: ${colors.blackAlpha(0.8)};
`;
const QuestionWrapper = styled.div`
  max-width: 700px;
  width: 100%;
  margin: 0 auto;

  @media ${smTablet} {
    padding: 0 24px;
  }
`;
const QuestionLabel = styled(Regular)`
  padding: 33px 0 41px;
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  color: ${colors.black};
`;
const QuestionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;
`;
const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #f8f8fa;
`;
