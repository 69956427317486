import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { H3 } from '../text/H3';
import { Regular } from '../text/Regular';
import { DefaultButton } from '../buttons/DefaultButton';
import { icons } from '../../utils/icons';
import { colors } from '../../styles/colors';
import { tablet } from '../../styles/breakpoints';

export const Superfruits = ({ direction, buttonText, buttonLinkTo }) => (
  <Container id="ingredients">
    <Wrapper className={direction === 'reverse' ? 'reverse' : null}>
      <Image src={icons.home_superfruits} alt="Superfruits" />
      <RightContainer>
        <Heading>Superfruits - the natural beauty heroes</Heading>
        <Description>
          We know how much you care about what you put on your skin, especially
          when your loved ones are involved.
        </Description>
        <Description>
          So do we – that’s why we use superfruits at the core of our
          child-friendly products. They contain antioxidants and vitamins to
          keep skin healthy – both for you and your daughter.
        </Description>
        <Description>
          They look great, they feel great, and they smell great. We love them
          and you’ll love them too!
        </Description>
        {buttonText && buttonLinkTo ? (
          <ButtonLink to={buttonLinkTo}>
            <DefaultButton
              id="seeProductBtn3"
              label={buttonText}
              type="button"
            />
          </ButtonLink>
        ) : null}
      </RightContainer>
    </Wrapper>
  </Container>
);

Superfruits.propTypes = {
  buttonLinkTo: PropTypes.string,
  buttonText: PropTypes.string,
  direction: PropTypes.oneOf(['default', 'reverse']),
};

Superfruits.defaultProps = {
  buttonLinkTo: null,
  buttonText: null,
  direction: 'default',
};

const Container = styled.div`
  padding: 43px 24px 48px;
  background-color: ${colors.white};

  @media ${tablet} {
    padding: 39px 0 46px;
  }
`;
const Wrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;

  &.reverse {
    flex-direction: row-reverse;
  }

  @media ${tablet} {
    flex-direction: column-reverse;

    &.reverse {
      flex-direction: column-reverse;
    }
  }
`;
const Image = styled.img`
  @media ${tablet} {
    width: 100%;
  }
`;
const RightContainer = styled.div`
  padding-top: 50px;

  @media ${tablet} {
    padding: 0 24px;
  }
`;
const Heading = styled(H3)`
  max-width: 398px;
`;
const Description = styled(Regular)`
  padding-top: 27px;
  font-size: 18px;
  color: ${colors.blackAlpha(0.8)};
`;
const ButtonLink = styled(Link)`
  display: inline-block;
  margin-top: 28px;

  @media ${tablet} {
    width: 100%;
  }
`;
