import { constants } from '../constants';

export const INITIAL_STATE = {
  questions: [],
  daughtersEditionQuestions: [],
  userInfo: {
    daughters: 1,
  },
};

export const funnelReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.funnel.UPDATE_USER_INFO:
      return { ...state, userInfo: { ...state.userInfo, ...action.payload } };
    case constants.funnel.UPDATE_USER_DAUGHTERS_INFO:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          daughtersData: {
            ...state.userInfo.daughtersData,
            ...action.payload,
          },
        },
      };
    case constants.funnel.SET_QUESTIONS:
      const questions = action.payload.questions.filter(
        ({ custom }) => !custom?.daughters_edition,
      );
      const daughtersEditionQuestions = action.payload.questions.filter(
        ({ custom }) => custom?.daughters_edition,
      );

      return { ...state, questions, daughtersEditionQuestions };
    default:
      return state;
  }
};
