import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { fadeIn } from '..';
import { colors } from '../../styles/colors';
import { mobile, smTablet } from '../../styles/breakpoints';
import { ReactComponent as ArrowIcon } from '../../assets/images/quiz/option_arrow.svg';
import { icons } from '../../utils/icons';
import { Regular } from '../text/Regular';

const checkIfDisabled = (condition, answers) => {
  if (typeof condition === 'string') {
    const answersStr = JSON.stringify(answers);
    const re = new RegExp(condition + '\\b');

    return re.test(answersStr);
  }

  return condition;
};

export const OptionItem = ({
  id,
  label,
  description,
  custom,
  fullWidth,
  onClick,
  selected,
}) => {
  const answers = useSelector(s => s.funnel.userInfo);
  const {
    disabled,
    horizontal,
    iconSize,
    icon,
    showHorizontalArrow,
    halfWidthTablet,
  } = custom;
  const isDisabled = checkIfDisabled(disabled, answers);
  const handleClick = () => {
    if (!isDisabled) {
      onClick();
    }
  };

  return (
    <OptionBox
      id={id}
      onClick={handleClick}
      isHorizontal={horizontal}
      halfWidthTablet={halfWidthTablet}
      className={`${selected && 'selected'} ${isDisabled && 'disabled'} ${
        fullWidth && 'full-width'
      }`}
      isBig={!!description}
    >
      <div>
        {icon && <Icon iconSize={iconSize} src={icons[icon]} alt="" />}
        <Caption margin={description ? '0 0 0.5rem 0' : '0'}>{label}</Caption>
        {description ? <Regular>{description}</Regular> : null}
      </div>
      {horizontal || showHorizontalArrow ? <ArrowIcon /> : null}
    </OptionBox>
  );
};

OptionItem.propTypes = {
  custom: PropTypes.object,
  description: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  fullWidth: PropTypes.bool,
  horizontal: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

OptionItem.defaultProps = {
  custom: {},
  fullWidth: false,
  // disabled: false,
  // selected: false,
  // horizontal: false,
};

const OptionBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 48%;
  height: 64px;
  padding: 1rem 38px;
  margin-bottom: 16px;
  background: ${colors.background};
  border-radius: 50px;
  transition: background 0.2s;
  vertical-align: top;
  opacity: 0;
  animation: ${fadeIn} 0.2s ease-in forwards;
  animation-delay: 0.1s;
  cursor: pointer;

  @media ${mobile} {
    width: 100%;
  }

  @media ${smTablet} {
    ${({ halfWidthTablet }) =>
      halfWidthTablet &&
      `
      width: 47.5%;
      padding: 14px 27px;
    `}
  }

  @media (min-width: 767px) {
    :hover:not(.disabled):not(.selected) {
      background: ${colors.primary};

      p {
        color: ${colors.white};
      }

      path {
        fill: ${colors.white};
      }
    }
  }

  &.selected {
    background: ${colors.primary};

    p {
      color: ${colors.white};
    }

    path {
      fill: ${colors.white};
    }
  }

  &.disabled {
    background: #7e7e7e;
    opacity: 0.8;
  }

  ${({ isHorizontal }) =>
    isHorizontal &&
    `
    width: auto;
    height: 64px;
    margin: 0 auto 1rem auto;
  `}

  &.full-width {
    width: 100%;
  }
`;

export const Caption = styled.p`
  display: ${props => props.display || 'block'};
  padding: ${props => props.padding || '0px'};
  margin: ${props => props.margin || '0px'};
  font-size: 20px;
  color: ${colors.black};
  vertical-align: middle;

  @media ${smTablet} {
    text-align: left;
  }

  &.bolder {
    font-weight: 600;
  }
`;

const Icon = styled.img`
  height: ${props => props.iconSize || '36'}px;
  margin-bottom: 0.5rem;
`;
