import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { icons } from '../../utils/icons';
import { colors } from '../../styles/colors';
import { Regular } from '../text/Regular';

export const CollapsableItems = ({ items }) => {
  const [isOpen, setIsOpen] = useState(false);

  if (!items) {
    return null;
  }

  return (
    <Container
      className={isOpen ? 'open' : null}
      onClick={() => setIsOpen(isOpen => !isOpen)}
    >
      <Header>
        <Label className={isOpen ? 'open' : null}>What&apos;s included</Label>
        <img
          alt="Chevron"
          src={icons[`checkout_chevron_${isOpen ? 'up' : 'down'}`]}
        />
      </Header>
      <Items className={isOpen ? 'open' : null}>
        {items.map(item => (
          <Content key={item}>
            <Dot />
            <Item>{item}</Item>
          </Content>
        ))}
      </Items>
    </Container>
  );
};

CollapsableItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
};

CollapsableItems.defaultProps = {
  items: null,
};

const Container = styled.div`
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;

  &.open {
    background-color: ${colors.almostWhite};
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Label = styled(Regular)`
  font-size: 18px;
  font-weight: 600;
  color: ${colors.blackAlpha(0.8)};

  &.open {
    color: ${colors.greenLighter};
  }
`;
const Items = styled.div`
  max-height: 0;
  transition: max-height 0.5s ease-out;
  overflow: hidden;

  &.open {
    max-height: 283px;
    transition: max-height 0.5s ease-in;
  }
`;
const Item = styled(Regular)`
  width: 95%;
  font-size: 18px;
  color: ${colors.blackAlpha(0.8)};
`;
const Content = styled.div`
  display: flex;
  align-items: flex-start;
`;
const Dot = styled.div`
  width: 2px;
  height: 2px;
  border-radius: 2px;
  margin-top: 12px;
  margin-right: 10px;
  background-color: ${colors.blackAlpha(0.8)};
`;
