import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { icons } from '../../utils/icons';
import { smDesktop } from '../../styles/breakpoints';
import { colors } from '../../styles/colors';

export const Cookies = () => {
  const [isHidden, setIsHidden] = useState(true);
  const onAcceptCookies = () => {
    setIsHidden(true);
    localStorage.setItem('COOKIE_SET', 'true');
  };

  useEffect(() => {
    const cookieSetValue = localStorage.getItem('COOKIE_SET');

    if (cookieSetValue !== 'true') {
      setIsHidden(false);
    }
  }, []);

  return isHidden ? null : (
    <CookiesContainer>
      <CookiesWrapper>
        <TextWrapper>
          This website uses cookies to improve your user experience. To learn
          more about our cookie policy or to withdraw from it, please check it
          out&nbsp;
          <StyledLink to="/privacy-policy">here</StyledLink>.
        </TextWrapper>
        <ButtonWrapper onClick={onAcceptCookies}>Accept</ButtonWrapper>
        <Icon onClick={() => setIsHidden(true)} src={icons.close} />
      </CookiesWrapper>
    </CookiesContainer>
  );
};

const CookiesContainer = styled.div`
  padding: 2.5rem 0;
  background-color: ${colors.lightRed};

  @media ${smDesktop} {
    padding: 2.5rem;
  }
`;
const CookiesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
`;
const ButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  border: 0;
  outline: none;
  color: ${colors.green};
  background-color: ${colors.lemon};
  border-radius: 30px;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
`;
const Icon = styled.img`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 12px;
  height: 12px;
  cursor: pointer;
`;
const TextWrapper = styled.div`
  max-width: 706px;
  text-align: left;
  color: ${colors.blackAlpha(0.8)};
`;
const StyledLink = styled(Link)`
  font-weight: bold;
  text-decoration: underline;
  color: ${colors.blackAlpha(0.8)};
`;
