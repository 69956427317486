import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { colors } from '../../../styles/colors';
import { smTablet } from '../../../styles/breakpoints';
import { H4, Regular } from '../../../components';
import { TestResultItem } from './TestResultItem';
import { icons } from '../../../utils/icons';
import {
  getGeneralConditionLabel,
  getMostSuitableFruitLabel,
  getQuestionOptions,
  getSkinConcernsLabel,
} from '../../../utils/quiz';

export const TestResults = () => {
  const {
    daughters,
    skin_type,
    skin_concern,
    skin_sensitivity,
    daughtersData,
    expected_effect,
  } = useSelector(s => s.funnel.userInfo);
  const questions = useSelector(s => s.funnel.questions) || [];
  const daughtersEditionQuestions =
    useSelector(s => s.funnel.daughtersEditionQuestions) || [];
  const skinTypes = getQuestionOptions({ key: 'skin_type', questions });
  const skinConcerns = getQuestionOptions({ key: 'skin_concern', questions });
  const daughterSkinConcerns = getQuestionOptions({
    key: 'skin_concerns',
    questions: daughtersEditionQuestions,
  });

  return (
    <>
      <Container>
        <Heading>Your personalized results:</Heading>
        <WidgetsGrid className="row-of-three">
          <TestResultItem
            icon="summary_sad_face"
            label="General condition:"
            value={getGeneralConditionLabel({
              skinTypes,
              skinType: skin_type,
              skinSensitivity: skin_sensitivity,
            })}
          />
          <TestResultItem
            icon="summary_nerd_face"
            label="Skin concerns:"
            value={getSkinConcernsLabel({
              skinConcerns,
              skinConcern: skin_concern,
            })}
          />
          <TestResultItem
            icon={`summary_${
              expected_effect === 'glow' ? 'avocado' : 'acai_berry'
            }`}
            label="Most suitable superfruit:"
            value={getMostSuitableFruitLabel({
              expectedEffect: expected_effect,
            })}
          />
        </WidgetsGrid>
        <DescriptionContainer>
          <Description>
            {expected_effect === 'glow'
              ? 'Next shining version of you with the face mask with avocado oil'
              : 'Healthier, more radiant looking skin with the face mask with acai berry extract'}
          </Description>
          <Leaves1 src={icons.summary_leaves_1} alt="Leaves" />
          <Leaves2 src={icons.summary_leaves_2} alt="Leaves" />
        </DescriptionContainer>
      </Container>
      <Container backgroundColor={colors.lightRed}>
        {Array.from(Array(parseInt(daughters, 10))).map((_, index) => (
          <Fragment key={index}>
            <Heading>
              Your beloved {daughtersData[`daughter${index + 1}_name`]} has:
            </Heading>
            <WidgetsGrid className="row-of-three">
              <TestResultItem
                icon="summary_sad_face"
                label="General condition:"
                value={getGeneralConditionLabel({
                  skinTypes,
                  skinType: daughtersData[`daughter${index + 1}_skin`],
                  skinSensitivity:
                    daughtersData[`daughter${index + 1}_skin_sensitivity`],
                })}
              />
              <TestResultItem
                icon="summary_nerd_face"
                label="Skin concerns:"
                value={getSkinConcernsLabel({
                  skinConcerns: daughterSkinConcerns,
                  skinConcern:
                    daughtersData[`daughter${index + 1}_skin_concerns`],
                })}
              />
              <TestResultItem
                icon={`summary_${
                  daughtersData[`daughter${index + 1}_expected_effects`] ===
                  'healthy_glow'
                    ? 'avocado'
                    : 'acai_berry'
                }`}
                label="Most suitable superfruit:"
                value={getMostSuitableFruitLabel({
                  expectedEffect:
                    daughtersData[`daughter${index + 1}_expected_effects`],
                })}
              />
            </WidgetsGrid>
          </Fragment>
        ))}
        <DescriptionContainer>
          <Description>
            It’s really important to focus on the right face treatment and use
            only non-harmful, natural ingredients like{' '}
            {daughtersData.daughter1_expected_effects === 'healthy_glow'
              ? 'avocado'
              : 'acai berry'}{' '}
            oil on her young skin.
          </Description>
          <Leaves3 src={icons.summary_leaves_3} alt="Leaves" />
          <Leaves4 src={icons.summary_leaves_4} alt="Leaves" />
        </DescriptionContainer>
      </Container>
    </>
  );
};

const Container = styled.div`
  padding-top: 36px;
  padding-bottom: 78px;
  background-color: ${props => props.backgroundColor || colors.almostWhite};
`;
const Heading = styled(H4)`
  padding-bottom: 26px;
  font-size: 32px;
  text-align: center;
`;
const WidgetsGrid = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  max-width: 1200px;
  width: 100%;
  padding: 0 24px;
  margin: 0 auto 23px;

  &.row-of-two {
    grid-template-columns: repeat(2, 1fr);

    @media ${smTablet} {
      grid-template-columns: auto;
    }
  }

  &.row-of-three {
    grid-template-columns: repeat(3, 1fr);

    @media ${smTablet} {
      grid-template-columns: auto;
    }
  }
`;
const DescriptionContainer = styled.div`
  position: relative;
  max-width: 781px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 24px 0;
`;
const Description = styled(Regular)`
  font-size: 20px;
  line-height: 26px;
  color: ${colors.black};
  text-align: center;
`;
const Leaves1 = styled.img`
  position: absolute;
  bottom: -60px;
  left: -70px;
`;
const Leaves2 = styled.img`
  position: absolute;
  top: 0;
  right: 20px;
`;
const Leaves3 = styled.img`
  position: absolute;
  top: 0;
  left: -70px;
`;
const Leaves4 = styled.img`
  position: absolute;
  bottom: -45px;
  right: 0;
`;
