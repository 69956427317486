import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { H3 } from '../text/H3';
import { Regular } from '../text/Regular';
import { DefaultButton } from '../buttons/DefaultButton';
import { icons } from '../../utils/icons';
import { colors } from '../../styles/colors';
import { mobile } from '../../styles/breakpoints';

export const OurPromise = ({ buttonText, buttonLinkTo }) => (
  <Container id="our-promise">
    <Wrapper>
      <Heading>Our Promise</Heading>
      <Grid>
        <Item
          icon="home_angel"
          label="Our products are 100% safe for children."
        />
        <Item
          icon="home_branch"
          label="Our products contain the best natural ingredients."
        />
        <Item
          icon="home_unicorn"
          label="Our face mask with superfruits nourishes and moisturises the skin."
        />
        <Item
          icon="home_massage"
          label="Our custom-made beauty rituals are easy to follow, with helpful guides and tips along the way."
        />
      </Grid>
      {buttonText && buttonLinkTo ? (
        <Link to={buttonLinkTo}>
          <DefaultButton id="seeProductBtn4" label={buttonText} type="button" />
        </Link>
      ) : null}
    </Wrapper>
  </Container>
);

OurPromise.propTypes = {
  buttonLinkTo: PropTypes.string,
  buttonText: PropTypes.string,
};

OurPromise.defaultProps = {
  buttonLinkTo: null,
  buttonText: null,
};

const Item = ({ icon, label }) => (
  <ItemContainer>
    <img src={icons[icon]} alt={icon} />
    <ItemLabel>{label}</ItemLabel>
  </ItemContainer>
);

Item.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

const Container = styled.div`
  padding: 43px 24px 50px;
  background-color: ${colors.lightRed};

  @media ${mobile} {
    padding: 43px 0 50px;
  }
`;
const Wrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;

  @media ${mobile} {
    padding: 0 24px;
  }
`;
const Heading = styled(H3)`
  text-align: center;

  @media ${mobile} {
    width: 100%;
    text-align: left;
  }
`;
const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 38px;
`;
const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 279px;
  height: 255px;
  margin-bottom: 38px;
  border-radius: 10px;
  background-color: ${colors.white};

  @media ${mobile} {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100px;
    padding: 0 14px;
    padding-bottom: 15px;

    img {
      width: 32px;
      height: 32px;
      margin-right: 11px;
    }
  }
`;
const ItemLabel = styled(Regular)`
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: ${colors.blackAlpha(0.8)};
  max-width: 198px;
  width: 100%;
  margin-top: 26px;

  @media ${mobile} {
    max-width: unset;
    font-size: 18px;
    text-align: left;
  }
`;
