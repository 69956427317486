import * as Yup from 'yup';

export const UserDataSchema = Yup.object().shape({
  age: Yup.number().min(18).max(120).required(),
  name: Yup.string()
    .min(2, 'Too short')
    .max(50, 'Too long')
    .required('Required'),
});

export const DaughtersDataSchema = daughtersCount => {
  const shape = {};

  for (let i = 0; i < daughtersCount; i += 1) {
    // shape[`daughter${i + 1}_age`] = Yup.number().min(1).max(120).required();
    shape[`daughter${i + 1}_name`] = Yup.string().min(2).max(50).required();
  }

  return Yup.object().shape(shape);
};

export const EmailSchema = Yup.object().shape({
  email: Yup.string().email().required(),
});

export const CheckoutSchema = Yup.object().shape({
  address: Yup.string().required(),
  agreedToTNC: Yup.boolean().oneOf(
    [true],
    'Must agree to T&C and Privacy Policy',
  ),
  apartment: Yup.string(),
  city: Yup.string().required(),
  country: Yup.string().required(),
  email: Yup.string().email().required(),
  fullName: Yup.string().required(),
  postalCode: Yup.string().required(),
});
