import storage from 'redux-persist/lib/storage';

import { config } from '../config';
import { createStore } from 'redux';
import { rootReducer } from './reducers';
import { reactotron } from '../utils/reactotron';
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['payments'],
};

const getStoreConfig = () => {
  let store;
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  if (config.ENV === 'development') {
    store = createStore(persistedReducer, reactotron.createEnhancer());
  } else {
    store = createStore(persistedReducer);
  }

  const persistor = persistStore(store);

  return { store, persistor };
};

export const storeConfig = getStoreConfig();
