import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { icons } from '../../utils/icons';
import { colors } from '../../styles/colors';
import { mobile } from '../../styles/breakpoints';
import { ReactComponent as Close } from '../../assets/images/nav/close.svg';
import { fadeIn, Regular } from '../../components';
import { BUNDLES } from './CheckoutConstants';

export const BundleModal = ({ visibleBundle, onClose }) => {
  const bundle = BUNDLES.find(({ id }) => id === visibleBundle);
  const { expected_effect, daughtersData } = useSelector(
    s => s.funnel.userInfo,
  );
  const momsFruit = expected_effect === 'glow' ? 'avocado' : 'acai_berry';
  const daughtersFruit =
    daughtersData?.daughter1_expected_effects === 'vitamin_mineral_boost'
      ? 'acai_berry'
      : 'avocado';
  const momsFruitFormatted =
    momsFruit === 'avocado' ? 'Avocado oil' : 'Acai berry extract';
  const daughtersFruitFormatted =
    daughtersFruit === 'avocado' ? 'Avocado oil' : 'Acai berry extract';

  if (!visibleBundle || !bundle) {
    return null;
  }

  return (
    <Backdrop onClick={onClose}>
      <Wrapper>
        <Header>
          <CloseIcon src={icons.close} alt="Close" onClick={onClose} />
          <BundleName>
            {`${bundle.title} beauty kit with ${
              momsFruit === 'avocado' ? 'avocado oil' : 'acai berry extract'
            }`}
          </BundleName>
          <Title>What&apos;s included</Title>
        </Header>
        <Content>
          {bundle.includedItems.map(({ name, effect, image, productFor }) => {
            const fruit =
              productFor === 'daughter' ? daughtersFruit : momsFruit;
            const imageWidth =
              fruit === 'avocado' && image === 'big_mask' ? '42px' : '100%';

            return (
              <IncludedItem key={name}>
                <IncludedItemImageWrapper>
                  <IncludedItemImage
                    alt={name}
                    width={imageWidth}
                    src={icons[`checkout_${fruit}_${image}`]}
                  />
                </IncludedItemImageWrapper>
                <div>
                  <IncludedItemName>
                    {name
                      .replace('%moms_fruit%', momsFruitFormatted)
                      .replace('%daughters_fruit%', daughtersFruitFormatted)}
                  </IncludedItemName>
                  {effect ? (
                    <IncludedItemEffect>{effect}</IncludedItemEffect>
                  ) : null}
                </div>
              </IncludedItem>
            );
          })}
        </Content>
      </Wrapper>
    </Backdrop>
  );
};

BundleModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  visibleBundle: PropTypes.string,
};

BundleModal.defaultProps = {
  visibleBundle: null,
};

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.7);
`;
const Wrapper = styled.div`
  max-width: 439px;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation: ${fadeIn} 0.2s ease-in forwards;
  animation-delay: 0.1s;

  @media ${mobile} {
    max-width: 327px;
    width: 100%;
  }
`;
const CloseIcon = styled(Close)`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;

  rect {
    fill: ${colors.white};
  }
`;
const Content = styled.div`
  width: 100%;
  height: 413px;
  overflow: scroll;
  background-color: ${colors.white};
`;
const Header = styled.div`
  padding: 20px 28px;
  background-color: ${colors.green};
`;
const BundleName = styled(Regular)`
  padding-bottom: 5px;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: ${colors.whiteAlpha(0.8)};
`;
const Title = styled(Regular)`
  font-size: 18px;
  font-weight: 600;
  line-height: 19px;
  color: ${colors.white};
`;
const IncludedItem = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 20px 28px;
  border-bottom: 1px solid #e9e9e9;
`;
const IncludedItemImageWrapper = styled.div`
  flex-shrink: 0;
  flex-basis: 52px;
  height: 45px;
  margin-right: 25px;
`;
const IncludedItemImage = styled.img`
  display: block;
  width: ${props => props.width || '100%'};
  height: 100%;
  margin: 0 auto;
  object-fit: contain;
`;
const IncludedItemName = styled(Regular)`
  font-size: 18px;
  line-height: 26px;
  font-weight: normal;
  color: ${colors.blackAlpha(0.8)};
`;
const IncludedItemEffect = styled(Regular)`
  font-size: 16px;
  line-height: 26px;
  font-weight: normal;
  color: ${colors.blackAlpha(0.6)};
`;
