import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { H2 } from '../text/H2';
import { Regular } from '../text/Regular';
import { icons } from '../../utils/icons';
import { smDesktop, tablet } from '../../styles/breakpoints';
import { colors } from '../../styles/colors';

const MagicalMomentItem = ({ icon, text }) => (
  <MagicalMomentItemContainer>
    <img src={icon} alt="People" />
    <MagicalMomentItemText>{text}</MagicalMomentItemText>
  </MagicalMomentItemContainer>
);

MagicalMomentItem.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export const MagicalMoments = ({ backgroundColor, isVisibleOnTablet }) => (
  <Container
    id="magical-moments"
    backgroundColor={backgroundColor}
    isVisibleOnTablet={isVisibleOnTablet}
  >
    <Wrapper>
      <Heading>Magical moments together</Heading>
      <ImagesContainer>
        <MagicalMomentItem
          icon={icons.home_people_1}
          text="Unboxing skincare bundles"
        />
        <MagicalMomentItem
          icon={icons.home_people_2}
          text="Testing and smelling the products"
        />
        <MagicalMomentItem
          icon={icons.home_people_3}
          text="Learning about skincare together"
        />
        <MagicalMomentItem
          icon={icons.home_people_4}
          text="Bonding with your daughter"
        />
      </ImagesContainer>
    </Wrapper>
  </Container>
);

MagicalMoments.propTypes = {
  backgroundColor: PropTypes.string,
  isVisibleOnTablet: PropTypes.bool,
};

MagicalMoments.defaultProps = {
  backgroundColor: colors.lightRoseGold,
  isVisibleOnTablet: true,
};

const Container = styled.div`
  background-color: ${props => props.backgroundColor};

  @media ${tablet} {
    display: ${props => (props.isVisibleOnTablet ? 'block' : 'none')};
  }
`;
const Wrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 78px 0;

  @media ${smDesktop} {
    padding: 43px 24px 40px;
  }
`;
const Heading = styled(H2)`
  text-align: center;
  padding-bottom: 48px;

  @media ${smDesktop} {
    text-align: left;
  }
`;
const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media ${smDesktop} {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
`;
const MagicalMomentItemContainer = styled.div`
  position: relative;
  margin-right: 20px;

  &:last-of-type {
    margin-right: 0;
  }
`;
const MagicalMomentItemText = styled(Regular)`
  position: absolute;
  left: 23px;
  right: 23px;
  bottom: 33px;
  font-size: 24px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
  color: ${colors.white};
`;
