import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';

import { icons } from '../../utils/icons';
import { colors } from '../../styles/colors';
import { mobile } from '../../styles/breakpoints';
import { isRoute } from '../../utils/navigation';
import { Regular } from '../text/Regular';

const isQuizRoute = isRoute('(quiz|calculating|summary|email|success)');

export const Footer = () => {
  const { listen, location } = useHistory();
  const [isHidden, setHidden] = useState(isQuizRoute(location));

  listen(l => {
    if (isQuizRoute(l) && !isHidden) {
      setHidden(true);
    } else if (isHidden && !isQuizRoute(l)) {
      setHidden(false);
    }
  });

  return isHidden ? null : (
    <Container>
      <Wrapper>
        <div>
          <CopyrightText>
            © Copyright {new Date().getFullYear()} www.bmoment.co
          </CopyrightText>
          <LinksContainer>
            <LinkText to="/general-conditions">General Conditions</LinkText>
            <Divider />
            <LinkText to="/privacy-policy">Privacy Policy</LinkText>
          </LinksContainer>
        </div>
        <ContactWrapper>
          <EnvelopeWrapper>
            <img src={icons.footer_envelope} alt="Envelope" />
          </EnvelopeWrapper>
          <ContactText>
            Contact us: <a href="mailto:hello@bmoment.co">hello@bmoment.co</a>
          </ContactText>
        </ContactWrapper>
        <MobileLinksContainer>
          <LinkText to="/general-conditions">General Conditions</LinkText>
          <Divider />
          <LinkText to="/privacy-policy">Privacy Policy</LinkText>
        </MobileLinksContainer>
      </Wrapper>
    </Container>
  );
};

const Container = styled.footer`
  padding: 30px 24px;
  background-color: ${colors.white};

  @media ${mobile} {
    padding: 44px 24px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1185px;
  width: 100%;
  margin: 0 auto;

  @media ${mobile} {
    flex-direction: column-reverse;
  }
`;
const CopyrightText = styled(Regular)`
  font-size: 18px;
  line-height: 26px;
  color: ${colors.blackAlpha(0.8)};
`;
const LinksContainer = styled.div`
  display: flex;
  align-items: center;

  @media ${mobile} {
    display: none;
  }
`;
const MobileLinksContainer = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media ${mobile} {
    display: flex;
  }
`;
const LinkText = styled(Link)`
  font-size: 14px;
  line-height: 26px;
  color: ${colors.blackAlpha(0.6)};
  text-decoration: none;

  @media ${mobile} {
    font-size: 18px;
  }
`;
const Divider = styled.div`
  width: 1px;
  height: 14px;
  margin: 0 10px;
  background-color: ${colors.blackAlpha(0.6)};

  @media ${mobile} {
    margin: 0;
  }
`;
const ContactWrapper = styled.div`
  display: flex;
  align-items: center;

  @media ${mobile} {
    padding: 22px;
    margin: 26px 0;
    border-radius: 26px;
    background-color: ${colors.almostWhite};
  }
`;
const EnvelopeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 20px;
  margin-right: 14px;
  background-color: ${colors.almostWhite};
`;
const ContactText = styled(Regular)`
  font-size: 16px;
  line-height: 24px;
  color: ${colors.blackAlpha(0.8)};

  a {
    font-weight: 600;
    text-decoration: none;
    color: ${colors.blackAlpha(0.8)};
  }
`;
