import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compact, values } from 'lodash';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';

import { actions } from '../../state/actions';

const EXT_SCRIPTS = [
  {
    'log-level': 'warn',
    id: 'payPalCheckout',
    'data-version-4': true,
    src: 'https://www.paypalobjects.com/api/checkout.js',
  },
  {
    id: 'brainTreeDropIn',
    src: 'https://js.braintreegateway.com/web/dropin/1.22.1/js/dropin.min.js',
  },
  {
    id: 'braintreeClient',
    src: 'https://js.braintreegateway.com/web/3.62.2/js/client.min.js',
  },
  {
    id: 'payPal',
    src: 'https://js.braintreegateway.com/web/3.62.2/js/paypal-checkout.min.js',
  },
];

export const ExternalScripts = ({ onLoadComplete }) => {
  const dispatch = useDispatch();
  const [loadedScripts, setLoadedScripts] = useState({
    payPal: window.paypal,
    braintreeClient: window.braintree && window.braintree.client,
    brainTreeDropIn: window.braintree && window.braintree.dropin,
    payPalCheckout: window.braintree && window.braintree.paypalCheckout,
  });
  const setScriptLoaded = key => () => {
    setLoadedScripts(ps => ({ ...ps, [key]: true }));
  };
  const handleScriptInject = ({ scriptTags }) => {
    if (scriptTags?.length) {
      scriptTags.forEach(s => {
        if (s.id) {
          s.onload = setScriptLoaded(s.id);
        }
      });
    }
  };

  useEffect(() => {
    const countOfLoadedScripts = compact(values(loadedScripts));
    if (countOfLoadedScripts.length === 4) {
      dispatch(actions.payments.setScriptsLoaded(true));
      onLoadComplete();
    }
  }, [loadedScripts]);

  return (
    <Helmet
      onChangeClientState={(_, addedTags) => handleScriptInject(addedTags)}
    >
      {EXT_SCRIPTS.filter(s => !loadedScripts[s.id]).map((s, i) => (
        <script key={i} {...s} />
      ))}
    </Helmet>
  );
};

ExternalScripts.propTypes = {
  onLoadComplete: PropTypes.func,
};

ExternalScripts.defaultProps = {
  onLoadComplete: () => null,
};
