import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { icons } from '../../utils/icons';
import { colors } from '../../styles/colors';
import { mobile, tablet } from '../../styles/breakpoints';
import { H2, Regular } from '../../components';

const ReviewItem = ({ photo, name, review, timeAgo }) => (
  <div>
    <ReviewItemContainer>
      <ReviewUserImg src={photo} alt="User" />
      <ReviewContentContainer>
        <Name>{name}</Name>
        <ReviewText>{review}</ReviewText>
      </ReviewContentContainer>
    </ReviewItemContainer>
    <ReviewFooter>
      <ReviewFooterText>Like</ReviewFooterText>
      <Dot />
      <ReviewFooterText>Reply</ReviewFooterText>
      <Dot />
      <ReviewFooterTime>{timeAgo}</ReviewFooterTime>
    </ReviewFooter>
  </div>
);

ReviewItem.propTypes = {
  name: PropTypes.string.isRequired,
  photo: PropTypes.string.isRequired,
  review: PropTypes.string.isRequired,
  timeAgo: PropTypes.string.isRequired,
};

export const Reviews = ({ isVisibleOnTablet }) => (
  <Container isVisibleOnTablet={isVisibleOnTablet}>
    <Wrapper>
      <HeadingContainer>
        <Heading>Join the club of thousand happy mothers</Heading>
        <Leaves1 src={icons.checkout_leaves_3} alt="Leaves" />
        <Leaves2 src={icons.checkout_leaves_4} alt="Leaves" />
      </HeadingContainer>
      <ReviewItemsWrapper>
        <ReviewItems>
          <ReviewItem
            timeAgo="16m"
            name="Jessica Ashley"
            photo={icons.checkout_user_1}
            review="Me and my daughter absolutely fell in love with B.Moment face masks. We are getting impatient while waiting for the next one :)"
          />
          <ReviewItem
            timeAgo="36m"
            name="Carol Conway"
            photo={icons.checkout_user_2}
            review={`Those beauty rituals changed our live and made us best friends for ever!\nThank you B.Moment!`}
          />
          <ReviewItem
            timeAgo="56m"
            name="Hilda Craig"
            photo={icons.checkout_user_3}
            review="My daughter is so motivated because she has real and most important HER OWN cosmetics! No doubt I recommend B.Moment!"
          />
        </ReviewItems>
      </ReviewItemsWrapper>
    </Wrapper>
  </Container>
);

Reviews.propTypes = {
  isVisibleOnTablet: PropTypes.bool,
};

Reviews.defaultProps = {
  isVisibleOnTablet: true,
};

const Container = styled.div`
  padding-top: 66px;
  padding-bottom: 68px;
  background-color: ${colors.white};

  @media ${mobile} {
    padding-top: 42px;
    padding-bottom: 50px;
  }

  @media ${tablet} {
    display: ${props => (props.isVisibleOnTablet ? 'block' : 'none')};
  }
`;
const Wrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;

  @media ${mobile} {
    padding: 0 24px;
  }
`;
const HeadingContainer = styled.div`
  position: relative;
  max-width: 550px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 57px;

  @media ${mobile} {
    max-width: 285px;
  }
`;
const Heading = styled(H2)`
  display: inline-block;
  font-size: 40px;
  text-align: center;
`;
const Leaves1 = styled.img`
  position: absolute;
  bottom: 30px;
  left: -30px;

  @media ${mobile} {
    left: -35px;
  }
`;
const Leaves2 = styled.img`
  position: absolute;
  top: -30px;
  right: -50px;
`;
const ReviewItemsWrapper = styled.div`
  padding: 0 24px;
`;
const ReviewItems = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 31px;

  @media ${tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;
const ReviewItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 380px;
  width: 100%;
`;
const ReviewContentContainer = styled.div`
  max-width: 289px;
  width: 100%;
  padding: 12px;
  margin-left: 5px;
  border-radius: 16px;
  background-color: ${colors.almostWhite};
`;
const Name = styled(Regular)`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #000000;
`;
const ReviewText = styled(Regular)`
  max-width: 242px;
  width: 100%;
  font-size: 16px;
  line-height: 18px;
  font-weight: normal;
  text-align: left;
  white-space: pre-wrap;
  color: ${colors.black};
`;
const ReviewUserImg = styled.img`
  width: 32px;
  height: 32px;
`;
const ReviewFooter = styled.div`
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-left: 49px;
`;
const ReviewFooterText = styled(Regular)`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #66676b;
`;
const ReviewFooterTime = styled(Regular)`
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #66676b;
`;
const Dot = styled.div`
  width: 2px;
  height: 2px;
  border-radius: 2px;
  margin: 2px 6px 0;
  background-color: #66676b;
`;
