import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { actions } from '../../state/actions';
import { colors } from '../../styles/colors';
import { smDesktop } from '../../styles/breakpoints';
import { MagicalMoments, OurPromise, Seo, Superfruits } from '../../components';
import { HomeHeaderV2 } from './HomeHeaderV2';
import { FacialRituals } from './FacialRituals';
import { BestSellingRituals } from './BestSellingRituals';
import { MoisturisingFaceMask } from './MoisturisingFaceMask';
import { StartYourRitual } from './StartYourRitual';

export const HomeV2 = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.app.setVariant(2));
  }, []);

  return (
    <>
      <Seo title="Bmoment - Facial rituals for mother & daughter" />
      <HomeWrapper>
        <HomeHeaderV2 />
        <MagicalMoments />
        <BestSellingRituals />
        <FacialRituals backgroundColor={colors.lightRoseGold} />
        <MoisturisingFaceMask variant={2} />
        <Superfruits />
        <OurPromise />
        <StartYourRitual variant={2} />
      </HomeWrapper>
    </>
  );
};

const HomeWrapper = styled.div`
  position: relative;
  display: block;
  text-align: center;
  margin-top: 70px;

  @media ${smDesktop} {
    margin-top: 0;
  }
`;
