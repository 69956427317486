import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { icons } from '../../utils/icons';
import { colors } from '../../styles/colors';
import { mobile, xsmMobile } from '../../styles/breakpoints';
import { CollapsableItems, DefaultButton, Regular } from '..';

export const RitualBundleItemV3 = ({
  id,
  icon,
  title,
  oneTimePlan,
  monthlySubscriptionPlan,
  reviewsCount,
  momsFruit,
  daughtersFruit,
  includedItems,
  onBuyClick,
  onIncludedItemsClick,
}) => {
  const momsFruitFormatted =
    momsFruit === 'avocado' ? 'Avocado oil' : 'Acai berry extract';
  const daughtersFruitFormatted =
    daughtersFruit === 'avocado' ? 'Avocado oil' : 'Acai berry extract';

  return (
    <Container>
      <Header
        className={onBuyClick ? 'clickable' : null}
        onClick={onBuyClick ? () => onBuyClick(id) : null}
      >
        <Image src={icon} alt="Ritual bundle" />
        <Title marginBottom={includedItems ? '10px' : null}>{title}</Title>
      </Header>
      {includedItems ? (
        <CollapsableItems
          items={includedItems.map(({ name, ...rest }) => ({
            name: name
              .replace('%moms_fruit%', momsFruitFormatted)
              .replace('%daughters_fruit%', daughtersFruitFormatted),
            ...rest,
          }))}
        />
      ) : null}
      {!includedItems && onIncludedItemsClick ? (
        <IncludedItemsButton onClick={onIncludedItemsClick}>
          <IncludedItemsButtonText>
            What&apos;s included
          </IncludedItemsButtonText>
          <img alt="Chevron" src={icons.checkout_chevron_right} />
        </IncludedItemsButton>
      ) : null}
      <StarsContainer includedItems={!!(includedItems || onIncludedItemsClick)}>
        <Stars src={icons.checkout_stars} alt="Stars" />
        <ReviewsCount>{reviewsCount} Reviews</ReviewsCount>
      </StarsContainer>
      <ButtonWrapper>
        <DefaultButton
          type="button"
          fullWidth={true}
          backgroundColor={colors.green}
          onClick={() => onBuyClick({ id, plan: '1_month' })}
        >
          <ButtonContent>
            <ButtonName>ONE TIME</ButtonName>
            <span>${oneTimePlan.monthlyPrice.toFixed(2)}</span>
          </ButtonContent>
        </DefaultButton>
      </ButtonWrapper>
      <DefaultButton
        type="button"
        fullWidth={true}
        backgroundColor={colors.orange}
        onClick={() => onBuyClick({ id, plan: '6_months' })}
      >
        <ButtonContent>
          <ButtonName>MONTHLY SUBSCRIPTION</ButtonName>
          <ButtonPriceContainer>
            <span>${monthlySubscriptionPlan.monthlyPrice.toFixed(2)}</span>
            <ButtonSaveText>Save 35%</ButtonSaveText>
          </ButtonPriceContainer>
        </ButtonContent>
      </DefaultButton>
      <DisclaimerItem marginTop="20px">
        <img src={icons.checkout_replay} alt="Replay" />
        <DisclaimerText>
          We’ll remind you three days before your trial ends.
        </DisclaimerText>
      </DisclaimerItem>
      <DisclaimerItem marginTop="8px">
        <img src={icons.checkout_lock} alt="Lock" />
        <DisclaimerText>No commitments, cancel anytime.</DisclaimerText>
      </DisclaimerItem>
    </Container>
  );
};

RitualBundleItemV3.propTypes = {
  daughtersFruit: PropTypes.oneOf(['avocado', 'acai_berry']).isRequired,
  icon: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  includedItems: PropTypes.arrayOf(PropTypes.string),
  momsFruit: PropTypes.oneOf(['avocado', 'acai_berry']).isRequired,
  monthlySubscriptionPlan: PropTypes.shape({
    id: PropTypes.string,
    monthlyPrice: PropTypes.number,
  }).isRequired,
  onBuyClick: PropTypes.func,
  oneTimePlan: PropTypes.shape({
    id: PropTypes.string,
    monthlyPrice: PropTypes.number,
  }).isRequired,
  onIncludedItemsClick: PropTypes.func,
  reviewsCount: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

RitualBundleItemV3.defaultProps = {
  includedItems: null,
  onBuyClick: null,
  onIncludedItemsClick: null,
};

const Container = styled.div`
  max-width: 327px;
  width: 100%;
  position: relative;

  @media ${mobile} {
    margin-bottom: 30px;
  }
`;
const Header = styled.div`
  &.clickable {
    cursor: pointer;
  }
`;
const Image = styled.img`
  display: block;
  width: 100%;
  height: 244px;
  border-radius: 10px;
`;
const Title = styled(Regular)`
  max-width: 244px;
  width: 100%;
  margin-top: 17px;
  margin-bottom: ${props => props.marginBottom || '19px'};
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: ${colors.black};
`;
const Stars = styled.img`
  display: block;
`;
const StarsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
  margin-top: ${props => (props.includedItems ? '19px' : '0px')};
`;
const ReviewsCount = styled(Regular)`
  padding-top: 3px;
  padding-left: 10px;
  font-size: 18px;
  line-height: 19px;
  font-weight: normal;
  color: #818181;
`;
const ButtonWrapper = styled.div`
  margin-bottom: 10px;
`;
const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 264px;
  width: 100%;
  margin: 0 auto;
`;
const ButtonName = styled.span`
  max-width: 121px;
  width: 100%;
  text-align: left;

  @media ${xsmMobile} {
    max-width: 105px;
    font-size: 14px;
  }
`;
const ButtonPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media ${xsmMobile} {
    padding-bottom: 3px;
  }
`;
const ButtonSaveText = styled(Regular)`
  margin-right: -2px;
  font-size: 17px;
  line-height: 25px;
  font-weight: normal;
  text-align: right;
  text-transform: uppercase;
  color: ${colors.white};

  @media ${xsmMobile} {
    font-size: 15px;
  }
`;
const DisclaimerItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: ${props => props.marginTop};
`;
const DisclaimerText = styled(Regular)`
  padding-left: 12px;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  color: ${colors.blackAlpha(0.8)};
`;
const IncludedItemsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
`;
const IncludedItemsButtonText = styled(Regular)`
  font-size: 18px;
  font-weight: 600;
  color: ${colors.blackAlpha(0.8)};
`;
