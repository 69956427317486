import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors } from '../../../styles/colors';
import { mobile } from '../../../styles/breakpoints';
import { DefaultButton } from '../..';
import { InfoListQuestion } from './InfoListQuestion';
import { InfoRatedQuestion } from './InfoRatedQuestion';
import { InfoDetailedQuestion } from './InfoDetailedQuestion';

const questionComponents = {
  list: InfoListQuestion,
  rated: InfoRatedQuestion,
  detailed: InfoDetailedQuestion,
};

export const InfoQuestion = ({ question, onSelect }) => {
  const { custom } = question;
  const QuestionComponent = questionComponents[custom?.kind];

  if (!QuestionComponent) {
    return null;
  }

  return (
    <Container>
      <QuestionComponent question={question} />
      <ButtonWrapper>
        <DefaultButton
          id="submitBtn"
          label="Next"
          fullWidth={true}
          onClick={onSelect}
        />
      </ButtonWrapper>
    </Container>
  );
};

InfoQuestion.propTypes = {
  onSelect: PropTypes.func.isRequired,
  question: PropTypes.shape({
    custom: PropTypes.shape({
      kind: PropTypes.string,
    }),
  }).isRequired,
};

const Container = styled.div`
  position: relative;
`;
const ButtonWrapper = styled.div`
  max-width: 375px;
  width: 100%;
  margin: 0 auto;

  @media ${mobile} {
    position: fixed;
    max-width: initial;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px 24px;
    border-top: 1px solid #ebebeb;
    background-color: ${colors.white};
  }
`;
