import { constants } from '../constants';

export const INITIAL_STATE = {
  variant: null,
};

export const appReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.app.SET_VARIANT:
      const { variant } = action;

      return { ...state, variant: variant ? parseInt(variant) : 1 };
    default:
      return state;
  }
};
