import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { H1, H2 } from '..';

export const Header = ({ title, description }) => (
  <HeaderWrapper>
    <H1>{title}</H1>
    {description && <H2 margin="1rem 0 0 0">{description}</H2>}
  </HeaderWrapper>
);

Header.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};

const HeaderWrapper = styled.div`
  padding: 3.5rem 1rem;
  text-align: center;
`;
