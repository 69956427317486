export const colors = {
  black: '#222222',
  blackAlpha: (alpha = 1) => `rgba(34, 34, 34, ${alpha})`,
  grey: '#4E4E4E',
  greyAlpha: (alpha = 1) => `rgba(210, 200, 224, ${alpha})`,
  white: '#FFFFFF',
  whiteAlpha: (alpha = 1) => `rgba(255, 255, 255, ${alpha})`,
  almostWhite: '#F8F9F8',
  lightRed: '#F6F4F9',
  lightRoseGold: '#F9E9EA',
  roseGold: '#F8C7CA',
  lightYellow: '#F9DD7D',
  lemon: '#BBEFD9',
  purple: '#643288',
  lightPurple: '#F08A90',
  darkPurple: '#432B4B',
  green: '#04AA80',
  greenLighter: '#05AB80',
  foggyGreen: '#99D1C3',
  lightGreen: '#7EBA34',
  yellow: '#E6BC28',
  error: '#E04C55',
  errorAlpha: (alpha = 1) => `rgba(224, 76, 85, ${alpha})`,
  primary: '#D88489',
  orange: '#E08446',
  background: '#F5F5F5',
  gradient: 'linear-gradient(90deg, #04AA80 -2.31%, #9ED25E 100%)',
  paypalGold: '#FFC439',
};
