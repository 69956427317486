import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useSelector } from 'react-redux';

import { isRoute, shouldShowBackArrow } from '../../utils/navigation';
import { Menu, NavWrapper } from '..';
import { smDesktop } from '../../styles/breakpoints';
import { colors } from '../../styles/colors';
import { icons } from '../../utils/icons';

const isSummaryRoute = isRoute('summary');
const isQuizRoute = isRoute('(quiz|calculating|email|success)');
const isCheckoutRoute = isRoute('checkout|checkout2');

export const Navigation = () => {
  const { listen, location, push } = useHistory();
  const [isOpen, setOpen] = useState(false);
  const [isQuiz, setIsQuz] = useState(isQuizRoute(location));
  const [isCheckout, setIsCheckout] = useState(isCheckoutRoute(location));
  const [showBack, setShowBack] = useState(shouldShowBackArrow(location));
  const variant = useSelector(({ app }) => app.variant);
  const menuIcon = isOpen ? icons['close'] : icons['menu'];
  const homeUrl = variant === 2 ? '/v2' : '/';

  listen(l => {
    if (location.pathname !== l.pathname) {
      window.scrollTo(0, 0);
      setIsQuz(isQuizRoute(l));
      setIsCheckout(isCheckoutRoute(l));
      setShowBack(shouldShowBackArrow(l));
    }
  });

  if (isQuiz) {
    return null;
  }

  return isSummaryRoute(location) ? null : (
    <>
      <Nav>
        <NavWrapper
          itemScope
          itemProp="author"
          itemType="http://schema.org/Organization"
        >
          <meta itemProp="name" content="B.Moment" />
          <meta itemProp="url" content="https://bmoment.co" />
          <ContentContainer className={isCheckout ? 'centered' : null}>
            <RowContainer>
              {showBack ? (
                <Link to={homeUrl} onClick={() => setOpen(false)}>
                  <Back src={icons.back} alt="Back" />
                </Link>
              ) : null}
              <ImageContainer
                className="centered"
                onClick={() => push(homeUrl)}
              >
                <Logo
                  id="quiz-logo"
                  itemProp="logo"
                  src={icons.logo}
                  alt="Logo"
                />
              </ImageContainer>
            </RowContainer>
            {isCheckout ? null : (
              <>
                <RowContainer>
                  <StyledHashLink smooth to={`${homeUrl}#magical-moments`}>
                    Magical moments
                  </StyledHashLink>
                  <StyledHashLink smooth to={`${homeUrl}#face-masks`}>
                    Face masks
                  </StyledHashLink>
                  <StyledHashLink smooth to={`${homeUrl}#ingredients`}>
                    Ingredients
                  </StyledHashLink>
                  <StyledHashLink smooth to={`${homeUrl}#our-promise`}>
                    Our promise
                  </StyledHashLink>
                  <StyledLinkButton
                    id="quizBtn1"
                    to={variant === 2 ? 'checkout2' : '/quiz-intro'}
                  >
                    {variant === 2 ? 'See the product' : 'Design your ritual'}
                  </StyledLinkButton>
                </RowContainer>
                <Icon
                  id="menuBtn"
                  onClick={() => setOpen(!isOpen)}
                  src={menuIcon}
                  alt=""
                />
              </>
            )}
          </ContentContainer>
        </NavWrapper>
      </Nav>
      <Menu
        isOpen={isOpen}
        variant={variant}
        closeMenu={() => setOpen(false)}
      />
    </>
  );
};

const Nav = styled.nav`
  position: relative;
  padding: 1.5rem 0;
  background: ${colors.white};
  border-bottom: ${colors.lightRed} 1px solid;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  z-index: 3;

  @media ${smDesktop} {
    box-shadow: none;
    border-bottom: 0;
    padding: 1.5rem 24px;
  }
`;
const Logo = styled.img`
  height: 3rem;
  vertical-align: middle;
`;
const Back = styled.img`
  margin-right: 1.5rem;
  vertical-align: middle;
`;
const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &.centered {
    justify-content: center;
  }
`;
const ImageContainer = styled.div`
  cursor: pointer;

  @media ${smDesktop} {
    flex: 1;
  }
`;
const Icon = styled.img`
  display: none;
  z-index: 3;
  cursor: pointer;

  @media ${smDesktop} {
    display: block;
  }
`;
const StyledHashLink = styled(HashLink)`
  margin-right: 46px;
  font-size: 18px;
  color: ${colors.black};
  text-decoration: none;

  &:last-of-type {
    margin-right: 0;
  }

  @media ${smDesktop} {
    display: none;
  }
`;
const StyledLinkButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 236px;
  height: 54px;
  color: ${colors.white};
  border-radius: 30px;
  font-size: 17px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  background: ${colors.gradient};

  @media ${smDesktop} {
    display: none;
  }
`;
const RowContainer = styled.div`
  display: flex;
  align-items: center;
`;
