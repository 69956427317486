import React from 'react';
import styled from 'styled-components';
import { Caption, H1, Regular } from '..';
import { colors } from '../../styles/colors';
import { mobile, smTablet } from '../../styles/breakpoints';
import { icons } from '../../utils/icons';

export const Testimonials = () => (
  <>
    <H1 className="centered" margin="0 0 1rem 0">
      Hear success stories from our clients
    </H1>
    <Regular textAlign="center" margin="0 0 3.5rem 0">
      Nothing inspires more than seeing other people succeed, right? So, read
      these amazing stories and get ready to start your own. A few months from
      now you could be one of them!
    </Regular>
    <GridBlock>
      <Grid className="main">
        <Item>
          <Img bg={icons['photo_1']} />
          <Content>
            <Grid>
              <Caption className="bolder" color={colors.primary}>
                160 lb
              </Caption>
              <Icon src={icons['arrows']} alt="" />
              <Caption className="bolder" color={colors.primary}>
                140 lb
              </Caption>
            </Grid>
            <Regular margin="1rem 0 0 0">
              Always struggling with extra weight made my life torture. I would
              try, then fail, then try again, and fail again. That was until my
              birthday, when my sister showed this weight loss plan. Of course,
              it wasn&apos;t easy at first, but seeing the results was the best
              thing I could ask for.
            </Regular>
          </Content>
        </Item>
        <Item>
          <Img bg={icons['photo_2']} />
          <Content>
            <Grid>
              <Caption className="bolder" color={colors.primary}>
                160 lb
              </Caption>
              <Icon src={icons['arrows']} alt="" />
              <Caption className="bolder" color={colors.primary}>
                135 lb
              </Caption>
            </Grid>
            <Regular margin="1rem 0 0 0">
              After I had my newborn, I gained about 25 pounds of baby weight
              and I just couldn&apos;t lose it because it would always come back
              after a month or so. My friend recommended me this weight loss
              plan and guess what? I said goodbye to my baby weight for good.
            </Regular>
          </Content>
        </Item>
        <Item>
          <Img bg={icons['photo_3']} />
          <Content>
            <Grid>
              <Caption className="bolder" color={colors.primary}>
                167 lb
              </Caption>
              <Icon src={icons['arrows']} alt="" />
              <Caption className="bolder" color={colors.primary}>
                147 lb
              </Caption>
            </Grid>
            <Regular margin="1rem 0 0 0">
              It’s a game-changer for anyone trying to lose weight. I eat
              delicious meals and manage to lose more weight than with any other
              diet. I wish I knew about it earlier. It really makes sense when
              you apply it to everyday life.
            </Regular>
          </Content>
        </Item>
      </Grid>
    </GridBlock>
  </>
);

const GridBlock = styled.div`
  display: block;
  padding-left: 4%;
  margin-left: -4%;
  margin-right: -4%;
  overflow: scroll;

  @media ${mobile} {
    padding-left: 2%;
    margin-left: -2%;
    margin-right: -2%;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1rem;

  &.main {
    @media ${smTablet} {
      width: 840px;
      padding-right: 4%;
    }

    @media ${mobile} {
      padding-right: 2%;
    }
  }
`;

const Item = styled.div`
  position: relative;
  background: ${colors.white};
  border-radius: 6px;
  overflow: hidden;
`;

const Img = styled.div`
  padding-bottom: 100%;
  background: url(${props => props.bg});
  background-position: center;
  background-size: cover;
`;

const Content = styled.div`
  padding: 1.5rem 1rem;
`;

const Icon = styled.img`
  width: 70%;
`;
