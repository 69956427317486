import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { DefaultButton, H1, Regular } from '../../components';
import { icons } from '../../utils/icons';
import { colors } from '../../styles/colors';
import { tablet } from '../../styles/breakpoints';

export const StartYourRitual = ({ variant }) => {
  const { push } = useHistory();

  return (
    <Container>
      <Wrapper>
        <LeftContainer>
          <Heading>
            {variant === 1
              ? 'Start your ritual'
              : 'Get 1st ritual for free, design your ritual now'}
          </Heading>
          <Description>
            {variant === 1
              ? 'Take our short quiz to get started building a personalized face ritual for you and your daughter. It\'s never been so easy to create and share those B.Moments together!'
              : 'Spend 60 seconds and build a personalized face ritual for you and your daughter. We give the first ritual for free, so don’t waste your time and create your ritual now. It’s never been so easy to create and share those B.Moments together!'}
          </Description>
          <ButtonWrapper>
            <DefaultButton
              type="button"
              id="quizBtn3"
              fullWidth={true}
              label={variant === 1 ? 'DESIGN YOUR RITUAL' : 'SEE THE PRODUCT'}
              onClick={() => push(variant === 1 ? '/quiz-intro' : '/checkout2')}
            />
          </ButtonWrapper>
          <Leaves1 src={icons.home_leaves_6} alt="leaves" />
          <Leaves2 src={icons.home_leaves_7} alt="leaves" />
        </LeftContainer>
        <ImageContainer>
          <Image
            src={
              icons[variant === 1 ? 'home_mom_daughter_2' : 'home_first_ritual']
            }
            alt="Mom and daughter"
          />
          {variant === 2 ? (
            <GiftContainer>
              <GiftImage src={icons.checkout_gift} alt="gift" />
              <GiftText>Free gift</GiftText>
            </GiftContainer>
          ) : null}
        </ImageContainer>
        <Leaves3
          src={icons[`home_leaves_${variant === 1 ? 8 : 9}`]}
          alt="leaves"
        />
      </Wrapper>
    </Container>
  );
};

StartYourRitual.propTypes = {
  variant: PropTypes.oneOf([1, 2]),
};

StartYourRitual.defaultProps = {
  variant: 1,
};

const Container = styled.div`
  padding: 0 24px;
  background-color: ${colors.lightRoseGold};

  @media ${tablet} {
    padding: 0;
  }
`;
const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 24px;

  @media ${tablet} {
    flex-direction: column-reverse;
  }
`;
const Heading = styled(H1)`
  @media ${tablet} {
    padding-top: 23px;
    font-size: 36px;
    line-height: 34px;
  }
`;
const LeftContainer = styled.div`
  position: relative;
  max-width: 515px;
  width: 100%;
  text-align: left;
`;
const Description = styled(Regular)`
  padding-top: 21px;
  padding-bottom: 28px;
  font-size: 18px;
  font-weight: normal;
  color: ${colors.blackAlpha(0.8)};

  @media ${tablet} {
    padding-top: 14px;
    padding-bottom: 19px;
  }
`;
const ButtonWrapper = styled.div`
  max-width: 270px;
  width: 100%;

  @media ${tablet} {
    margin: 0 auto;
  }
`;
const Leaves1 = styled.img`
  position: absolute;
  top: -45px;
  right: 80px;

  @media ${tablet} {
    right: -20px;
  }
`;
const Leaves2 = styled.img`
  position: absolute;
  bottom: -75px;
  left: -70px;

  @media ${tablet} {
    display: none;
  }
`;
const Leaves3 = styled.img`
  position: absolute;
  right: -70px;
  bottom: 0;

  @media ${tablet} {
    width: 90px;
    bottom: unset;
    right: unset;
    top: 0;
    left: 0;
  }
`;
const ImageContainer = styled.div`
  position: relative;
  max-width: 581px;
  width: 100%;
`;
const Image = styled.img`
  width: 100%;
`;
const GiftContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 14px;
  transform: translateY(-50%);
  border-radius: 7px;
  background-color: ${colors.error};
`;
const GiftImage = styled.img`
  padding-bottom: 2px;
`;
const GiftText = styled(Regular)`
  margin-left: 5px;
  font-weight: 600;
  font-size: 15px;
  line-height: 34px;
  color: ${colors.white};
  text-transform: uppercase;
`;
