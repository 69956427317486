import React, { useEffect, useState } from 'react';
import values from 'lodash/fp/values';
import flatten from 'lodash/fp/flatten';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { Analytics } from '../apis/Analytics';
import { colors } from '../styles/colors';
import { mobile } from '../styles/breakpoints';
import { H2, ProgressBar, Regular, Seo } from '../components';
import { AskQuestion } from '../components/questions/AskQuestion';
import { actions } from '../state/actions';
import { icons } from '../utils/icons';

export const Quiz = ({ location, match }) => {
  const step = parseInt(match.params.step);
  const isDaughtersQuiz = location.pathname.includes('daughters-quiz');
  const { push, goBack, replace } = useHistory();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const userInfo = useSelector(({ funnel }) => funnel.userInfo);
  const daughtersCount = parseInt(userInfo.daughters, 10);
  const questions = useSelector(({ funnel }) => {
    if (isDaughtersQuiz) {
      let result = [
        ...funnel.daughtersEditionQuestions.filter(
          ({ type }) => type === 'daughters_data',
        ),
      ];

      for (let i = 0; i < daughtersCount; i += 1) {
        const questions = funnel.daughtersEditionQuestions
          .filter(
            ({ type, key }) =>
              type !== 'daughters_data' && key !== 'skin_rituals_frequency',
          )
          .map(({ key, label, ...rest }) => {
            const { daughtersData } = funnel.userInfo;
            const daughterName = daughtersData
              ? daughtersData[`daughter${i + 1}_name`]
              : null;

            return {
              ...rest,
              key: `daughter${i + 1}_${key}`,
              label: daughterName
                ? label.replace('%name%', daughterName)
                : label,
            };
          });

        result.push(questions);
      }

      return [
        ...flatten(result),
        ...funnel.daughtersEditionQuestions.filter(
          ({ key }) => key === 'skin_rituals_frequency',
        ),
      ];
    }

    return funnel.questions;
  });
  const updateUser = value => dispatch(actions.funnel.updateUserInfo(value));
  const updateUserDaughters = value =>
    dispatch(actions.funnel.updateUserDaughtersInfo(value));
  const question = questions[step];
  const handleBackButton = () => {
    if (step > 0) {
      goBack();
      window.scrollTo(0, 0);
    } else {
      push('/');
    }
  };
  const isValid = value => {
    const val = values(value)?.[0];

    if (typeof val === 'string') {
      return val.length > 0;
    }

    return typeof val === 'boolean' || typeof val === 'number';
  };
  const getLeavesIcon = () => {
    const currentStep = step + 1;

    if (currentStep % 3 === 0) {
      return icons.quiz_leaves_3;
    } else if (currentStep % 2 === 0) {
      return icons.quiz_leaves_2;
    }

    return icons.quiz_leaves_1;
  };
  const getCurrentVisibleStep = () => {
    let seenInfoQuestionsCount = 0;

    for (let i = 0; i < questions.length; i += 1) {
      if (i > step) {
        break;
      }

      if (questions[i].type === 'info') {
        seenInfoQuestionsCount += 1;
      }
    }

    return step + 1 - seenInfoQuestionsCount;
  };
  const onSelect = value => {
    const isInfoQuestion = questions[step].type === 'info';

    if (!isInfoQuestion) {
      Analytics.trackQuizAnswer(question['label'], value['label']);
      delete value['label'];
    }

    if (isValid(value) || isInfoQuestion) {
      if (!isInfoQuestion) {
        if (isDaughtersQuiz) {
          updateUserDaughters(value);
        } else {
          updateUser(value);
        }
      }

      if (step + 1 < questions.length) {
        push(`/${isDaughtersQuiz ? 'daughters-quiz' : 'quiz'}/${step + 1}`);
        window.scrollTo(0, 0);
      } else {
        if (isDaughtersQuiz) {
          push('/calculating');
        } else {
          push('/daughters-quiz-intro');
        }
      }
    }
  };

  const getSelectableQuestionAnswer = () => {
    const { key } = question;

    if (isDaughtersQuiz && userInfo.daughtersData) {
      return userInfo.daughtersData[key];
    }

    return userInfo[key];
  };

  useEffect(() => {
    if (!questions?.length) {
      push('/');
    } else {
      if (Number.isNaN(step) || step > questions.length - 1) {
        replace(`/${isDaughtersQuiz ? 'daughters-quiz/0' : 'quiz/0'}`);
      }

      setLoaded(true);
    }
  }, []);

  if (!loaded) {
    return null;
  }

  return (
    <>
      <Seo tile="Bmoment - Facial rituals for mother & daughter" />
      <Background>
        <Container>
          <Wrapper>
            <Header>
              <Icon
                className={step === 0 ? 'hidden' : null}
                onClick={handleBackButton}
                src={icons.back}
              />
              <img src={icons.logo} alt="Logo" />
              <Regular
                color={colors.blackAlpha(0.6)}
                textAlign="center"
                margin="0 0 1rem 0"
              >
                <strong>{getCurrentVisibleStep()}</strong> of{' '}
                {questions.filter(({ type }) => type !== 'info').length}
              </Regular>
            </Header>
            <ProgressBar step={step + 1} totalSteps={questions.length} />
            <LabelContainer>
              {question.type === 'info' ? null : (
                <>
                  <Leaves src={getLeavesIcon()} alt="Leaves" />
                  {question.label ? (
                    <Label className="fadeIn" margin="1rem 0 2rem 0">
                      {question.label}
                    </Label>
                  ) : null}
                </>
              )}
              <AskQuestion
                answer={getSelectableQuestionAnswer()}
                question={question}
                onSelect={onSelect}
              />
            </LabelContainer>
          </Wrapper>
        </Container>
      </Background>
    </>
  );
};

Quiz.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      step: PropTypes.string,
    }),
  }).isRequired,
};

const Background = styled.div`
  padding: 4.5rem 0;
  min-height: 100vh;
  background-color: ${colors.background};

  @media ${mobile} {
    min-height: 100vh;
    padding: 0;
    background-color: ${colors.white};
  }
`;
const Container = styled.div`
  max-width: 860px;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
  border-radius: 40px;
  background: ${colors.white};

  @media ${mobile} {
    padding: 0;
  }
`;
const Wrapper = styled.div`
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  padding-top: 38px;
  padding-bottom: 66px;
  text-align: center;

  @media ${mobile} {
    padding-top: 20px;
  }
`;
const Icon = styled.img`
  display: block;
  padding: 18px 18px 18px 0;
  text-align: left;
  cursor: pointer;

  &.hidden {
    visibility: hidden;
  }

  @media ${mobile} {
    padding: 1rem;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 4px;

  @media ${mobile} {
    padding: 0 18px 4px 0;
  }
`;
const Label = styled(H2)`
  width: calc(100% - 100px);
  font-size: 28px;
  font-weight: 600;
  text-align: left;

  @media ${mobile} {
    font-size: 22px;
    line-height: 26px;
    width: calc(100% - 40px);
  }
`;
const LabelContainer = styled.div`
  position: relative;
  padding-top: 20px;

  @media ${mobile} {
    padding: 34px 24px 0;
  }
`;
const Leaves = styled.img`
  position: absolute;
  top: 10px;
  right: 0;
  width: 78px;

  @media ${mobile} {
    top: 10px;
    right: 1px;
  }
`;
