import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect, { components } from 'react-select';

import { colors } from '../../styles/colors';

const CustomOption = props => <components.Option {...props} />;

export const Select = ({ error, options, onChange }) => (
  <ReactSelect
    options={options}
    components={{ Option: CustomOption }}
    onChange={onChange}
    styles={{
      container: provided => ({
        ...provided,
        marginTop: 16,
      }),
      control: provided => ({
        ...provided,
        outline: 'none',
        paddingLeft: 8,
        paddingRight: 8,
        height: 46,
        boxShadow: null,
        fontSize: 18,
        borderColor: error ? colors.error : '#E0E0E0',
        '&:hover': null,
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: provided => ({
        ...provided,
        color: colors.blackAlpha(0.8),
      }),
    }}
  />
);

Select.propTypes = {
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
};

Select.defaultProps = {
  error: null,
};
