import styled from 'styled-components';

import { mobile } from '../../styles/breakpoints';
import { colors } from '../../styles/colors';

export const H1 = styled.h1`
  padding: ${props => props.padding || '0px'};
  margin: ${props => props.margin || '0px'};
  font-size: 50px;
  font-weight: 700;
  color: ${props => props.color || colors.black};

  &.centered {
    text-align: center;
  }

  @media ${mobile} {
    font-size: 42px;
  }
`;
