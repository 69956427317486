import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import keys from 'lodash/fp/keys';

import { colors } from '../../styles/colors';
import {
  MagicalMoments,
  OurPromise,
  Seo,
  SummaryHeader,
} from '../../components';
import { TestResults } from './TestResults';
import { EnjoyTheEffects } from './EnjoyTheEffects';

export const Summary = () => {
  const { push } = useHistory();
  const [loaded, setLoaded] = useState(false);
  const { userInfo, questions, daughtersEditionQuestions } = useSelector(
    ({ funnel }) => funnel,
  );

  useEffect(() => {
    if (
      !questions?.length ||
      !daughtersEditionQuestions?.length ||
      keys(userInfo).length < questions?.length
    ) {
      push('/');
    } else {
      setLoaded(true);
    }
  }, []);

  if (!loaded) {
    return null;
  }

  return (
    <>
      <Seo title="Bmoment - Facial rituals for mother & daughter" />
      <SummaryHeader />
      <TestResults />
      <EnjoyTheEffects />
      <MagicalMoments backgroundColor={colors.white} />
      <OurPromise buttonLinkTo="/email" buttonText="SEE THE PRODUCT" />
    </>
  );
};
