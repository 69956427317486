export const SUBSCRIPTION_PLANS = {
  regular: [
    {
      id: '1_month',
      title: 'Monthly supply',
      ritualsCount: 4,
      monthlyPrice: 55,
      totalPrice: 55,
      billedMonths: 1,
      isGift: true,
    },
    {
      id: '3_months',
      title: '3 Months supply',
      discount: 20,
      ritualsCount: 12,
      monthlyPrice: 45,
      totalPrice: 165,
      totalPriceDiscounted: 134,
      billedMonths: 3,
    },
    {
      id: '6_months',
      title: '6 Months supply',
      discount: 35,
      ritualsCount: 24,
      monthlyPrice: 35,
      totalPrice: 330,
      totalPriceDiscounted: 210,
      isMostPopular: true,
      billedMonths: 6,
    },
  ],
  maxi: [
    {
      id: '1_month',
      title: 'Monthly supply',
      ritualsCount: 4,
      monthlyPrice: 84,
      totalPrice: 84,
      billedMonths: 1,
      isGift: true,
    },
    {
      id: '3_months',
      title: '3 Months supply',
      discount: 20,
      ritualsCount: 12,
      monthlyPrice: 67,
      totalPrice: 252,
      totalPriceDiscounted: 201,
      billedMonths: 3,
    },
    {
      id: '6_months',
      title: '6 Months supply',
      discount: 35,
      ritualsCount: 24,
      monthlyPrice: 53,
      totalPrice: 504,
      totalPriceDiscounted: 318,
      isMostPopular: true,
      billedMonths: 6,
    },
  ],
};

export const BUNDLES = [
  {
    id: 'regular',
    title: 'Regular',
    includedItems: [
      {
        name: '1 x Face Mask with %moms_fruit% Extract for Mom.',
        effect: 'Effect: Hydrated, plump skin.',
        image: 'big_mask',
        productFor: 'mom',
      },
      {
        name: '1 x Face Mask with %daughters_fruit% for Daughter.',
        effect: 'Effect: Nourished and soft skin.',
        image: 'big_mask',
        productFor: 'daughter',
      },
      {
        name: '2 x Soft brushes',
        image: 'brushes',
      },
      {
        name: '2 x Face sponges',
        image: 'sponge',
      },
    ],
    includedItemsAvocadoKit: [
      '2 x Face masks with Avocado oil',
      '2 x Soft brushes',
      '2 x Face sponges',
    ],
    includedItemsAcaiKit: [
      '2 x Face masks with Acai berry extract ',
      '2 x Soft brushes',
      '2 x Face sponges',
    ],
    reviewsCount: {
      acai: 1585,
      avocado: 1528,
    },
  },
  {
    id: 'maxi',
    title: 'Maxi',
    includedItems: [
      {
        name: '1 x Face Mask with %moms_fruit% Extract for Mom.',
        effect: 'Effect: Hydrated, plump skin',
        image: 'big_mask',
        productFor: 'mom',
      },
      {
        name: '1 x Face Mask with %daughters_fruit% for Daughter.',
        effect: 'Effect: Nourished and soft skin.',
        image: 'big_mask',
        productFor: 'daughter',
      },
      {
        name: '2 x Face Tonics.',
        effect: 'Effect: balanced pH of the skin.',
        image: 'tonic',
      },
      {
        name: '2 x Moisturizing Face Creams.',
        effect: 'Effect: Intensive 24hour moisturization.',
        image: 'cream',
      },
      {
        name: '1 x Serum.',
        effect: 'Effect: Multi layer hydration.',
        image: 'serum',
      },
      {
        name: '1 x Micellar water.',
        effect: 'Effect: Removed excess oil, impurities.',
        image: 'mic_water',
      },
      {
        name: '1 x Scrub.',
        effect: 'Effect: Removed dead skin cells.',
        image: 'scrub',
      },
      {
        name: '2 x Soft brushes',
        image: 'brushes',
      },
      {
        name: '2 x Face sponges',
        image: 'sponge',
      },
    ],
    includedItemsAvocadoKit: [
      '2 x Face masks with Avocado oil',
      '2 x Tonics',
      '2 x Moisturizing creams',
      '1 x Serum',
      '1 x Micellar water for mom',
      '1 x Scrub for mom',
      '2 x Soft brushes',
      '2 x Face sponges',
    ],
    includedItemsAcaiKit: [
      '2 x Face masks with Acai berry extract',
      '2 x Tonics',
      '2 x Moisturizing creams',
      '1 x Serum',
      '1 x Micellar water for mom',
      '1 x Scrub for mom',
      '2 x Soft brushes',
      '2 x Face sponges',
    ],
    reviewsCount: {
      acai: 1811,
      avocado: 1723,
    },
  },
];
