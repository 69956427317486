import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { icons } from '../../../utils/icons';
import { colors } from '../../../styles/colors';
import { Regular } from '../../text/Regular';

export const InfoDetailedQuestion = ({ question }) => {
  const { custom, label } = question;

  return (
    <Container>
      <img src={icons[`quiz_${custom.icon}`]} alt={custom.icon} />
      <Label>{label}</Label>
      <Description>{custom.text}</Description>
    </Container>
  );
};

InfoDetailedQuestion.propTypes = {
  question: PropTypes.shape({
    custom: PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.string,
    }),
    label: PropTypes.string,
  }).isRequired,
};

const Container = styled.div`
  padding-top: 14px;
  padding-bottom: 32px;
`;
const Label = styled(Regular)`
  padding-top: 11px;
  padding-bottom: 7px;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  text-align: center;
  color: ${colors.black};
`;
const Description = styled(Regular)`
  font-size: 18px;
  font-weight: normal;
  line-height: 26px;
  text-align: center;
  color: ${colors.blackAlpha(0.8)};
`;
