import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { icons } from '../../utils/icons';
import { colors } from '../../styles/colors';
import { tablet } from '../../styles/breakpoints';
import { H2, Regular } from '../../components';

const FaqItem = ({ title, body, isOpen, isLast, onToggle }) => (
  <FaqItemContainer className={isLast ? 'no-border' : null} onClick={onToggle}>
    <FaqItemTitleContainer>
      <FaqItemTitle className={isOpen ? 'open' : null}>{title}</FaqItemTitle>
      <img src={icons[`checkout_${isOpen ? 'minus' : 'plus'}`]} alt="Symbol" />
    </FaqItemTitleContainer>
    <FaqItemBody className={isOpen ? 'open' : null}>{body}</FaqItemBody>
  </FaqItemContainer>
);

FaqItem.propTypes = {
  body: PropTypes.string.isRequired,
  isLast: PropTypes.bool,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

FaqItem.defaultProps = {
  isLast: false,
  isOpen: false,
};

export const Faq = ({ isVisibleOnTablet }) => {
  const [openItem, setOpenItem] = useState(null);

  return (
    <Container isVisibleOnTablet={isVisibleOnTablet}>
      <Wrapper>
        <Heading>Frequently asked questions</Heading>
        <FaqItems>
          <FaqItem
            isOpen={openItem === 0}
            title="Are the products safe for my daughter?"
            body="Yes, our products are 100% safe for children and adults alike."
            onToggle={() =>
              setOpenItem(openItem => (openItem === 0 ? null : 0))
            }
          />
          <FaqItem
            isOpen={openItem === 1}
            title="What are the products made of?"
            body="We use natural ingredients into our products, these include fruit oils, minerals, and vitamins with nutritional and hydrational properties."
            onToggle={() =>
              setOpenItem(openItem => (openItem === 1 ? null : 1))
            }
          />
          <FaqItem
            isOpen={openItem === 2}
            title="Is it easy to use the products?"
            body="Yes, the beauty rituals we build for you will come with easy to follow guides and tips. You will get detailed, step-by-step instructions on how to use your face masks correctly."
            onToggle={() =>
              setOpenItem(openItem => (openItem === 2 ? null : 2))
            }
          />
          <FaqItem
            isLast={true}
            isOpen={openItem === 3}
            title="When will I feel the effect of my face mask?"
            body="You will feel the instant effect of your face mask after your first ritual, just make sure that you follow the simple instructions that are included in your kit."
            onToggle={() =>
              setOpenItem(openItem => (openItem === 3 ? null : 3))
            }
          />
        </FaqItems>
      </Wrapper>
    </Container>
  );
};

Faq.propTypes = {
  isVisibleOnTablet: PropTypes.bool,
};

Faq.defaultProps = {
  isVisibleOnTablet: true,
};

const Container = styled.div`
  padding-top: 66px;
  padding-bottom: 68px;
  background-color: ${colors.almostWhite};

  @media ${tablet} {
    display: ${props => (props.isVisibleOnTablet ? 'block' : 'none')};
  }
`;
const Wrapper = styled.div`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
`;
const Heading = styled(H2)`
  padding-bottom: 48px;
  font-size: 42px;
  text-align: center;
`;
const FaqItems = styled.div`
  cursor: pointer;
  background-color: ${colors.white};
`;
const FaqItemContainer = styled.div`
  padding: 27px 40px;
  border-bottom: 1px solid #e8e8e8;

  &.no-border {
    border-bottom: 0;
  }
`;
const FaqItemTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const FaqItemTitle = styled(Regular)`
  font-size: 24px;
  font-weight: 600;
  color: #000000;

  &.open {
    color: ${colors.green};
  }
`;
const FaqItemBody = styled(Regular)`
  max-height: 0;
  transition: max-height 0.5s ease-out;
  overflow: hidden;
  font-size: 18px;
  color: ${colors.blackAlpha(0.8)};

  &.open {
    max-height: 130px;
    transition: max-height 0.5s ease-in;
  }
`;
