import { config } from '../config';
import Reactotron from 'reactotron-react-js';
import { reactotronRedux } from 'reactotron-redux';

const init = () => {
  if (config.ENV === 'development') {
    return Reactotron.configure().use(reactotronRedux()).connect();
  }
  return null;
};

export const reactotron = init();
