import React from 'react';
import styled from 'styled-components';

import { colors } from '../styles/colors';
import { mobile } from '../styles/breakpoints';

import { Caption, OlWrapper, Regular, Seo } from '../components';

export const RefundPolicy = () => (
  <>
    <Seo title="Terms and policies | B.Moment" />
    <Container>
      <Wrapper>
        <Heading>Refund policy</Heading>
        <Caption className="bolder" margin="0 0 1.5rem 0">
          100% MONEY-BACK GUARANTEE POLICY
        </Caption>
        <RegularText margin="0 0 0.5rem 0">
          You are eligible to receive a refund if you did not get visible
          results with our personalized meal plan and all of the following
          conditions are met:
        </RegularText>
        <OlWrapper margin="0 0 0.5rem 0">
          <Li>
            you contact us within 30 days after you purchased our personalized
            weight loss kit; and
          </Li>
          <Li>
            you have followed our personalized meal plan at least during 14
            consecutive days within the first 30 days after the purchase; and
          </Li>
          <Li>
            you are able to demonstrate that you have followed the personalized
            meal plan pursuant to the requirements stated below in Section “How
            to demonstrate that you have followed the plan”.
          </Li>
        </OlWrapper>
        <RegularText margin="0 0 2.5rem 0">
          We will review your application and notify you (by email) whether your
          application is approved. If the application is approved, your refund
          will be processed, and a credit will be automatically applied to your
          credit card or original method of payment, within 14 days.
        </RegularText>
        <Caption className="bolder" margin="0 0 1.5rem 0">
          How to demonstrate that you have followed the plan
        </Caption>
        <RegularText margin="0 0 0.5rem 0">
          You can demonstrate that you have followed the personalized meal plan
          only if:
        </RegularText>
        <OlWrapper margin="0 0 0.5rem 0">
          <Li>
            you provide photos or/and videos of meals, which you have
            prepared/ordered according to our personalized meal plan; and
          </Li>
          <Li>
            the meals were made according to the recipes provided by us in your
            personalized meal plan.
          </Li>
        </OlWrapper>
        <RegularText margin="0 0 2.5rem 0">
          The minimum number of meals depicted in your photos/videos should be
          equal to at least 19.
        </RegularText>
        <Caption className="bolder" margin="0 0 1.5rem 0">
          IMPORTANT STATEMENT
        </Caption>
        <RegularText margin="0 0 0.5rem 0">
          Please note that only fulfillment of the above requirements allows you
          to receive a complete refund under “Money-back guarantee”. For the
          sake of clarity, this “Money-back guarantee” does not apply to any
          other instances, including, but not limited to, the following cases:
        </RegularText>
        <OlWrapper margin="0 0 0.5rem 0">
          <Li>
            some of the ingredients from the personalized meal plan are not
            available in the place of your residence;
          </Li>
          <Li>
            you think that you do not (1) have enough time to cook the meals
            contained in the personalized meal plan, (2) possess sufficient
            skills to prepare some of the meals;
          </Li>
          <Li>
            you do not have some of the utensils or/and equipment required for
            the preparation of meals contained in the personalized meal plan.
          </Li>
        </OlWrapper>
        <RegularText>
          This Money Back Policy was posted on 28 February 2020. It is effective
          upon acceptance for new users and from 28 February 2020 for all other
          users. This Money Back Policy is incorporated into, and is subject to
          the Terms of Service unless otherwise provided for therein.
        </RegularText>
      </Wrapper>
    </Container>
  </>
);

const Container = styled.div`
  padding-top: 53px;
  padding-bottom: 55px;
  background-color: ${colors.lightRed};

  @media ${mobile} {
    padding: 29px 24px;
  }
`;
const Wrapper = styled.div`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
`;
const Heading = styled(Regular)`
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 13px;
  color: ${colors.black};
`;
const RegularText = styled(Regular)`
  font-size: 15px;
  color: ${colors.blackAlpha(0.8)};
  line-height: 23px;
`;
const Li = styled.li`
  font-size: 15px;
  color: ${colors.blackAlpha(0.8)};
  line-height: 23px;
`;
