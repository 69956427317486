import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { icons } from '../../../utils/icons';
import { colors } from '../../../styles/colors';
import { mobile } from '../../../styles/breakpoints';
import { CollapsableItems, Regular } from '../../../components';
import { ReactComponent as CheckmarkIcon } from '../../../assets/images/checkout/checkmark.svg';

export const BundleItem = ({
  id,
  title,
  image,
  momsFruit,
  daughtersFruit,
  includedItems,
  isSelected,
  onSelect,
  onIncludedItemsClick,
}) => {
  const momsFruitFormatted =
    momsFruit === 'avocado' ? 'Avocado oil' : 'Acai berry extract';
  const daughtersFruitFormatted =
    daughtersFruit === 'avocado' ? 'Avocado oil' : 'Acai berry extract';

  return (
    <Container>
      <Wrapper
        id={id}
        className={isSelected ? 'selected' : null}
        onClick={onSelect}
      >
        <TitleWrapper>
          <Circle className={isSelected ? 'selected' : null}>
            <CheckmarkIcon />
          </Circle>
          <Title className={isSelected ? 'selected' : null}>{title}</Title>
        </TitleWrapper>
        <PlanImage src={image} alt="Bundle plan" />
      </Wrapper>
      {includedItems ? (
        <CollapsableItems
          items={includedItems.map(({ name, ...rest }) => ({
            name: name
              .replace('%moms_fruit%', momsFruitFormatted)
              .replace('%daughters_fruit%', daughtersFruitFormatted),
            ...rest,
          }))}
        />
      ) : null}
      {!includedItems && onIncludedItemsClick ? (
        <IncludedItemsButton onClick={onIncludedItemsClick}>
          <IncludedItemsButtonText>
            What&apos;s included
          </IncludedItemsButtonText>
          <img alt="Chevron" src={icons.checkout_chevron_right} />
        </IncludedItemsButton>
      ) : null}
    </Container>
  );
};

BundleItem.propTypes = {
  daughtersFruit: PropTypes.oneOf(['avocado', 'acai_berry']).isRequired,
  id: PropTypes.string,
  image: PropTypes.string.isRequired,
  includedItems: PropTypes.arrayOf(PropTypes.string),
  isSelected: PropTypes.bool.isRequired,
  momsFruit: PropTypes.oneOf(['avocado', 'acai_berry']).isRequired,
  onIncludedItemsClick: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

BundleItem.defaultProps = {
  includedItems: null,
  onIncludedItemsClick: null,
};

const Container = styled.div`
  width: 327px;

  @media ${mobile} {
    margin-bottom: 30px;
  }
`;
const Wrapper = styled.div`
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #e0e0e0;

  &.selected {
    border: 2px solid ${colors.greenLighter};
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 17px;
  cursor: pointer;

  path {
    fill: ${colors.white};
  }
`;
const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  margin-right: 11px;
  border: 1px solid #e0e0e0;
  background-color: ${colors.white};

  &.selected {
    border: 0;
    background-color: ${colors.greenLighter};
  }
`;
const Title = styled(Regular)`
  font-size: 18px;
  font-weight: 600;
  color: ${colors.black};

  &.selected {
    color: ${colors.greenLighter};
  }
`;
const PlanImage = styled.img`
  width: 327px;
  height: 244px;
  vertical-align: bottom;
`;
const IncludedItemsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
`;
const IncludedItemsButtonText = styled(Regular)`
  font-size: 18px;
  font-weight: 600;
  color: ${colors.blackAlpha(0.8)};
`;
