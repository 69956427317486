import styled from 'styled-components';

import { colors } from '../../styles/colors';

export const Regular = styled.p`
  padding: ${props => props.padding || '0px'};
  margin: ${props => props.margin || '0px'};
  font-size: ${props => props.fontSize || '1rem'};
  font-weight: 400;
  color: ${props => props.color || colors.greyDark};
  text-align: ${props => props.textAlign || 'inherit'};

  &.bolder {
    font-weight: 700;
    color: ${props => props.color || colors.black};
  }
`;
