import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Api } from '../apis/api';
import { icons } from '../utils/icons';
import { colors } from '../styles/colors';
import { mobile } from '../styles/breakpoints';
import { actions } from '../state/actions';
import { Loader, Regular, Seo } from '../components';

export const Calculating = () => {
  let timeout;
  const { push } = useHistory();
  const dispatch = useDispatch();
  const userUpdated = useRef(false);
  const [loaded, setLoaded] = useState(false);
  const { userInfo, questions, daughtersEditionQuestions } = useSelector(
    ({ funnel }) => funnel,
  );
  const handleValueUpdated = value => {
    if (value === 100) {
      timeout = setTimeout(() => {
        if (userUpdated.current) {
          push('/summary');
        } else {
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleValueUpdated(100);
          }, 500);
        }
      }, 1000);
    }
  };
  const sendAnswers = async () => {
    try {
      const payload = { ...userInfo };

      delete payload.id;

      const response = await Api.sendAnswers(payload);

      userUpdated.current = true;
      dispatch(actions.funnel.updateUserInfo(response.data));
    } catch (error) {
      // TODO: maybe add more graceful method to display error msg?
      alert(error);
    }
  };

  useEffect(() => {
    if (!questions?.length || !daughtersEditionQuestions?.length) {
      push('/');
    } else {
      sendAnswers();
      setLoaded(true);
    }

    return () => clearTimeout(timeout);
  }, []);

  if (!loaded) {
    return null;
  }

  return (
    <>
      <Seo tile="Bmoment - Facial rituals for mother & daughter" />
      <Background>
        <Container>
          <Wrapper>
            <Logo src={icons.logo} alt="Logo" onClick={() => push('/')} />
            <Loader onValueUpdated={handleValueUpdated} />
            <LoaderText>Calculating results...</LoaderText>
            <Leaves1 src={icons.calculating_leaves_1} alt="Leaves" />
            <Leaves2 src={icons.calculating_leaves_2} alt="Leaves" />
            <Leaves3 src={icons.calculating_leaves_3} alt="Leaves" />
            <Leaf src={icons.calculating_leaf} alt="Leaf" />
          </Wrapper>
        </Container>
      </Background>
    </>
  );
};

const Background = styled.div`
  padding-top: 4.5rem;
  min-height: 100vh;
  background: ${colors.background};

  @media ${mobile} {
    min-height: 100vh;
    padding: 20px 0 0;
    background: ${colors.white};
  }
`;
const Container = styled.div`
  position: relative;
  max-width: 860px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  background: ${colors.white};
  border-radius: 40px;

  @media ${mobile} {
    padding: 0 2%;
  }
`;
const Wrapper = styled.div`
  min-height: 600px;
  padding-top: 40px;

  @media ${mobile} {
    padding-top: 0;
  }
`;
const Logo = styled.img`
  display: block;
  margin: 0 auto 80px;
  cursor: pointer;
`;
const LoaderText = styled(Regular)`
  font-size: 22px;
  font-weight: 600;
`;
const Leaves1 = styled.img`
  position: absolute;
  left: calc(50% - 165px);
  top: calc(50% - 130px);
`;
const Leaves2 = styled.img`
  position: absolute;
  top: calc(50% - 175px);
  right: calc(50% - 145px);
`;
const Leaves3 = styled.img`
  position: absolute;
  bottom: 42px;
  right: calc(50% - 200px);
`;
const Leaf = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
`;
