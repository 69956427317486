import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { icons } from '../../utils/icons';
import { colors } from '../../styles/colors';
import { CollapsableItems, DefaultButton, Regular } from '..';

export const RitualBundleItem = ({
  id,
  icon,
  title,
  price,
  includedItems,
  isSelected,
  buttonLabel,
  buttonBackgroundColor,
  onBuyClick,
}) => (
  <Container>
    <Header
      className={onBuyClick ? 'clickable' : null}
      onClick={onBuyClick ? () => onBuyClick(id) : null}
    >
      <Image src={icon} alt="Ritual bundle" />
      <Title marginBottom={includedItems ? '10px' : null}>{title}</Title>
    </Header>
    <CollapsableItems items={includedItems} />
    <Stars
      src={icons.checkout_stars}
      alt="Stars"
      marginTop={includedItems ? '19px' : '0'}
    />
    <Price>
      Price: from <span>{price}</span>
    </Price>
    {onBuyClick ? (
      <DefaultButton
        fullWidth={true}
        type="button"
        backgroundColor={buttonBackgroundColor}
        onClick={() => onBuyClick(id)}
      >
        <ButtonContent>
          {isSelected ? (
            <Circle>
              <Checkmark src={icons.checkout_checkmark} alt="Checkmark" />
            </Circle>
          ) : null}
          <span>{buttonLabel || 'BUY NOW'}</span>
        </ButtonContent>
      </DefaultButton>
    ) : (
      <BuyButton to={`/checkout2?bundleId=${id}`}>
        <DefaultButton
          fullWidth={true}
          label={buttonLabel || 'BUY NOW'}
          type="button"
          backgroundColor={buttonBackgroundColor}
        />
      </BuyButton>
    )}
  </Container>
);

RitualBundleItem.propTypes = {
  buttonBackgroundColor: PropTypes.string,
  buttonLabel: PropTypes.string,
  icon: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  includedItems: PropTypes.arrayOf(PropTypes.string),
  isSelected: PropTypes.bool,
  onBuyClick: PropTypes.func,
  price: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

RitualBundleItem.defaultProps = {
  buttonBackgroundColor: colors.gradient,
  buttonLabel: 'BUY NOW',
  includedItems: null,
  isSelected: false,
  onBuyClick: null,
};

const Container = styled.div`
  width: 277px;
  width: 100%;
  position: relative;
`;
const Header = styled.div`
  &.clickable {
    cursor: pointer;
  }
`;
const Image = styled.img`
  display: block;
  width: 277px;
  height: 229px;
  margin: 0 auto;
  border-radius: 10px;
`;
const Title = styled(Regular)`
  margin-top: 17px;
  margin-bottom: ${props => props.marginBottom || '19px'};
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: ${colors.black};
`;
const Stars = styled.img`
  display: block;
  margin: 0 auto;
  margin-top: ${props => props.marginTop};
`;
const Price = styled(Regular)`
  margin-top: 4px;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: normal;
  line-height: 24px;
  text-align: center;
  color: ${colors.black};

  span {
    font-size: 18px;
    font-weight: bold;
  }
`;
const BuyButton = styled(Link)`
  display: inline-block;
  max-width: 277px;
  width: 100%;
`;
const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 101px;
  width: 100%;
  margin: 0 auto;
`;
const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-right: 8px;
  margin-bottom: 2px;
  background-color: ${colors.white};
`;
const Checkmark = styled.img`
  width: 10px;
  height: 10px;
`;
