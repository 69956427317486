import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import COUNTRIES from '../../../assets/data/countries.json';
import { colors } from '../../../styles/colors';
import { tablet } from '../../../styles/breakpoints';
import { Input, Regular, Select } from '../../../components';

export const ShippingAddress = ({
  values,
  errors,
  handleChange,
  setFieldValue,
}) => (
  <Container>
    <Wrapper>
      <Heading>Shipping address</Heading>
      <div>
        <FormInput
          name="fullName"
          type="text"
          id="fullNameInput"
          placeholder="Full name"
          borderColor="#E0E0E0"
          backgroundColor={colors.white}
          value={values.fullName}
          error={errors.fullName}
          onChange={handleChange}
        />
        <FormInput
          name="email"
          type="text"
          id="emailInput"
          placeholder="Your e-mail"
          borderColor="#E0E0E0"
          backgroundColor={colors.white}
          value={values.email}
          error={errors.email}
          onChange={handleChange}
        />
        <FormInput
          name="address"
          type="text"
          id="addressInput"
          placeholder="Address"
          borderColor="#E0E0E0"
          backgroundColor={colors.white}
          value={values.address}
          error={errors.address}
          onChange={handleChange}
        />
        <FormInput
          type="text"
          required={false}
          name="apartment"
          id="apartmentInput"
          placeholder="Apartment, suite, etc. (optional)"
          borderColor="#E0E0E0"
          backgroundColor={colors.white}
          value={values.apartment}
          error={errors.apartment}
          onChange={handleChange}
        />
        <FormInput
          name="city"
          type="text"
          id="cityInput"
          placeholder="City"
          borderColor="#E0E0E0"
          backgroundColor={colors.white}
          value={values.city}
          error={errors.city}
          onChange={handleChange}
        />
        <Select
          error={errors.country}
          options={COUNTRIES}
          onChange={({ value }) => setFieldValue('country', value)}
        />
        <FormInput
          name="postalCode"
          type="text"
          id="zipCodeInput"
          placeholder="Postal code"
          borderColor="#E0E0E0"
          backgroundColor={colors.white}
          value={values.postalCode}
          error={errors.postalCode}
          onChange={handleChange}
        />
      </div>
    </Wrapper>
  </Container>
);

ShippingAddress.propTypes = {
  errors: PropTypes.shape({
    address: PropTypes.string,
    apartment: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    email: PropTypes.string,
    fullName: PropTypes.string,
    postalCode: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({
    address: PropTypes.string,
    apartment: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    email: PropTypes.string,
    fullName: PropTypes.string,
    postalCode: PropTypes.string,
  }).isRequired,
};

const Container = styled.div`
  max-width: 430px;
  width: 100%;
  padding: 55px 0;
  background-color: ${colors.almostWhite};

  @media ${tablet} {
    max-width: unset;
    padding-top: 43px;
    padding-bottom: 34px;
  }
`;
const Wrapper = styled.div`
  max-width: 327px;
  width: 100%;
  margin: 0 auto;
`;
const Heading = styled(Regular)`
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  color: ${colors.black};
`;
const FormInput = styled(Input)`
  margin-top: 16px;
`;
